import { Image20Regular as ImageIcon } from "@fluentui/react-icons";
import Button, { ButtonPosition } from "components/buttons/Button";
import { ColorVariant } from "components/buttons/buttonVariants";
import { InputRow } from "components/forms/forms";
import { useHasPermission } from "features/authorization/ComponentPermissionChecker";
import { selectSelectedCompanyId } from "features/navigation/navSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import * as routes from "constants/routes";
import { useTranslation } from "react-i18next";

export default function CompanyImages() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t: tc } = useTranslation("company");
  const selectedCompanyId = useAppSelector(selectSelectedCompanyId);

  const writePermission = useHasPermission(["companyWriteRead"]);

  return (
    <InputRow verticalAlign="bottom">
      <Button
        type={"button"}
        disabled={!writePermission || !selectedCompanyId}
        buttonPosition={ButtonPosition.none}
        buttonColor={ColorVariant.primary}
        icon={<ImageIcon />}
        onClick={() => {
          navigate(routes.COMPANY_LOGO.replace(":companyId", selectedCompanyId || ""), {
            state: { background: location },
          });
        }}
      >
        {tc("Logo")}
      </Button>
      <Button
        type={"button"}
        disabled={!writePermission || !selectedCompanyId}
        buttonPosition={ButtonPosition.none}
        buttonColor={ColorVariant.primary}
        icon={<ImageIcon />}
        onClick={() => {
          navigate(routes.COMPANY_SYMBOL.replace(":companyId", selectedCompanyId || ""), {
            state: { background: location },
          });
        }}
      >
        {tc("Symbol")}
      </Button>
      <Button
        type={"button"}
        disabled={!writePermission || !selectedCompanyId}
        buttonPosition={ButtonPosition.none}
        buttonColor={ColorVariant.primary}
        icon={<ImageIcon />}
        onClick={() => {
          navigate(routes.COMPANY_CHECKOUT_BG_IMAGE.replace(":companyId", selectedCompanyId || ""), {
            state: { background: location },
          });
        }}
      >
        {tc("CheckoutBackgroundImage")}
      </Button>
    </InputRow>
  );
}

import api, {
  ClaimRefundAttemptRecord,
  ClaimRefundRefundSessionRecord,
  CompliancePostOperation,
  RefundSessionStatus,
} from "api/api";
import { useContext, useEffect, useRef, useState } from "react";
import { RefundClaimContext } from "pages/refundClaim/RefundClaimPage";
import i18n from "i18n";
import { useTranslation } from "react-i18next";
import Button, { ButtonPosition, SizeVariant } from "components/buttons/Button";
import Note, { NoteType } from "features/note/Note";
import { getErrorResponse } from "components/errors/validationErrors";
import styles from "pages/refundClaim/refundClaimPage.module.scss";
import { Link } from "react-router-dom";
import * as routes from "constants/routes";
import { MilliSatInBTC } from "constants/backend";

type UntilExpiry = {
  minutes?: number;
  seconds?: number;
};

function formatMSatAmount(amountMsat: number): string {
  return i18n.t("SatSpaceFormatterWithSymbol", { value: amountMsat / 1000, ns: "currency" });
}

function getExchangeRateStr(
  refundSession?: ClaimRefundRefundSessionRecord,
  attempt?: ClaimRefundAttemptRecord,
): string | undefined {
  if (!refundSession?.amount || !attempt?.amountMsat) {
    return undefined;
  }

  const amountBTC = attempt.amountMsat / MilliSatInBTC;
  const amountFiat = refundSession.amount;

  const rate = amountFiat / amountBTC;

  return i18n.t("BtcEurPriceFormatter", { value: rate, ns: "currency" });
}

function RefundClaimAttempt() {
  const { t } = useTranslation("claimrefund");
  const lastFetched = useRef<number>(0);
  const [requestRefundAttemptError, setRequestRefundAttemptError] = useState<string | undefined>();
  const [settleRefundAttemptError, setSettleRefundAttemptError] = useState<string | undefined>();

  const { refundSessionResp, refundSessionStatus, checkRefundCustomerResp, loggedInCustomer } =
    useContext(RefundClaimContext);

  const [requestRefundAttempt, requestRefundAttemptResp] = api.useRequestClaimRefundAttemptMutation();
  const [settleRefundAttempt, settleRefundAttemptResp] = api.useSettleClaimRefundAttemptMutation();

  const [untilExpiry, setUntilExpiry] = useState<UntilExpiry>({ minutes: undefined, seconds: undefined });

  function fetchRefundAttempt() {
    if (!loggedInCustomer) {
      console.error("Tried to call fetchRefundAttempt without loggedInCustomer");
      return;
    }

    if (!requestRefundAttemptResp.isLoading) {
      requestRefundAttempt(refundSessionResp?.data?.refundSessionId || "");
    }
  }

  function handleSettleRefund() {
    if (refundSessionResp?.data?.refundSessionId) {
      settleRefundAttempt(refundSessionResp?.data?.refundSessionId);
    }
  }

  function calculateUntilExpiry() {
    const expiry = requestRefundAttemptResp?.data?.expiry;
    if (!expiry) {
      return;
    }

    const now = new Date().getTime();
    const expiryTime = new Date(expiry).getTime();
    const timeLeft = expiryTime - now;

    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

    setUntilExpiry({ minutes, seconds });
  }

  // Update the count-down every 100 milliseconds
  useEffect(() => {
    const interval = setInterval(calculateUntilExpiry, 100);

    return () => clearInterval(interval);
  }, [requestRefundAttemptResp?.data?.expiry]);

  // When the attempt expires, fetch a new one
  useEffect(() => {
    if (untilExpiry?.seconds != null && untilExpiry.seconds < 0) {
      fetchRefundAttempt();
    }
  }, [untilExpiry?.seconds]);

  // Fetch the refund attempt
  useEffect(() => {
    if (
      !checkRefundCustomerResp?.isSuccess ||
      !loggedInCustomer?.customerId ||
      !refundSessionResp?.data?.refundSessionId
    ) {
      return;
    }

    // To go around the StrictMode running the query twice, do not allow to run the query twice within 1 second
    if (new Date().getTime() - lastFetched.current > 1000) {
      fetchRefundAttempt();
    }
    return () => {
      lastFetched.current = new Date().getTime();
    };
  }, [checkRefundCustomerResp, loggedInCustomer, refundSessionResp]);

  useEffect(() => {
    // Try refetching after 5 sec if there is error in the query
    if (requestRefundAttemptResp?.isError) {
      setTimeout(() => {
        fetchRefundAttempt();
      }, 5000);

      const err = getErrorResponse(requestRefundAttemptResp?.error);
      setRequestRefundAttemptError(err?.message || "Error getting refund quote");

      return;
    }

    setRequestRefundAttemptError(undefined);
  }, [requestRefundAttemptResp]);

  useEffect(() => {
    if (settleRefundAttemptResp?.isError) {
      const err = getErrorResponse(settleRefundAttemptResp?.error);
      setSettleRefundAttemptError(err?.message || "Error claiming refund");
    } else {
      setSettleRefundAttemptError(undefined);
    }
  }, [settleRefundAttemptResp]);

  function getBlockedByComplianceMessage() {
    const postOperations = requestRefundAttemptResp?.data?.compliancePostOperations as
      | CompliancePostOperation[]
      | undefined;

    if (postOperations !== undefined) {
      if (postOperations.includes(CompliancePostOperation.DigitalKnowYourCustomer)) {
        return (
          <div>
            <p>{t("MustKYCInfo")}</p>
            <Link to={`${routes.CONSUMER_ACCOUNT_EDIT}?rs=${refundSessionResp?.data?.refundSessionId}`}>
              {t("Proceed to identification")}
            </Link>
          </div>
        );
      }

      // TODO add different compliance messages like fill more info for PEP check
    }

    // default error if blocked by compliance
    return (
      <div>
        <p>{t("RefundBlockedByComplianceInfo")}</p>
        <p>{loggedInCustomer?.customerId}</p>
      </div>
    );
  }

  return (
    <div>
      {checkRefundCustomerResp?.isSuccess && (
        <>
          {requestRefundAttemptError && (
            <Note noteType={NoteType.error} className={styles.paddedNote}>
              {requestRefundAttemptError}
            </Note>
          )}
          {settleRefundAttemptError && (
            <Note noteType={NoteType.error} className={styles.paddedNote}>
              {settleRefundAttemptError}
            </Note>
          )}
          {requestRefundAttemptResp?.data?.blockedByCompliance && (
            <Note noteType={NoteType.error} title={t("RefundBlockedByComplianceTitle")} className={styles.paddedNote}>
              {getBlockedByComplianceMessage()}
            </Note>
          )}
          {requestRefundAttemptResp?.isSuccess && !requestRefundAttemptResp?.data?.blockedByCompliance && (
            <div className={styles.refundDetailsWrapper}>
              <div className={styles.refundDetailsRow}>
                <div>{t("ReceiveBTCAmountRefundInfo")}</div>
                <div>
                  <b>{formatMSatAmount(requestRefundAttemptResp?.data?.amountMsat || 0)}</b>
                </div>
              </div>
              <div className={styles.refundDetailsRow}>
                <div>{t("ReceiveBTCRefundRateInfo")}</div>
                <div>{getExchangeRateStr(refundSessionResp?.data, requestRefundAttemptResp?.data)}</div>
              </div>
              <div className={styles.refundDetailsRow}>
                <Button
                  type={"button"}
                  buttonPosition={ButtonPosition.left}
                  buttonSize={SizeVariant.large}
                  onClick={handleSettleRefund}
                  disabled={
                    settleRefundAttemptResp?.isLoading ||
                    requestRefundAttemptResp?.data?.blockedByCompliance ||
                    refundSessionStatus?.status !== RefundSessionStatus.Active
                  }
                >
                  {t("ClaimRefund")}
                </Button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default RefundClaimAttempt;

import PopoutPageTemplate from "features/templates/popoutPageTemplate/PopoutPageTemplate";
import CustomerBitcoinWithdraw from "features/withdraw/bitcoinWithdraw/CustomerBitcoinWithdraw";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Wallet20Regular as WithdrawIcon } from "@fluentui/react-icons";

function ConsumerBitcoinWithdrawModal() {
  const { t } = useTranslation("withdraw");
  const navigate = useNavigate();
  const location = useLocation();

  function navBack() {
    if (location.state?.navBackToBackground && location.state?.background) {
      navigate(location.state?.background, { replace: true });
      return;
    }

    navigate(-1);
  }

  return (
    <PopoutPageTemplate title={t("Withdraw")} show={true} onClose={navBack} icon={<WithdrawIcon />}>
      <CustomerBitcoinWithdraw />
    </PopoutPageTemplate>
  );
}

export default ConsumerBitcoinWithdrawModal;

import classNames from "classnames";
import { Delete16Regular as RemoveIcon } from "@fluentui/react-icons";
import {
  Clause,
  FilterCategoryType,
  FilterClause,
  FilterFunctions,
  FilterInterface,
  FilterParameterType,
} from "features/sidebar/sections/filter/filter";
import styles from "features/sidebar/sections/filter/filter-section.module.scss";
import { useState } from "react";
import { format } from "d3";
import { Input, InputColorVaraint, InputSizeVariant, Select } from "components/forms/forms";
import clone from "clone";
import { formatDuration, intervalToDuration } from "date-fns";
import { DurationInput, TimeUnitFromSeconds, TimeUnits } from "components/common/durationInput/DurationInput";
import { useTranslation } from "react-i18next";
import HelpTooltip from "components/forms/helpTooltip/helpTooltip";

const formatter = format(",.0f");
const subSecFormat = format("0.2f");

function formatParameter(value: number) {
  return value % 1 != 0 ? value : formatter(value);
}

const ffLabels = new Map<string, string>([
  ["eq", "is equal to"],
  ["neq", "is not equal to"],
  ["gt", "is greater than"],
  ["gte", "is greater than or equal to"],
  ["lt", "is less than"],
  ["lte", "is less than or equal to"],
  ["like", "contain"],
  ["notLike", "don't contain"],
  ["any", "contain"],
  ["notAny", "don't contain"],
  ["empty", "is empty"],
  ["emptyString", "is empty"], // null or empty
]);

function getFilterFunctions(filterCategory: FilterCategoryType) {
  const filterFuncs = FilterFunctions.get(filterCategory)?.entries();
  if (!filterFuncs) {
    throw new Error("Filter category not found in list of filters");
  }
  return Array.from(filterFuncs, ([key]) => ({ value: key, label: ffLabels.get(key) ?? "Label not found" }));
}

interface filterRowInterface {
  index: number;
  child: boolean;
  filterClause: FilterClause;
  filterOptions: Array<{
    label: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any; // TODO: Switch to "number" | "string" | "date" | "boolean" | "array" | "duration" | "enum" | undefined
    valueType?: FilterCategoryType;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectOptions?: Array<any>;
    suffix: string | undefined;
    helpText?: string;
    formatFunc?: (parameter: unknown) => string;
    optLabelTranslatePackage?: string;
  }>;
  onUpdateFilter: (filters: Clause) => void;
  onRemoveFilter: (index: number) => void;
  handleCombinerChange: () => void;
  combiner?: string;
  editingDisabled?: boolean;
}

function FilterRow({
  index,
  child,
  filterClause,
  filterOptions,
  onUpdateFilter,
  onRemoveFilter,
  handleCombinerChange,
  combiner,
  editingDisabled,
}: filterRowInterface) {
  const { t } = useTranslation();

  const rowValues = filterClause.filter;
  const [rowExpanded, setRowExpanded] = useState(true); // expand by default

  const functionOptions = getFilterFunctions(rowValues.category);

  const keyOption = filterOptions.find((item) => item.value === rowValues.key);
  const funcOption = functionOptions.find((item) => item.value === rowValues.funcName);

  const selectData = {
    func: funcOption,
    key: keyOption,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleKeyChange = (item: any) => {
    if (editingDisabled) {
      return;
    }
    const newRow = { ...rowValues };
    newRow.key = item.value;
    newRow.formatFunc = filterOptions.find((i) => i.value === item.value)?.formatFunc;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newCategory = filterOptions.find((item: any) => item.value === newRow.key)?.valueType || "number";
    switch (newCategory) {
      case "number":
      case "currency":
        newRow.parameter = 0;
        newRow.funcName = "gte";
        break;
      case "duration":
        newRow.parameter = 0;
        newRow.funcName = "gte";
        break;
      case "boolean":
        if (newCategory !== newRow.category) {
          newRow.parameter = true;
        }
        newRow.funcName = "eq";
        break;
      case "date": {
        newRow.parameter = new Date().toISOString().slice(0, 10) + "T00:00:00";
        newRow.funcName = "gte";
        break;
      }
      case "datetime": {
        newRow.parameter = new Date().toISOString().slice(0, 10) + "T00:00:00";
        newRow.funcName = "gte";
        break;
      }
      case "array":
        newRow.parameter = [];
        newRow.funcName = "any";
        break;
      case "enum":
        newRow.parameter = [];
        newRow.funcName = "any";
        break;
      default:
        newRow.parameter = "";
        newRow.funcName = "like";
    }
    newRow.category = newCategory;
    const updatedFilterClause = clone(filterClause);
    updatedFilterClause.filter = newRow;
    onUpdateFilter(updatedFilterClause);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFunctionChange = (item: any) => {
    if (editingDisabled) {
      return;
    }
    const updatedFilterClause = clone(filterClause);
    updatedFilterClause.filter = { ...rowValues, funcName: item.value };
    if (item.value === "empty" || item.value === "emptyString") {
      updatedFilterClause.filter.parameter = "";
    }
    onUpdateFilter(updatedFilterClause);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleParamChange = (e: any) => {
    if (editingDisabled) {
      return;
    }
    const newRow = { ...rowValues };
    switch (newRow.category) {
      case "number":
      case "currency":
        newRow.parameter = e.floatValue || 0;
        break;
      case "duration":
        if (typeof e === "number") {
          newRow.parameter = e;
        } else {
          newRow.parameter = e.floatValue || 0;
        }
        break;
      case "boolean":
        newRow.parameter = e.value;
        break;
      case "array":
        newRow.parameter = (e as Array<{ value: string | number }>).map((item) => item.value);
        break;
      case "enum":
        newRow.parameter = (e as Array<{ value: string | number }>).map((item) => item.value);
        break;
      default:
        newRow.parameter = e.target.value ? e.target.value : "";
    }
    const updatedFilterClause = clone(filterClause);
    updatedFilterClause.filter = newRow;
    onUpdateFilter(updatedFilterClause);
  };

  const filterOpt = filterOptions.find((item) => item.value === rowValues.key);

  const label = filterOpt?.label || "Select filters...";

  let options = filterOpt?.selectOptions || [];
  if (filterOpt?.optLabelTranslatePackage) {
    options = options.map((item) => ({
      ...item,
      label: t(item.label as string, { ns: filterOpt.optLabelTranslatePackage }),
    }));
  }

  const suffix = filterOpt?.suffix || "";
  const helpText = filterOpt?.helpText || "";

  function getParameter() {
    switch (rowValues.category) {
      case "string":
        return rowValues.parameter as string;
      case "number":
      case "currency":
        return formatParameter(rowValues.parameter as number);
      case "duration":
        if ((rowValues.parameter as number) >= 1) {
          try {
            const d = intervalToDuration({ start: 0, end: (rowValues.parameter as number) * 1000 });
            return (
              formatDuration({
                years: d.years,
                months: d.months,
                days: d.days,
                hours: d.hours,
                minutes: d.minutes,
                seconds: d.seconds,
              }) +
              " " +
              suffix
            );
          } catch {
            return `${formatter(rowValues.parameter as number)} seconds ${suffix}`;
          }
        } else if ((rowValues.parameter as number) < 1 && (rowValues.parameter as number) > 0) {
          return `${subSecFormat(rowValues.parameter as number)} seconds ${suffix}`;
        }
        return `0 seconds ${suffix}`;
      case "boolean":
        return !rowValues.parameter ? "False" : "True";
      case "array":
        if (rowValues?.parameter) {
          return (
            (
              options?.filter((item) => {
                return (rowValues.parameter as Array<string | number>).includes(item.value);
              }) || []
            )
              .map((item) => item.label)
              .join(" or ") || "[empty]"
          );
        }
        return "[empty]";
      case "enum":
        if (rowValues?.parameter) {
          return (
            (
              options?.filter((item) => {
                return (rowValues.parameter as Array<string | number>).includes(item.value);
              }) || []
            )
              .map((item) => item.label)
              .join(" or ") || "[empty]"
          );
        }
        return "[empty]";
      default:
        return rowValues.parameter as string;
    }
  }

  return (
    <div className={classNames(styles.filter, { [styles.first]: !index })}>
      <div className={classNames(styles.filterKeyContainer, { [styles.expanded]: rowExpanded })}>
        {index !== 0 && (
          <div
            className={styles.combinerContainer}
            onClick={() => {
              handleCombinerChange();
            }}
          >
            {combiner}
          </div>
        )}
        <div
          className={classNames(styles.filterKeyLabel)}
          onClick={() => setRowExpanded(!rowExpanded)}
          data-intercom-target={"filter-row-summary-label"}
        >
          <span>{label}</span>
          <span className={styles.filterFunctionLabel}>&nbsp;{funcOption?.label}&nbsp;</span>
          <span className={styles.parameterLabel}>{getParameter()}</span>
        </div>
        {!editingDisabled && (
          <div
            className={classNames(styles.removeFilter, styles.desktopRemove)}
            onClick={() => onRemoveFilter(index)}
            data-intercom-target={"remove-filter-row"}
          >
            <RemoveIcon />
          </div>
        )}
      </div>

      <div className={classNames(styles.filterOptions, { [styles.expanded]: rowExpanded })}>
        <Select
          intercomTarget={"filter-row-key-select"}
          options={filterOptions}
          value={selectData.key}
          onChange={handleKeyChange}
          sizeVariant={InputSizeVariant.small}
          colorVariant={InputColorVaraint.primary}
        />

        <div className="filter-function-container">
          <Select
            intercomTarget={"filter-row-function-select"}
            options={functionOptions}
            value={selectData.func}
            onChange={handleFunctionChange}
            sizeVariant={InputSizeVariant.small}
            colorVariant={InputColorVaraint.primary}
          />
        </div>
        {selectData.func?.value !== "empty" && selectData.func?.value !== "emptyString" && (
          <div className={styles.filterParameterContainer}>
            <FilterInputField
              onChange={handleParamChange}
              rowValues={rowValues}
              options={options}
              child={child}
              editingDisabled={editingDisabled}
              suffix={suffix}
            />
            <HelpTooltip label={label} text={helpText} />
          </div>
        )}
      </div>
    </div>
  );
}

export default FilterRow;

function FilterInputField(props: {
  onChange: (e: any) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
  rowValues: FilterInterface;
  child: boolean;
  options: Array<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  editingDisabled?: boolean | false;
  suffix?: string;
}) {
  const { t } = useTranslation("common");
  const { editingDisabled } = props;
  switch (props.rowValues.category) {
    case "number":
      return (
        <Input
          intercomTarget={"filter-row-parameter-input"}
          disabled={editingDisabled}
          formatted={true}
          thousandSeparator=","
          sizeVariant={InputSizeVariant.small}
          colorVariant={InputColorVaraint.primary}
          defaultValue={props.rowValues.parameter as keyof FilterParameterType}
          onValueChange={(e) => {
            props.onChange(e);
          }}
        />
      );
    case "duration":
      return (
        <DurationInput
          editingDisabled={editingDisabled}
          timeUnit={TimeUnitFromSeconds(props.rowValues.parameter as number, TimeUnits.days)}
          seconds={props.rowValues.parameter as keyof FilterParameterType}
          timeUnitOptions={[
            { value: TimeUnits.seconds.valueOf(), label: t("second", { count: TimeUnits.seconds.valueOf() }) },
            { value: TimeUnits.minutes.valueOf(), label: t("minute", { count: TimeUnits.minutes.valueOf() }) },
            { value: TimeUnits.hours.valueOf(), label: t("hour", { count: TimeUnits.hours.valueOf() }) },
            { value: TimeUnits.days.valueOf(), label: t("day", { count: TimeUnits.days.valueOf() }) },
          ]}
          intercomTargetFrequencyInput={"filter-row-parameter-frequency-input"}
          intercomTargetTimeUnitSelect={"filter-row-parameter-time-unit-select"}
          sizeVariant={InputSizeVariant.small}
          colorVariant={InputColorVaraint.primary}
          onDurationChange={(sec: number) => {
            props.onChange(sec);
          }}
          additionalSuffix={props.suffix}
        />
      );
    case "boolean":
      return (
        <Select
          intercomTarget={"filter-row-parameter-input"}
          isDisabled={editingDisabled}
          options={[
            { label: "True", value: true },
            { label: "False", value: false },
          ]}
          value={[
            { label: "True", value: true },
            { label: "False", value: false },
          ].find((v) => v.value === props.rowValues.parameter)}
          onChange={props.onChange}
          colorVariant={InputColorVaraint.primary}
          sizeVariant={InputSizeVariant.small}
        />
      );
    case "array": {
      return (
        <Select
          intercomTarget={"filter-row-parameter-input"}
          isDisabled={editingDisabled}
          isMulti={true}
          options={props.options}
          value={props.options.filter((item) => {
            return (props.rowValues.parameter as Array<string | number>).includes(item.value);
          })}
          onChange={props.onChange}
          colorVariant={InputColorVaraint.primary}
          sizeVariant={InputSizeVariant.small}
        />
      );
    }
    case "enum": {
      return (
        <Select
          intercomTarget={"filter-row-parameter-input"}
          isDisabled={editingDisabled}
          isMulti={true}
          options={props.options}
          value={props.options.filter((item) => {
            return (props.rowValues.parameter as Array<string | number>).includes(item.value);
          })}
          onChange={props.onChange}
          colorVariant={InputColorVaraint.primary}
          sizeVariant={InputSizeVariant.small}
        />
      );
    }
    case "date":
      return (
        <Input
          intercomTarget={"filter-row-parameter-input"}
          disabled={editingDisabled}
          type="datetime-local"
          sizeVariant={InputSizeVariant.small}
          colorVariant={InputColorVaraint.primary}
          value={props.rowValues.parameter as string}
          onChange={props.onChange}
        />
      );
    case "datetime":
      return (
        <Input
          intercomTarget={"filter-row-parameter-input"}
          disabled={editingDisabled}
          type="datetime-local"
          sizeVariant={InputSizeVariant.small}
          colorVariant={InputColorVaraint.primary}
          value={props.rowValues.parameter as string}
          onChange={props.onChange}
        />
      );
    case "currency":
      return (
        <Input
          intercomTarget={"filter-row-parameter-input"}
          disabled={editingDisabled}
          formatted={true}
          thousandSeparator=","
          sizeVariant={InputSizeVariant.small}
          colorVariant={InputColorVaraint.primary}
          defaultValue={props.rowValues.parameter as keyof FilterParameterType}
          onValueChange={(e) => {
            props.onChange(e);
          }}
        />
      );
    default:
      return (
        <Input
          intercomTarget={"filter-row-parameter-input"}
          disabled={editingDisabled}
          type="text"
          sizeVariant={InputSizeVariant.small}
          colorVariant={InputColorVaraint.primary}
          value={props.rowValues.parameter as keyof FilterParameterType}
          onChange={props.onChange}
        />
      );
  }
}

import { useTranslation } from "react-i18next";
import styles from "features/existingCustomer/existingCustomer.module.scss";
import { ArrowExit20Regular as LogOutIcon } from "@fluentui/react-icons";
import api, { CustomerRecord } from "api/api";
import { useDispatch } from "react-redux";
import Button, { ButtonPosition, ColorVariant, SizeVariant } from "components/buttons/Button";
import { useLocation, useNavigate } from "react-router-dom";
import * as routes from "constants/routes";
import classNames from "classnames";

type Props = {
  header?: string;
  setEditCustomer?: (edit: boolean | undefined) => void; // when Edit button is clicked (if not navigating, but editing without navigate)
  navigateToOnEdit?: string; // when Edit button is clicked where to navigate (empty to not navigate)
  setEmail?: (email: string | undefined) => void;
  loggedInCustomer: CustomerRecord | null | undefined;
  setIsGuest?: (edit: boolean | undefined) => void;
};

export default function ExistingCustomer(props: Props) {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [logoutCustomer] = api.useLogoutCustomerMutation();

  const inAccountPage = location.pathname === routes.CONSUMER_ACCOUNT;

  if (!props.loggedInCustomer) {
    return <></>;
  }

  return (
    <div className={styles.existingCustomerWrapper}>
      <div className={styles.headerWrapper}>
        <div className={styles.header}>{props.header}</div>
        <div
          className={styles.logOut}
          onClick={() => {
            logoutCustomer()
              .unwrap()
              .then(() => {
                if (props.setEmail) props.setEmail(undefined);
                if (props.setIsGuest) props.setIsGuest(undefined);
                if (props.setEditCustomer) {
                  props.setEditCustomer(undefined);
                }
                localStorage.removeItem("token");
                sessionStorage.removeItem("token");

                if (window.$chatwoot) {
                  window.$chatwoot.reset();
                }

                dispatch(api.util.invalidateTags(["customer"]));
              });
          }}
        >
          <LogOutIcon />
          {t("Log out")}
        </div>
      </div>

      {/* go to account page by clicking on the area */}
      <div
        className={classNames(styles.details, { [styles.showHover]: !inAccountPage })}
        onClick={() => {
          if (!inAccountPage) navigate(routes.CONSUMER_ACCOUNT);
        }}
      >
        <div>
          <div className={styles.fullName}>
            {props.loggedInCustomer?.firstName} {props.loggedInCustomer?.lastName}
          </div>
          <div className={styles.email}>{props.loggedInCustomer?.email}</div>

          {/* Address etc*/}
        </div>

        <Button
          className={styles.editButton}
          intercomTarget={"edit-customer"}
          buttonSize={SizeVariant.normal}
          buttonColor={ColorVariant.primary}
          onClick={(e) => {
            e.stopPropagation();

            if (props.navigateToOnEdit) {
              navigate(props.navigateToOnEdit, {
                state: {
                  customerEmail: props.loggedInCustomer?.email,
                },
              });
            } else if (props.setEditCustomer) {
              props.setEditCustomer(true);
            } else {
              console.error("No navigateToOnEdit or setEditCustomer provided");
            }
          }}
          buttonPosition={ButtonPosition.right}
        >
          {t("Edit")}
        </Button>
      </div>
    </div>
  );
}

export const ROOT = "/";

// Public routes
export const LOGIN = "login";
export const BOOTSTRAPPING = "bootstrapping";
export const LOGOUT = "logout";
export const SIGNUP = "signup";
export const TOKEN_LOGIN = "token-login";
export const TOKEN_RESET_PASSWORD = "token-password-reset";
export const FORGOT_PASSWORD_USER = "/user-forgot-password";
export const FORGOT_PASSWORD_CUSTOMER = "/customer-forgot-password";
export const CHECKOUT = "/checkout";
export const DEMO_SHOP = "/demo-shop";
export const DEMO_PRODUCT = "/demo-shop/:productId";
export const CONSUMER_ACCOUNT = "/consumer-account";
export const CONSUMER_ACCOUNT_EDIT = "/consumer-account/edit"; // edit and create are actually same page
export const CONSUMER_ACCOUNT_CREATE = "/consumer-account/create";
export const CONSUMER_ACCOUNT_BITCOIN_WITHDRAW = "/consumer-account/bitcoin-withdraw";
export const SHOP = "/shop/:shopHandle";
export const SHOPPING_CART = "/shop/:shopHandle/shopping-cart";
export const CLAIM_REFUND = "/claim-refund";

// Merchants Pages
export const COMPANY = "/company";
export const USERS = "/users";
export const PAYMENTS = "/payments";
export const CUSTOMERS = "/customers";
export const COMPANY_BANK_ACCOUNTS = "/company-bank-accounts";
export const COMPANY_BANK_WITHDRAWALS = "/company-bank-withdrawals";
export const API_KEYS = "/keys";
export const COMPANY_PRODUCTS = "/company-products";
export const REFUNDS = "/refunds";

// admin routes
export const ADMIN_USERS = "/admin-users";
export const EDIT_USER_ADMIN_PERMISSIONS = "/users/admin-permissions/:userId";
export const INVITE_ADMIN_USER = "/users/admin-invite";
export const EDIT_USER_COMPANIES = "/users/usercompanies/:userId";
export const ADMIN_BANK_WITHDRAWALS = "/admin/bank/withdrawals";
export const ADMIN_LIGHTNING_WITHDRAWALS = "/admin/wallet/lightning/withdrawals";
export const ADMIN_FAILURE_EVENTS = "/failure-events";
export const ADMIN_CUSTOMERS = "/admin-customers";
export const ADMIN_COMPLIANCE_HITS = "/admin/compliance-hits";
export const ADMIN_UPDATE_COMPLIANCE_HIT_STATUS = "/admin/compliance-hits/status/:complianceHitId";
export const ADMIN_EXEMPT_COMPLIANCE_HIT = "/admin/compliance-hits/exempt/:complianceHitId";
export const ADMIN_SUPER_ADMIN_SETTINGS = "/admin/settings";

// modals
export const CONNECTION_STATUS = "/connection-status";
export const CREATE_COMPANY = "/create-company";
export const USER_SELF_DETAILS = "/user-details";
export const EDIT_USER_PERMISSIONS = "/users/permissions/:userId";
export const INVITE_USER_COMPANY = "/users/invite";
export const API_KEY = "/keys/:keyId";
export const CREATE_COMPANY_BANK_ACCOUNT = "/create-company-bank-account";
export const COMPANY_LOGO = "/company-logo/:companyId";
export const COMPANY_SYMBOL = "/company-symbol/:companyId";
export const COMPANY_SHOP_FAVICON = "/company-shop-favicon/:companyId";
export const COMPANY_CHECKOUT_BG_IMAGE = "/company-checkout-bg-image/:companyId";
export const COMPANY_PRODUCT_EDIT = "/company-product-edit/:productId";
export const COMPANY_PRODUCT_ADD = "/company-product-add";
export const PAYMENT_REFUND = "/payment-refund/:paymentSessionId";

// function for insert variables into the path generalized
export function Path(
  path: string,
  params: Record<string, string | number>,
  query?: Record<string, string | number>,
): string {
  let newPath = path;

  Object.keys(params).forEach((key) => {
    newPath = newPath.replace(`:${key}`, params[key].toString());
  });

  if (query) {
    newPath = AddQuery(newPath, query);
  }

  return newPath;
}

// AddQuery adds the query string to the url
export function AddQuery(url: string, query: Record<string, string | number>): string {
  const queryString = Object.keys(query)
    .map((key) => `${key}=${query[key]}`)
    .join("&");
  return `${url}?${queryString}`;
}

import PopoutPageTemplate from "features/templates/popoutPageTemplate/PopoutPageTemplate";
import { useNavigate, useParams } from "react-router-dom";
import {
  Add20Regular as AddIcon,
  HomePerson20Regular as TitleIcon,
  LineHorizontal120Regular as RemoveIcon,
} from "@fluentui/react-icons";
import Button, { ButtonRow, ColorVariant } from "components/buttons/Button";
import { Form, Select } from "components/forms/forms";
import { useTranslation } from "react-i18next";
import { FormEvent, useEffect, useState } from "react";
import styles from "pages/users/UsersPage.module.scss";
import { useHasPermission } from "features/authorization/ComponentPermissionChecker";
import api from "api/api";
import { SelectOption } from "components/forms/select/Select";
import DefaultSymbol from "features/navigation/companySelect/defaultCompanySymbol.svg";
import { useConfirmation } from "components/dialogue/ConfirmContext";
import Note, { NoteType } from "features/note/Note";
import { getErrorResponse } from "components/errors/validationErrors";
import Spinny from "features/spinny/Spinny";

function EditUserCompaniesModal() {
  const { t } = useTranslation("common");
  const { t: tu } = useTranslation("user");
  const { t: tv } = useTranslation("validation");
  const navigate = useNavigate();
  const confirm = useConfirmation();
  const [selectedAddToCompany, setSelectedAddToCompany] = useState<string>("");
  const [selectedRemoveFromCompany, setSelectedRemoveFromCompany] = useState<string>("");
  const [addErrMessage, setAddErrMessage] = useState<string>();
  const [removeErrMessage, setRemoveErrMessage] = useState<string>();
  const { userId } = useParams<{ userId: string }>();
  const hasEdenAdminPermission = useHasPermission(["edenAdmin"]);

  // Get user to edit
  const { data: user, isLoading: loadingUser } = api.useGetUserByIdQuery(userId || "", { skip: !userId });

  const { data: currentUser } = api.useGetSelfUserQuery();

  // Get all companies related to the user that is being modified. Only for Eden Admin
  const { data: userCompanies, isLoading: loadingUserCompanies } = api.useGetUserCompaniesQuery(userId || "", {
    skip: !userId,
  });

  // Get all companies that the logged-in user has access to. (The logged-in user cannot add/remove the user to a company that they do not have access to)
  const { data: allCompanies, isLoading: loadingAllCompanies } = api.useGetUserOwnCompaniesQuery();

  const [addMutation, { error: addError, isLoading: loadingAdd }] = api.useAddUserToCompanyMutation();
  const [removeMutation, { error: removeError, isLoading: loadingRemove }] = api.useRemoveUserFromCompanyMutation();

  useEffect(() => {
    if (addError) {
      const addErrMsg = getErrorResponse(addError)?.message;
      setAddErrMessage(addErrMsg || "Unknown Error");
    } else {
      setAddErrMessage(undefined);
    }

    if (removeError) {
      const removeErrMsg = getErrorResponse(removeError)?.message;
      setRemoveErrMessage(removeErrMsg || "Unknown Error");
    } else {
      setRemoveErrMessage(undefined);
    }
  }, [addError, removeError]);

  const handleSubmitAdd = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const result = await confirm({
      title: t("Confirm Action", { ns: "common" }),
      message: tu("confirmAddUserCompany"),
    });
    if (result) {
      addMutation({ addOrRemoveUserToCompany: { userId: userId || "" }, companyId: selectedAddToCompany });
    }
  };

  const handleSubmitRemove = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const result = await confirm({
      title: t("Confirm Action", { ns: "common" }),
      message: tu("confirmRemoveUserCompany"),
    });
    if (result) {
      removeMutation({ addOrRemoveUserToCompany: { userId: userId || "" }, companyId: selectedRemoveFromCompany });
    }
  };

  return (
    <PopoutPageTemplate title={tu("EditUserCompanies")} show={true} onClose={() => navigate(-1)} icon={<TitleIcon />}>
      <div className={styles.modalContentWrapper}>
        <div className={styles.modalTopInfo}>{tu("UserCompaniesModalDescription")}</div>
        {/* Only for eden admin. Normal users should add/remove differently */}
        {!hasEdenAdminPermission && (
          <Note noteType={NoteType.error}>{tv("You lack the required permissions to access this resource")}</Note>
        )}
        {hasEdenAdminPermission && (
          <>
            {currentUser?.userId === userId && <Note noteType={NoteType.warning}>{tu("cannotEditOwnCompanies")}</Note>}
            {/* User info */}
            <div className={styles.userCompaniesModalSection}>
              <div className={styles.smallLabel}>{tu("User")}</div>
              <div className={styles.companySelectUserName}>
                {user?.firstName} {user?.lastName} ({user?.email})
              </div>
            </div>
            {/* User companies list */}
            <div className={styles.userCompaniesModalSection}>
              <div className={styles.smallLabel}>{tu("UserInCompanies")}</div>
              <div className={styles.smallDescription}>{tu("UserInCompaniesDescription")}</div>
              <div className={styles.companyList}>
                {userCompanies?.map((userCompany) => {
                  const symbol = DefaultSymbol;
                  // TODO get symbol from the logo bytes
                  /*if (userCompany.symbol) {

                    //symbol = userCompany.logo;
                  }*/
                  return (
                    <div className={styles.companyListItem} key={userCompany.companyId}>
                      <div className={styles.companySymbol}>
                        <img src={symbol} alt="company logo" />
                      </div>
                      <div className={styles.companyListCompanyName}>{userCompany.companyName}</div>
                    </div>
                  );
                })}
              </div>
            </div>
            {/* Add to company */}
            <Form
              className={styles.userCompaniesModalSection}
              intercomTarget={"add-user-to-company-form"}
              onSubmit={handleSubmitAdd}
            >
              <div>
                <Select
                  intercomTarget={"select-add-user-company"}
                  label={tu("AddToCompanySelect")}
                  name="company"
                  options={
                    // filter out companies that the user is already in
                    allCompanies
                      ?.filter((c) => !userCompanies?.find((uc) => uc.companyId === c.companyId))
                      .map((company) => ({ label: company.companyName, value: company.companyId })) as SelectOption[]
                  }
                  onChange={(e) => setSelectedAddToCompany((e as { value: string }).value)}
                />
              </div>
              <ButtonRow>
                <Button
                  intercomTarget={"add-user-company-button"}
                  buttonColor={ColorVariant.success}
                  type={"submit"}
                  disabled={
                    loadingUserCompanies ||
                    loadingAllCompanies ||
                    loadingUser ||
                    loadingAdd ||
                    loadingRemove ||
                    !selectedAddToCompany ||
                    currentUser?.userId === userId
                  }
                  icon={<AddIcon />}
                >
                  {tu("AddToCompany")}
                </Button>
              </ButtonRow>
              {addErrMessage && <Note noteType={NoteType.error}>{tv(addErrMessage)}</Note>}
            </Form>
            {/* Remove from company */}
            <Form
              className={styles.userCompaniesModalSection}
              intercomTarget={"remove-user-from-company-form"}
              onSubmit={handleSubmitRemove}
            >
              <div>
                <Select
                  intercomTarget={"select-remove-user-company"}
                  label={tu("RemoveFromCompanySelect")}
                  name="company"
                  options={
                    // show only companies that the user is in
                    allCompanies
                      ?.filter((c) => userCompanies?.find((uc) => uc.companyId === c.companyId))
                      .map((company) => ({ label: company.companyName, value: company.companyId })) as SelectOption[]
                  }
                  onChange={(e) => setSelectedRemoveFromCompany((e as { value: string }).value)}
                />
              </div>
              <ButtonRow>
                <Button
                  intercomTarget={"remove-user-company-button"}
                  buttonColor={ColorVariant.error}
                  type={"submit"}
                  disabled={
                    loadingUserCompanies ||
                    loadingAllCompanies ||
                    loadingUser ||
                    loadingAdd ||
                    loadingRemove ||
                    !selectedRemoveFromCompany ||
                    currentUser?.userId === userId
                  }
                  icon={loadingRemove ? <Spinny /> : <RemoveIcon />}
                >
                  {tu("RemoveFromCompany")}
                </Button>
              </ButtonRow>
              {removeErrMessage && <Note noteType={NoteType.error}>{tv(removeErrMessage)}</Note>}
            </Form>
          </>
        )}
      </div>
    </PopoutPageTemplate>
  );
}

export default EditUserCompaniesModal;

import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { CheckoutContext } from "pages/checkout/CheckoutPage";
import ExistingCustomer from "features/existingCustomer/ExistingCustomer";

export default function ExistingCheckoutCustomer(props: { navigateToOnEdit?: string }) {
  const { t } = useTranslation("checkout");

  const { setIsGuest, setEmail, setEditCustomer, loggedInCustomer } = useContext(CheckoutContext);

  return (
    <ExistingCustomer
      header={t("Billing information")}
      loggedInCustomer={loggedInCustomer}
      setIsGuest={setIsGuest}
      setEmail={setEmail}
      setEditCustomer={setEditCustomer}
      navigateToOnEdit={props.navigateToOnEdit}
    />
  );
}

import styles from "pages/checkout/paymentMethods/paymentMethods.module.scss";
import { PaymentMethod, PaymentSessionStatus, FundingStatus, FeeStatus, CompliancePostOperation } from "api/api";
import LightningAttempt from "pages/checkout/paymentMethods/Lightning/LightningAttempt";
import OnChainAttempt from "pages/checkout/paymentMethods/OnChain/OnChainAttempt";
import { ReactNode, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckoutContext } from "pages/checkout/CheckoutPage";
import { ChevronDown12Filled as ChevronDownIcon, ChevronUp12Filled as ChevronUpIcon } from "@fluentui/react-icons";
import classNames from "classnames";
import Note, { NoteType } from "features/note/Note";
import { Link } from "react-router-dom";
import * as routes from "constants/routes";

export default function PaymentMethodRenderer(props: { method: PaymentMethod | undefined }) {
  const { t } = useTranslation("checkout");

  const { paymentSessionStatus, attemptResp, loggedInCustomer, setPaymentMethod, sessionId } =
    useContext(CheckoutContext);

  const [paymentMethodCollapsed, setPaymentMethodCollapsed] = useState<boolean>(true);

  function getBlockedByComplianceMessage() {
    const postOperations = attemptResp?.data?.compliancePostOperations as CompliancePostOperation[] | undefined;

    if (postOperations !== undefined) {
      if (postOperations.includes(CompliancePostOperation.DigitalKnowYourCustomer)) {
        return (
          <div>
            <p>{t("MustKYCInfo")}</p>
            <Link to={`${routes.CONSUMER_ACCOUNT_EDIT}?ps=${sessionId}`}>{t("Proceed to identification")}</Link>
          </div>
        );
      }

      // TODO add different compliance messages like fill more info for PEP check
    }

    // default error if blocked by compliance
    return (
      <div>
        <p>{t("PaymentBlockedByComplianceInfo")}</p>
        <p>{loggedInCustomer?.customerId}</p>
      </div>
    );
  }

  useEffect(() => {
    setPaymentMethodCollapsed(false);
  }, [paymentSessionStatus]);

  useEffect(() => {
    if (attemptResp?.data?.blockedByCompliance) {
      setPaymentMethod(undefined);
    }
  }, [attemptResp?.data?.blockedByCompliance]);

  function getPaymentMethod(method: PaymentMethod | undefined): ReactNode {
    switch (method) {
      case PaymentMethod.Lightning:
        return <LightningAttempt />;
      case PaymentMethod.OnChain:
        return <OnChainAttempt />;
      case PaymentMethod.Card:
        return <div>{t("Card")}</div>;
      case PaymentMethod.Invoice:
        return <div>{t("Invoice")}</div>;
      case undefined:
        return <div></div>;
    }
  }

  return (
    <div className={styles.paymentMethodWrapper}>
      <div className={styles.paymentStatusContainer}>
        {attemptResp?.isError && (
          <Note noteType={NoteType.error} title={t("ErrorGettingPaymentDetailsTitle")}>
            {t("ErrorGettingPaymentDetailsInfo")}
          </Note>
        )}
        {attemptResp?.data?.blockedByCompliance && (
          <Note noteType={NoteType.error} title={t("PaymentBlockedByComplianceTitle")}>
            {getBlockedByComplianceMessage()}
          </Note>
        )}
        {paymentSessionStatus.status === PaymentSessionStatus.FundingInTransit && (
          <>
            {/* the payment method that was used */}
            {paymentSessionStatus.paymentMethod === PaymentMethod.OnChain &&
              paymentSessionStatus.fundingStatus !== FundingStatus.UnderFunded && (
                <Note noteType={NoteType.success} title={t("PaymentInTransit")}>
                  {t("PaymentInTransitInfoOnChainOk")}
                </Note>
              )}

            {paymentSessionStatus.fundingStatus === FundingStatus.UnderFunded && (
              <Note noteType={NoteType.error} title={t("PaymentInTransit")}>
                {t("PaymentUnderpaidOnChainInfo")}
              </Note>
            )}

            {/* fee status */}
            {paymentSessionStatus.feeStatus === FeeStatus.Insufficient && (
              <Note noteType={NoteType.warning}>{t("PaymentInsufficientFeeInfo")}</Note>
            )}
            {paymentSessionStatus.feeStatus === FeeStatus.Low && (
              <Note noteType={NoteType.warning}>{t("PaymentLowFeeInfo")}</Note>
            )}

            {props.method && (
              <div className={styles.paymentMethodCollapseLink}>
                {paymentMethodCollapsed && (
                  <span onClick={() => setPaymentMethodCollapsed(false)}>
                    {t("ShowPaymentMethod")}
                    <ChevronDownIcon />
                  </span>
                )}
                {!paymentMethodCollapsed && (
                  <span onClick={() => setPaymentMethodCollapsed(true)}>
                    {t("HidePaymentMethod")}
                    <ChevronUpIcon />
                  </span>
                )}
              </div>
            )}
          </>
        )}
      </div>
      <div className={classNames({ [styles.collapse]: paymentMethodCollapsed })}>{getPaymentMethod(props.method)}</div>
    </div>
  );
}

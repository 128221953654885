import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { CheckoutContext } from "pages/checkout/CheckoutPage";
import CustomerEmailLoginFields from "features/customerEmailLoginFields/CustomerEmailLoginFields";

type Props = {
  paymentSessionId: string;
};

export default function CheckoutEmailLoginFields(props: Props) {
  const { t } = useTranslation("checkout");

  const { setCustomerResp, setEmail, email, isGuest, setIsGuest } = useContext(CheckoutContext);

  return (
    <CustomerEmailLoginFields
      header={t("Billing information")}
      paymentSessionId={props.paymentSessionId}
      setCustomerResp={setCustomerResp}
      setEmail={setEmail}
      email={email}
      isGuest={isGuest}
      setIsGuest={setIsGuest}
    />
  );
}

import PopoutPageTemplate from "features/templates/popoutPageTemplate/PopoutPageTemplate";
import { useNavigate, useParams } from "react-router-dom";
import {
  Person20Regular as UserIcon,
  Save20Regular as SaveIcon,
  BinRecycle20Regular as RemoveUserIcon,
} from "@fluentui/react-icons";
import Button, { ButtonRow, ColorVariant } from "components/buttons/Button";
import { Checkbox, Form } from "components/forms/forms";
import { useTranslation } from "react-i18next";
import { FormEvent, useEffect, useState } from "react";
import styles from "pages/users/UsersPage.module.scss";
import api from "api/api";
import { getErrorResponse } from "components/errors/validationErrors";
import Note, { NoteType } from "features/note/Note";
import Spinny from "features/spinny/Spinny";
import { useConfirmation } from "components/dialogue/ConfirmContext";
import { useHasPermission } from "features/authorization/ComponentPermissionChecker";
import NoMatch from "pages/noMatch/NoMatch";

function EditUserAdminPermissionsModal() {
  const { t: tu } = useTranslation("user");
  const { t: tp } = useTranslation("permissions");
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const confirm = useConfirmation();
  const { userId } = useParams<{ userId: string }>();
  const [errMessage, setErrMessage] = useState<string>();
  const { data: currentUser, error: currentUserError } = api.useGetSelfUserQuery();
  const currentUserHasEdenAdminPermission = useHasPermission(["edenAdmin"]);

  const [edenAdminChecked, setEdenAdminChecked] = useState(false);

  if (currentUserError) {
    console.error("Error getting current user", currentUserError);
  }

  const { data: userPermissions } = api.useGetUserHasEdenAdminRoleQuery(userId || "", { skip: !userId });

  useEffect(() => {
    if (userPermissions?.permissionRoles?.includes("edenAdmin")) {
      setEdenAdminChecked(true);
    } else {
      setEdenAdminChecked(false);
    }
  }, [userPermissions?.permissionRoles]);

  const [
    updateEdenAdminPermission,
    { error: permissionSaveErrors, isLoading: loadingSavePerm, isSuccess: isSuccessUpdatePermission },
  ] = api.useUpdateUserEdenAdminPermissionRoleMutation();

  useEffect(() => {
    if (isSuccessUpdatePermission) {
      navigate(-1);
    }
  }, [isSuccessUpdatePermission]);

  useEffect(() => {
    const err = getErrorResponse(permissionSaveErrors);
    setErrMessage(err?.message);
  }, [permissionSaveErrors]);

  const [deleteUser, { error: deleteUserErrors, isLoading: loadingDeleteUser, isSuccess: isSuccessDelete }] =
    api.useDeleteUserMutation();

  useEffect(() => {
    if (isSuccessDelete) {
      navigate(-1);
    }
  }, [isSuccessDelete]);

  useEffect(() => {
    const err = getErrorResponse(deleteUserErrors);
    setErrMessage(err?.message);
  }, [deleteUserErrors]);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const result = await confirm({
      title: t("Confirm Action", { ns: "common" }),
      message: <div>{tu("confirmGiveAdminRights")}</div>,
    });
    if (result) {
      // Send it to the API
      const roles: string[] = [];
      if (edenAdminChecked) {
        roles.push("edenAdmin");
      }
      updateEdenAdminPermission({
        userId: userId || "",
        roles: {
          roles: roles,
        },
      });
    }
  };

  if (!currentUserHasEdenAdminPermission) {
    return <NoMatch />;
  }

  const handleRemoveUser = () => {
    return async () => {
      const result = await confirm({
        title: t("Confirm Action", { ns: "common" }),
        message: (
          <div>
            <div>{tu("confirmRemoveUserFully")}</div>
            <div className={styles.removeUserConfirmUserName}>
              {userPermissions?.firstName} {userPermissions?.lastName} ({userPermissions?.email})
            </div>
          </div>
        ),
      });
      if (result) {
        deleteUser(userId || "");
      }
    };
  };

  return (
    <PopoutPageTemplate
      title={tu("EditUserEdenAdminPermissions")}
      show={true}
      onClose={() => navigate(-1)}
      icon={<UserIcon />}
    >
      <div className={styles.modalContentWrapper}>
        {currentUser?.userId === userId && (
          <div className={styles.modalTopInfo}>
            <Note noteType={NoteType.warning}>{tu("cannotEditOwnPermissions")}</Note>
          </div>
        )}
        <div>
          <h4 className={styles.permissionsModalUserNameHeader}>{tu("User")}</h4>
        </div>
        <div className={styles.permissionsModalUserName}>
          {userPermissions?.firstName} {userPermissions?.lastName} ({userPermissions?.email})
        </div>
        <div>
          <h4>{t("Permissions", { ns: "common" })}</h4>
        </div>
        <Form intercomTarget={"permissions-form"} onSubmit={handleSubmit}>
          <div className={styles.permissionItem} key={"edenAdmin"}>
            <Checkbox
              name={"edenAdmin"}
              label={tp("edenAdmin.label")}
              disabled={currentUser?.userId === userId}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEdenAdminChecked(e.target.checked)}
              checked={edenAdminChecked}
            />
            <div className={styles.permissionDescription}>{tp("edenAdmin.description")}</div>
          </div>
          {/* If there are errors then add a Note */}
          {(permissionSaveErrors || deleteUserErrors) && (
            <Note noteType={NoteType.error} title={t("Error")}>
              {errMessage}
            </Note>
          )}

          <ButtonRow>
            <Button
              intercomTarget={"save-permissions-button"}
              buttonColor={ColorVariant.success}
              type={"submit"}
              disabled={loadingSavePerm || currentUser?.userId === userId}
              icon={loadingSavePerm ? <Spinny /> : <SaveIcon />}
            >
              {t("Save permissions")}
            </Button>
          </ButtonRow>
        </Form>
        <div className={styles.removeUserButton}>
          <Button
            intercomTarget={"remove-user-button"}
            buttonColor={ColorVariant.error}
            type={"button"}
            onClick={handleRemoveUser()}
            disabled={loadingDeleteUser || currentUser?.userId === userId}
            icon={loadingDeleteUser ? <Spinny /> : <RemoveUserIcon />}
          >
            {tu("removeUser")}
          </Button>
        </div>
      </div>
    </PopoutPageTemplate>
  );
}

export default EditUserAdminPermissionsModal;

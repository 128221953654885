import api, { ProductList, ProductRecord, Status, VATType } from "api/api";
// import * as routes from "constants/routes";
import { useAppSelector } from "store/hooks";
import { selectSelectedCompanyId } from "features/navigation/navSlice";
import Table from "features/table/Table";
import DefaultCellRenderer from "features/table/DefaultCellRenderer";
import { useTranslation } from "react-i18next";
import { usePagination } from "components/table/pagination/usePagination";
import { ColumnMetaData } from "features/table/types";
import TablePageTemplate, { TableControlSection } from "features/templates/tablePageTemplate/TablePageTemplate";
// import { useHasPermission } from "features/authorization/ComponentPermissionChecker";
import { useTableColumns } from "features/table/tableColumnHooks";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import { getErrorResponse } from "components/errors/validationErrors";
import { toastCategory } from "features/toast/Toasts";
import { useEffect, useState } from "react";
import React from "react";
import ToastContext from "features/toast/context";
import Button, { ColorVariant } from "components/buttons/Button";
import ViewsSidebar from "features/viewManagement/ViewsSidebar";
import { Options20Regular as OptionsIcon, ArrowSync20Regular as RefreshIcon } from "@fluentui/react-icons";
import { FilterInterface } from "features/sidebar/sections/filter/filter";
import { EditRegular as EditIcon, Add20Regular as AddIcon } from "@fluentui/react-icons";
import { formatAmountFunc } from "features/table/columnFormatFunctions";
import { EnumAsOptionsLabelKey } from "api/apiUtils";
import { useLocation, useNavigate } from "react-router-dom";
import * as routes from "constants/routes";
import { useHasPermission } from "features/authorization/ComponentPermissionChecker";

const columns: ColumnMetaData<ProductRecord>[] = [
  {
    heading: "Edit",
    type: "LinkCell",
    key: "productId",
    valueType: "multilink",
    headingLocalizePackage: "common",
    multilinkData: [
      {
        basePath: routes.COMPANY_PRODUCT_EDIT.replace(":productId", ""),
        text: "Edit",
        icon: <EditIcon />,
        internal: true,
        disabled: false,
        color: ColorVariant.primary,
        localizePackage: "shop",
      },
    ],
  },
  {
    heading: "ProductName",
    type: "TextCell",
    key: "productName",
    valueType: "string",
    headingLocalizePackage: "shop",
    sortable: true,
    filterable: true,
  },
  {
    heading: "DisplayName",
    type: "TextCell",
    key: "displayName",
    valueType: "string",
    headingLocalizePackage: "shop",
    sortable: true,
    filterable: true,
  },
  {
    heading: "Price",
    type: "TextCell",
    key: "priceFloat",
    valueType: "currency",
    formatFunction: formatAmountFunc,
    headingLocalizePackage: "shop",
    sortable: true,
    filterable: false,
  },
  {
    heading: "Description",
    type: "TextCell",
    key: "description",
    valueType: "string",
    headingLocalizePackage: "shop",
    sortable: true,
    filterable: true,
  },
  {
    heading: "DeliveryType",
    type: "TextCell",
    key: "deliveryType",
    valueType: "enum",
    headingLocalizePackage: "shop",
    sortable: true,
    filterable: true,
    selectOptions: [
      {
        label: "Physical",
        value: "physical",
      },
      {
        label: "Virtual",
        value: "virtual",
      },
    ],
  },
  {
    heading: "Status",
    type: "TextCell",
    key: "status",
    valueType: "enum",
    headingLocalizePackage: "shop",
    sortable: true,
    filterable: true,
    selectOptions: EnumAsOptionsLabelKey(Status),
  },
  {
    heading: "VATType",
    type: "TextCell",
    key: "vatType",
    valueType: "enum",
    headingLocalizePackage: "shop",
    sortable: true,
    filterable: true,
    selectOptions: EnumAsOptionsLabelKey(VATType),
  },
  {
    heading: "Website",
    type: "TextCell",
    key: "website",
    valueType: "string",
    headingLocalizePackage: "common",
    sortable: false,
    filterable: false,
  },
];

function ProductsPage() {
  const { t } = useTranslation("shop");
  const selectedCompanyId = useAppSelector(selectSelectedCompanyId);
  const toastRef = React.useContext(ToastContext);
  const navigate = useNavigate();
  const location = useLocation();
  const productsWritePermission = useHasPermission(["companyProductsWriteRead"]);

  const [cols, sortBy, sortDirection, filters, filterJSON, handleFilterUpdate] =
    useTableColumns<ProductRecord>(columns);

  const [getPaginator, limit, offset] = usePagination("productPagination", 50);

  const productsResponse = api.useListCompanyProductsQuery<{
    data: ProductList;
    isLoading: boolean;
    isFetching: boolean;
    isUninitialized: boolean;
    isSuccess: boolean;
    error: FetchBaseQueryError | SerializedError | undefined;
  }>(
    {
      companyId: selectedCompanyId || "",
      limit: limit,
      offset: offset,
      sortby: sortBy,
      sortdirection: sortDirection,
      filter: filterJSON,
    },
    { skip: !selectedCompanyId },
  );

  useEffect(() => {
    const err = getErrorResponse(productsResponse.error);
    if (err?.message) {
      toastRef?.current?.addToast(err.message, toastCategory.error);
    }
  }, [productsResponse.error]);

  const [sidebarExpanded, setSidebarExpanded] = useState(false);

  const closeSidebarHandler = () => {
    setSidebarExpanded(false);
  };

  const tableControls = (
    <TableControlSection intercomTarget={"table-page-controls"}>
      <Button
        intercomTarget="refresh-products-table"
        buttonColor={ColorVariant.primary}
        icon={<RefreshIcon />}
        onClick={() => {
          productsResponse.refetch();
        }}
      />
      <Button
        intercomTarget="add-product"
        buttonColor={ColorVariant.overlay}
        icon={<AddIcon />}
        disabled={!productsWritePermission}
        onClick={() => {
          navigate(routes.COMPANY_PRODUCT_ADD, { state: { background: location } });
        }}
      />
      <Button
        intercomTarget="table-settings"
        onClick={() => {
          setSidebarExpanded(!sidebarExpanded);
        }}
        icon={<OptionsIcon />}
        hideMobileText={true}
        id={"tableControlsButton"}
      >
        {t("TableOptions", { ns: "common" })}
      </Button>
    </TableControlSection>
  );

  const sidebar = (
    <ViewsSidebar
      onExpandToggle={closeSidebarHandler}
      expanded={sidebarExpanded}
      allColumns={cols}
      filterTemplate={{ key: "", category: "string" } as FilterInterface}
      filters={filters}
      handleFilterUpdate={handleFilterUpdate}
    />
  );

  return (
    <TablePageTemplate
      title={t("Products")}
      titleContent={""}
      sidebarExpanded={sidebarExpanded}
      sidebar={sidebar}
      tableControls={tableControls}
      pagination={getPaginator(productsResponse.data?.totalCount || 0)}
    >
      <Table
        intercomTarget={"customers-table"}
        cellRenderer={DefaultCellRenderer}
        data={productsResponse.data?.records || []}
        activeColumns={cols}
        isLoading={productsResponse.isLoading || productsResponse.isFetching || productsResponse.isUninitialized}
      />
    </TablePageTemplate>
  );
}

export default ProductsPage;

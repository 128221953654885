import api, { CustomerRecord } from "api/api";
import ExistingCustomer from "features/existingCustomer/ExistingCustomer";
import { useTranslation } from "react-i18next";
import * as routes from "constants/routes";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Card, { CardHeader } from "components/cards/Card";
import styles from "pages/consumerAccount/consumerAccountPage.module.scss";
import Button from "components/buttons/Button";
import { BTC } from "constants/backend";
import i18n from "i18n";
import { QueryStatus } from "@reduxjs/toolkit/query";
import CustomerLogin from "features/customerEmailLoginFields/CustomerLogin";

function formatMSatAmount(amountMsat?: number): string {
  if (amountMsat === undefined) {
    return "";
  }

  // round down to sat accuracy
  const satAmount = (amountMsat / 1000).toFixed(0);

  return i18n.t("SatSpaceFormatterWithSymbol", { value: satAmount, ns: "currency" });
}

function ConsumerAccountPage() {
  const { t } = useTranslation("customer");
  const navigate = useNavigate();
  const location = useLocation();
  const [loggedInCustomer, setLoggedInCustomer] = useState<CustomerRecord | undefined | null>();

  const loggedInCustomerResp = api.useGetCustomerQuery(undefined, {
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
  });

  const bitcoinBalanceResp = api.useGetCustomerBalanceQuery(BTC, {
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
    skip: !loggedInCustomer,
  });

  useEffect(() => {
    if (
      loggedInCustomerResp?.isSuccess === true &&
      !loggedInCustomerResp?.isFetching &&
      loggedInCustomerResp.status === QueryStatus.fulfilled
    ) {
      setLoggedInCustomer(loggedInCustomerResp?.data);
    } else if (
      loggedInCustomerResp.isError ||
      loggedInCustomerResp.isUninitialized ||
      loggedInCustomerResp.status === QueryStatus.rejected
    ) {
      setLoggedInCustomer(undefined);
    }
  }, [loggedInCustomerResp]);

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.pageContent}>
        {/* show login if not logged in */}
        {!loggedInCustomer && (
          <Card className={styles.loginCard}>
            <CustomerLogin header={t("Log in", { ns: "common" })} />
          </Card>
        )}

        {loggedInCustomer && (
          <>
            <div>
              <ExistingCustomer
                header={t("Account")}
                loggedInCustomer={loggedInCustomerResp?.data}
                navigateToOnEdit={routes.CONSUMER_ACCOUNT_EDIT}
              />
            </div>

            <Card>
              <CardHeader
                header={
                  <>
                    <div>{t("Balance")}</div>
                    <div>
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(routes.CONSUMER_ACCOUNT_BITCOIN_WITHDRAW, { state: { background: location } });
                        }}
                      >
                        {t("Withdraw", { ns: "withdraw" })}
                      </Button>
                    </div>
                  </>
                }
              />
              {/* for now just bitcoin balance */}
              <div className={styles.balancesWrapper}>{formatMSatAmount(bitcoinBalanceResp.data?.balance)}</div>
            </Card>
            <Card>
              <CardHeader header={t("Payment History")} />
              <div>Coming soon...</div>
            </Card>
          </>
        )}
      </div>
    </div>
  );
}

export default ConsumerAccountPage;

import api from "api/api";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import {
  Mail20Regular as EmailIcon,
  Password20Regular as PinIcon,
  Password20Regular as PasswordIcon,
} from "@fluentui/react-icons";
import styles from "features/customerEmailLoginFields/customerEmailLoginFields.module.scss";
import Button, { ButtonPosition, ButtonRow } from "components/buttons/Button";
import { Input } from "components/forms/forms";
import { FormEvent, useEffect, useRef, useState } from "react";
import { getErrorResponse } from "components/errors/validationErrors";
import Spinny from "features/spinny/Spinny";
import { useDispatch } from "react-redux";
import * as routes from "constants/routes";
import { useNavigate } from "react-router-dom";

type Props = {
  header?: string;
  paymentSessionId?: string;
  refundSessionId?: string;
  className?: string;
};

// Basic customer login. Does not allow to create new account.

export default function CustomerLogin(props: Props) {
  const { t, i18n } = useTranslation("common");
  const { t: tc } = useTranslation("checkout");
  const navigate = useNavigate();

  const emailInput = useRef<HTMLInputElement>(null);

  const [requiresPin, setRequiresPin] = useState<boolean>(false);
  const [currentPin, setCurrentPin] = useState<string>();
  const [checkPinErrors, setCheckPinErrors] = useState<string[] | undefined>(undefined);
  const [requiresPassword, setRequiresPassword] = useState<boolean>(true); // use password by default
  const [currentPassword, setCurrentPassword] = useState<string>();
  const [checkPasswordErrors, setCheckPasswordErrors] = useState<string[] | undefined>(undefined);

  const dispatch = useDispatch();

  const [loginCustomer, loginResp] = api.useLoginCustomerMutation();

  // login
  useEffect(() => {
    if (loginResp?.data?.token) {
      // put customer token in session storage, to clear after closing the browser
      window.sessionStorage.setItem("token", loginResp?.data?.token);
      window.localStorage.removeItem("token");
    }

    // dispatch manually after setting the token to trigger logged in customer refetch
    dispatch(api.util.invalidateTags(["customer"]));
  }, [loginResp]);

  useEffect(() => {
    if (!loginResp?.error) {
      setCheckPinErrors(undefined);
      setCheckPasswordErrors(undefined);
      return;
    }

    const err = getErrorResponse(loginResp?.error);

    if (loginResp?.error && err?.message) {
      let errMsg = err.message;

      if (i18n.exists(err.message, { ns: "validation" })) {
        errMsg = t(err.message, { ns: "validation" });
      } else if (i18n.exists(err.message, { ns: "compliance" })) {
        errMsg = t(err.message, { ns: "compliance" });
      } else {
        errMsg = tc(err.message);
      }

      if (requiresPin) {
        setCheckPinErrors([errMsg]);
        setCheckPasswordErrors(undefined);
      }

      if (requiresPassword) {
        setCheckPasswordErrors([errMsg]);
        setCheckPinErrors(undefined);
      }
    }
  }, [loginResp]);

  function validatePin(pin: string): boolean {
    const onlyNumbers = /^\d+$/.test(pin) || pin === "";
    const maxLength = pin.length <= 4;

    return onlyNumbers && maxLength;
  }

  function handleEnterKey(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      event.preventDefault();
      login();
    }
  }

  function validateAndSetCheckPin(event: FormEvent<HTMLInputElement>) {
    if (validatePin(event.currentTarget.value)) {
      setCheckPinErrors(undefined);
      setCurrentPin(event.currentTarget.value);
    } else {
      // cannot set pin of wrong format
      setCheckPinErrors([tc("PinCodeFormatErr")]);
    }
  }

  function validateAndSetCheckPassword(event: FormEvent<HTMLInputElement>) {
    // no need to validate password in login
    setCurrentPassword(event.currentTarget.value);
  }

  function login() {
    let pin: string | undefined = undefined;
    let password: string | undefined = undefined;

    if (requiresPin) {
      if (!currentPin) {
        setCheckPinErrors([tc("PinCodeRequiredErr")]);
        return;
      }
      pin = currentPin;
    }

    if (requiresPassword) {
      if (!currentPassword) {
        setCheckPasswordErrors([tc("PasswordRequiredErr")]);
        return;
      }
      password = currentPassword;
    }

    loginCustomer({
      email: emailInput?.current?.value || "",
      pin: pin,
      password: password,
    });
  }

  function toggleUsePasswordInsteadOfPin(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    if (loginResp.isLoading) {
      return;
    }

    setCurrentPin("");
    setCurrentPassword("");

    if (requiresPin) {
      setRequiresPin(false);
      setRequiresPassword(true);
    } else {
      setRequiresPin(true);
      setRequiresPassword(false);
    }
  }

  function navigateForgotPassword(event: React.MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();

    if (props.paymentSessionId) {
      navigate(routes.FORGOT_PASSWORD_CUSTOMER + "?checkout=" + props.paymentSessionId, {
        state: { email: emailInput.current?.value },
      });
      return;
    }

    if (props.refundSessionId) {
      navigate(routes.FORGOT_PASSWORD_CUSTOMER + "?refund=" + props.refundSessionId, {
        state: { email: emailInput.current?.value },
      });
      return;
    }

    navigate(routes.FORGOT_PASSWORD_CUSTOMER, {
      state: { email: emailInput.current?.value },
    });
  }

  return (
    <div className={classNames(styles.formPart, props.className)}>
      <div className={styles.header}>{props.header}</div>
      <Input
        leftIcon={<EmailIcon />}
        ref={emailInput}
        label={tc("EmailInput")}
        name={"email"}
        required={true}
        type={"email"}
        onKeyDown={handleEnterKey}
        autoFocus={true}
      />
      {requiresPin && (
        <Input
          autoFocus={true}
          pinLength={4}
          maxLength={4}
          minLength={4}
          required={true}
          leftIcon={<PinIcon />}
          label={tc("PinCodeInput")}
          name={"checkPin"}
          value={currentPin || ""}
          type={"password"}
          onChange={validateAndSetCheckPin}
          onKeyDown={handleEnterKey}
          errors={checkPinErrors}
        />
      )}
      {requiresPassword && (
        <Input
          autoFocus={true}
          minLength={8}
          required={true}
          leftIcon={<PasswordIcon />}
          label={tc("PasswordInput")}
          name={"checkPassword"}
          value={currentPassword || ""}
          type={"password"}
          onChange={validateAndSetCheckPassword}
          onKeyDown={handleEnterKey}
          errors={checkPasswordErrors}
        />
      )}
      <div className={styles.usePinOrPassword}>
        <a href="#" onClick={toggleUsePasswordInsteadOfPin}>
          {requiresPin ? t("Use password instead") : t("Use pin instead")}
        </a>
      </div>
      <ButtonRow>
        <Button
          intercomTarget={"next-email-set"}
          type={"button"}
          onClick={() => login()}
          disabled={loginResp.isLoading}
          buttonPosition={ButtonPosition.right}
          icon={loginResp.isLoading ? <Spinny /> : undefined}
        >
          {t("Next")}
        </Button>
      </ButtonRow>
      <div className={styles.forgotPassword}>
        <a href={routes.FORGOT_PASSWORD_CUSTOMER} onClick={navigateForgotPassword}>
          {t("Forgot your pin or password?")}
        </a>
      </div>
    </div>
  );
}

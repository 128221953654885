import { PaymentMethod } from "api/api.generated";
import Button from "components/buttons/Button";
import { getErrorResponse } from "components/errors/validationErrors";
import { Input, Select, TextArea } from "components/forms/forms";
import { SelectOption } from "components/forms/select/Select";
import { MilliSatInBTC } from "constants/backend";
import Note, { NoteType } from "features/note/Note";
import i18n from "i18n";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ToastContext from "features/toast/context";
import { toastCategory } from "features/toast/Toasts";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import styles from "features/withdraw/bitcoinWithdraw/baseBitcoinWithdraw.module.scss";
import api from "api/api";
import Spinny from "features/spinny/Spinny";
import HelpTooltip from "components/forms/helpTooltip/helpTooltip";

const decodeInvoiceRateLimitMs = 1000;

const paymentMethodOptions: SelectOption[] = [
  { label: "Lightning", value: PaymentMethod.Lightning },
  { label: "On-chain", value: PaymentMethod.OnChain },
];

function formatMSatAmount(amountMsat?: number): string {
  if (amountMsat === undefined) {
    return "";
  }

  // round down to sat accuracy
  const satAmount = (amountMsat / 1000).toFixed(0);

  return i18n.t("SatSpaceFormatterWithSymbol", { value: satAmount, ns: "currency" });
}

export enum BitcoinWithdrawType {
  company = "company",
  customer = "customer",
}

export type BaseBitcoinWithdrawProps = {
  type: BitcoinWithdrawType;
  balanceMSat?: number;
  companyId?: string;
  withdrawLightning: (paymentRequest: string, amountMsat?: number) => void;
  withdrawOnChain: (address: string, amountMsat: number) => void;
  withdrawSuccess: boolean;
  withdrawError: FetchBaseQueryError | SerializedError | undefined;
  withdrawLoading: boolean;
};

function BaseBitcoinWithdraw(props: BaseBitcoinWithdrawProps) {
  const { t } = useTranslation("withdraw");
  const toastRef = useContext(ToastContext);

  const [withdrawAmountMsat, setWithdrawAmountMsat] = useState<number | undefined>(0);
  const [withdrawMethod, setWithdrawMethod] = useState<string>("");
  // address/invoice/etc.
  const [withdrawDetail, setWithdrawDetail] = useState<string>("");
  const [errMessage, setErrMessage] = useState<string>();
  const [lastDecodeInvoiceRequestTime, setLastDecodeInvoiceRequestTime] = useState<number | undefined>();
  const [pendingDecodeInvoiceRequest, setPendingDecodeInvoiceRequest] = useState<boolean>(false);

  const [decodeLightningInvoice, decodeLightningInvoiceResp] = api.useDecodeLightningInvoiceMutation();

  function triggerDecodeLightningInvoice(paymentRequest?: string) {
    if (withdrawMethod !== PaymentMethod.Lightning) {
      setPendingDecodeInvoiceRequest(false);
      return;
    }

    if (
      !decodeLightningInvoiceResp.isLoading &&
      (!lastDecodeInvoiceRequestTime || Date.now() - lastDecodeInvoiceRequestTime > decodeInvoiceRateLimitMs)
    ) {
      setPendingDecodeInvoiceRequest(false);
      // use either from state or param
      decodeLightningInvoice({ paymentRequest: ((paymentRequest || withdrawDetail) as string).trim() });
      return;
    }

    setPendingDecodeInvoiceRequest(true);
  }

  useEffect(() => {
    if (props.withdrawSuccess) {
      toastRef?.current?.addToast(t("Withdraw successful"), toastCategory.success);
    }
  }, [props.withdrawSuccess]);

  // default max amount
  useEffect(() => {
    if (!withdrawAmountMsat && props.balanceMSat) {
      setWithdrawAmountMsat(props.balanceMSat);
    }
  }, [props.balanceMSat]);

  useEffect(() => {
    if (props.withdrawError) {
      const err = getErrorResponse(props.withdrawError);
      if (err?.message) {
        setErrMessage(t(err.message));
        return;
      }
    }

    setErrMessage(undefined);
  }, [props.withdrawError]);

  useEffect(() => {
    if (!decodeLightningInvoiceResp?.isUninitialized) {
      setLastDecodeInvoiceRequestTime(Date.now());
    }

    if (decodeLightningInvoiceResp?.data?.amountMsat) {
      setWithdrawAmountMsat(decodeLightningInvoiceResp?.data?.amountMsat);
    }
  }, [decodeLightningInvoiceResp]);

  // decode if pending and ratelimit passed
  useEffect(() => {
    const interval = setInterval(() => {
      if (pendingDecodeInvoiceRequest) {
        triggerDecodeLightningInvoice();
      }
    }, 50);

    return () => clearInterval(interval);
  }, [pendingDecodeInvoiceRequest]);

  function getWithdrawDetailLabel() {
    if (withdrawMethod === PaymentMethod.Lightning) {
      return t("Lightning invoice");
    } else if (withdrawMethod === PaymentMethod.OnChain) {
      return t("On-chain address");
    }

    return "";
  }

  function handleWithdraw() {
    if (!withdrawAmountMsat) {
      return;
    }

    if (withdrawMethod === PaymentMethod.Lightning) {
      props.withdrawLightning(withdrawDetail.trim(), parseInt(withdrawAmountMsat.toFixed(0)));
    } else if (withdrawMethod === PaymentMethod.OnChain) {
      props.withdrawOnChain(withdrawDetail, withdrawAmountMsat);
    }
  }

  function handleChangeWithdrawDetail(event: React.ChangeEvent<HTMLTextAreaElement>) {
    const detail: string = event.target.value;
    setWithdrawDetail(detail);

    if (withdrawMethod === PaymentMethod.Lightning) {
      // decode or set pending to wait for ratelimit
      triggerDecodeLightningInvoice(detail);
    } else {
      setPendingDecodeInvoiceRequest(false);
    }
  }

  return (
    <div className={styles.withdrawWrapper}>
      <div className={styles.withdrawInfoRow}>
        <div>{t("Funds available for withdrawal")}</div>
        <div>{formatMSatAmount(props.balanceMSat)}</div>
        <div>
          <HelpTooltip label={t("Funds available for withdrawal")} text={t("FundsAvailableTooltip")} showUnder={true} />
        </div>
      </div>
      <div>
        <Select
          label={t("Withdraw method")}
          name={"withdrawMethod"}
          options={paymentMethodOptions}
          onChange={(newValue: unknown) => setWithdrawMethod((newValue as SelectOption).value as PaymentMethod)}
          value={paymentMethodOptions.find((option) => option.value === withdrawMethod)}
          required
        />
      </div>
      <div>
        {withdrawMethod && (
          <TextArea
            label={getWithdrawDetailLabel()}
            name={"withdrawDetail"}
            onChange={handleChangeWithdrawDetail}
            value={withdrawDetail}
            required
            rows={6}
            errorText={
              withdrawMethod === PaymentMethod.Lightning && decodeLightningInvoiceResp?.isError
                ? t("Invalid invoice")
                : undefined
            }
          />
        )}
      </div>
      <div className={styles.withdrawAmountInput}>
        <Input
          type={"number"}
          label={t("Withdrawal amount (BTC)")}
          name={"withdrawAmount"}
          value={parseFloat(((withdrawAmountMsat || 0) / MilliSatInBTC).toFixed(8))}
          max={props.balanceMSat || 0 / MilliSatInBTC}
          min={0}
          step={1e-8}
          onChange={(e) => setWithdrawAmountMsat(parseFloat(e.target.value) * MilliSatInBTC)}
          readOnly={!!decodeLightningInvoiceResp?.data?.amountMsat}
          required={!decodeLightningInvoiceResp?.data?.amountMsat}
          infoText={!!decodeLightningInvoiceResp?.data?.amountMsat ? t("Amount defined by the payment request") : ""}
          errors={
            props.balanceMSat && withdrawAmountMsat && withdrawAmountMsat > props.balanceMSat
              ? [t("Insufficient funds")]
              : undefined
          }
        />
        <Button
          type={"button"}
          onClick={() => setWithdrawAmountMsat(props.balanceMSat || 0)}
          disabled={!!decodeLightningInvoiceResp?.data?.amountMsat}
        >
          {t("Max", { ns: "common" })}
        </Button>
      </div>
      <div>
        <Button
          type={"button"}
          onClick={() => handleWithdraw()}
          disabled={
            withdrawMethod === "" ||
            withdrawDetail === "" ||
            props.withdrawLoading ||
            !withdrawAmountMsat ||
            (withdrawMethod === PaymentMethod.Lightning && !decodeLightningInvoiceResp?.isSuccess)
          }
          icon={props.withdrawLoading ? <Spinny /> : undefined}
        >
          {t("Withdraw")}
        </Button>
      </div>
      {errMessage && (
        <Note noteType={NoteType.error} title={t("Payment error")}>
          {withdrawMethod === PaymentMethod.Lightning ? t("lightningError." + errMessage) : t(errMessage)}
        </Note>
      )}
    </div>
  );
}

export default BaseBitcoinWithdraw;

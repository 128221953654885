import styles from "features/section/sectionContainer.module.scss";
import {
  ChevronDown20Regular as CollapsedIcon,
  FluentIconsProps,
  LineHorizontal120Regular as ExpandedIcon,
} from "@fluentui/react-icons";
import classNames from "classnames";
import React from "react";
import Collapse from "features/collapse/Collapse";

type SectionContainerProps = {
  title: string;
  icon: React.FC<FluentIconsProps>;
  children: React.ReactNode;
  expanded?: boolean;
  disabled?: boolean;
  handleToggle?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  intercomTarget?: string;
  colorVariant?: SectionContainerColor;
  tightContent?: boolean;
};

export enum SectionContainerColor {
  primary = "primary",
  success = "success",
  warning = "warning",
  error = "error",
  accent1 = "accent1",
  accent2 = "accent2",
  accent3 = "accent3",
  disabled = "disabled",
}

export const SectionContainerColorClasses = new Map<SectionContainerColor, string>([
  [SectionContainerColor.primary, styles.primary],
  [SectionContainerColor.success, styles.success],
  [SectionContainerColor.warning, styles.warning],
  [SectionContainerColor.error, styles.error],
  [SectionContainerColor.accent1, styles.accent1],
  [SectionContainerColor.accent2, styles.accent2],
  [SectionContainerColor.accent3, styles.accent3],
  [SectionContainerColor.disabled, styles.disabled],
]);

export function SectionContainer(props: SectionContainerProps) {
  const colorClass = SectionContainerColorClasses.get(props.colorVariant || SectionContainerColor.primary);
  return (
    <div
      className={classNames(styles.sectionContainer, colorClass, {
        [styles.disabled]: props.disabled,
        [styles.expaned]: props.expanded,
      })}
      data-intercom-target={props.intercomTarget}
      data-testid={props.intercomTarget}
    >
      <div className={styles.sectionTitleContainer} onClick={props.handleToggle}>
        <div className={styles.sidebarIcon}>
          <props.icon />
        </div>
        <div className={styles.sidebarTitle}>{props.title}</div>
        <div className={styles.sidebarIcon}>{props.expanded ? <ExpandedIcon /> : <CollapsedIcon />}</div>
      </div>
      <Collapse
        className={classNames(styles.sectionContainer, { [styles.disabled]: props.disabled })}
        collapsed={!props.expanded}
        animate={true}
      >
        <div
          className={classNames(styles.sidebarSectionContent, {
            [styles.sidebarSectionContentTight]: props.tightContent,
          })}
        >
          {props.children}
        </div>
      </Collapse>
    </div>
  );
}

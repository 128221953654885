import api from "api/api";
import Button, { ButtonPosition } from "components/buttons/Button";
import { ColorVariant } from "components/buttons/buttonVariants";
import { InputRow, Input, Form } from "components/forms/forms";
import { useHasPermission } from "features/authorization/ComponentPermissionChecker";
import { selectSelectedCompanyId } from "features/navigation/navSlice";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router";
import { useAppSelector } from "store/hooks";
import * as routes from "constants/routes";
import { Image20Regular as ImageIcon } from "@fluentui/react-icons";

export default function UpdateCompanyShopForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t: tc } = useTranslation("company");
  const selectedCompanyId = useAppSelector(selectSelectedCompanyId);

  const writePermission = useHasPermission(["companyWriteRead"]);

  const { data: company } = api.useGetCompanyByIdQuery(selectedCompanyId || "", {
    skip: !selectedCompanyId,
  });

  // TODO allow update, currently readonly
  return (
    <Form intercomTarget={"update-company-shop-form"}>
      <InputRow verticalAlign="top">
        <Input disabled={true} name={"shopHandle"} label={tc("ShopHandle")} value={company?.shopHandle}></Input>
        <Input disabled={true} name={"shopTitle"} label={tc("ShopTitle")} value={company?.shopTitle}></Input>
      </InputRow>
      <InputRow verticalAlign="top">
        <Input
          disabled={true}
          name={"shopRedirectUrl"}
          label={tc("ShopRedirectUrl")}
          value={company?.shopRedirectUrl}
        ></Input>
      </InputRow>
      <div>
        <Button
          type={"button"}
          disabled={!writePermission || !selectedCompanyId}
          buttonPosition={ButtonPosition.none}
          buttonColor={ColorVariant.primary}
          icon={<ImageIcon />}
          onClick={() => {
            navigate(routes.COMPANY_SHOP_FAVICON.replace(":companyId", selectedCompanyId || ""), {
              state: { background: location },
            });
          }}
        >
          {tc("ShopFavicon")}
        </Button>
      </div>
    </Form>
  );
}

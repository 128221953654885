import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { ServerErrorResultType } from "components/errors/errors";
import ShoppingCart from "pages/shop/ShoppingCart";
import ShopProducts from "pages/shop/ShopProducts";
import styles from "pages/shop/shopPage.module.scss";
import ShopHeader from "pages/shop/ShopHeader";
import { useParams } from "react-router-dom";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import api, { CompanyShopRecord, ProductRecord, ShoppingCartRecord, ShoppingCartRecordJoined } from "api/api";
import { getErrorResponse } from "components/errors/validationErrors";
import ShippingAddress from "pages/shop/ShippingAddress";
import { SerializedError } from "@reduxjs/toolkit";
import { toastCategory } from "features/toast/Toasts";
import { useTranslation } from "react-i18next";
import ToastContext from "features/toast/context";
import { getRestEndpoint } from "utils/apiUrlBuilder";
import { DynamicHtmlHeadContext } from "features/dynamicHtmlHead/DynamicHtmlHeadContext";

export enum ShopView {
  PRODUCTS = "products",
  CART = "cart",
}

export type ShopViewParams = {
  shop?: CompanyShopRecord;
  setCartId?: Dispatch<SetStateAction<string | undefined>>;
  shoppingCart?: ShoppingCartRecordJoined;
  updateProductInCart?: (
    product: ProductRecord,
    quantity: number,
    shoppingCartId: string,
    productAddedCallBack?: (product: ProductRecord, quantity: number) => void,
  ) => void;
  handleApiError: (err: ServerErrorResultType | FetchBaseQueryError | SerializedError) => void;
};

export function isFullShippingAddressRequired(cart?: ShoppingCartRecordJoined) {
  return cart?.products?.some((product) => product.deliveryType === "physical") || false;
}

export function isRequiredShippingAddressSet(cart?: ShoppingCartRecordJoined) {
  // requires full address if any delivery is physical
  const requiresFullAddress = isFullShippingAddressRequired(cart);
  const a = cart?.shippingAddress;

  if (requiresFullAddress) {
    return a?.streetName && a?.postCode && a?.townName && a?.countryIso3;
  }

  return a?.countryIso3 !== undefined && a?.countryIso3 !== "";
}

function ShopPage(props: { view: ShopView }) {
  const { t: tv } = useTranslation("validation");
  const toastRef = useContext(ToastContext);
  const { shopHandle } = useParams();
  const [cartId, setCartId] = useState<string | undefined>();

  const [editShippingAddress, setEditShippingAddress] = useState(true);

  const dynamicHtmlHead = useContext(DynamicHtmlHeadContext);

  // get cart from session storage
  useEffect(() => {
    if (shopHandle) {
      const cId = sessionStorage.getItem(shopHandle + "-cartId");
      setCartId(cId || undefined);
    }
  }, [shopHandle]);

  // cart to session storage
  useEffect(() => {
    if (shopHandle && cartId) {
      sessionStorage.setItem(shopHandle + "-cartId", cartId);
    }
  }, [cartId]);

  const shopResponse = api.useGetShopDetailsQuery(shopHandle || "", {
    refetchOnMountOrArgChange: true,
    skip: !shopHandle,
  });

  const shoppingCartResponse = api.useGetShoppingCartQuery(
    { companyShop: shopHandle || "", shoppingCartId: cartId || "" },
    {
      refetchOnMountOrArgChange: true,
      skip: !shopHandle || !cartId,
    },
  );

  useEffect(() => {
    if (shopResponse.error) {
      console.error(shopResponse.error);
    }

    if (shopResponse?.data?.shopTitle) {
      dynamicHtmlHead.setTitle(shopResponse.data.shopTitle);
    } else if (shopResponse?.data?.displayName) {
      dynamicHtmlHead.setTitle(shopResponse.data?.displayName + " Shop");
    }

    if (shopResponse?.data?.publicShopFaviconAttachmentPath) {
      dynamicHtmlHead.setFaviconUrl(getRestEndpoint() + shopResponse?.data?.publicShopFaviconAttachmentPath);
    }

    // reset defaults
    return () => {
      dynamicHtmlHead.setFaviconUrl(undefined);
      dynamicHtmlHead.setTitle(undefined);
    };
  }, [shopResponse]);

  const [addProductToCartMutation] = api.useAddShoppingCartProductMutation();

  function handleApiError(err: ServerErrorResultType | FetchBaseQueryError | SerializedError) {
    let e = (err as ServerErrorResultType)?.data?.errors?.server?.[0].description;

    if (!e) {
      e = getErrorResponse(err as FetchBaseQueryError)?.message;
    }

    if (e) {
      toastRef?.current?.addToast(tv(e), toastCategory.error);
      return;
    }

    console.error("Unknown error", err);
  }

  function updateProductInCart(
    product: ProductRecord,
    quantity: number,
    shoppingCartId: string,
    productAddedCallBack?: (product: ProductRecord, quantity: number) => void,
  ) {
    if (!shopHandle || !product.productId || !shoppingCartId) {
      return;
    }

    addProductToCartMutation({
      companyShop: shopHandle,
      productId: product.productId,
      quantity: quantity,
      shoppingCartId: shoppingCartId,
    })
      .unwrap()
      .catch((err: ServerErrorResultType | FetchBaseQueryError) => {
        handleApiError(err);
      })
      .then((res: ShoppingCartRecord | void) => {
        if (!res?.shoppingCartId) {
          console.error("Error adding product to cart");
          return;
        }

        productAddedCallBack?.(product, quantity);
      });
  }

  if (!shopHandle || shopResponse.error) {
    return (
      <div className={styles.shopPageWrapper}>
        <h1>Shop does not exist</h1>
      </div>
    );
  }

  return (
    <div className={styles.shopPageWrapper}>
      {shopHandle && (
        <>
          <ShopHeader cartId={cartId} shopHandle={shopHandle} companyShop={shopResponse.data} />
          {props.view === ShopView.PRODUCTS && (
            <ShopProducts
              shop={shopResponse.data}
              shoppingCart={shoppingCartResponse?.data}
              setCartId={setCartId}
              updateProductInCart={updateProductInCart}
              handleApiError={handleApiError}
            />
          )}
          {props.view === ShopView.CART && (
            <div className={styles.shoppingCartContainer}>
              <ShippingAddress
                shop={shopResponse.data}
                shoppingCart={shoppingCartResponse?.data}
                handleApiError={handleApiError}
                editing={editShippingAddress}
                setEditing={setEditShippingAddress}
              />
              <ShoppingCart
                shop={shopResponse.data}
                shoppingCart={shoppingCartResponse?.data}
                updateProductInCart={updateProductInCart}
                handleApiError={handleApiError}
                editingShippingAddress={editShippingAddress}
                setEditingShippingAddress={setEditShippingAddress}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default ShopPage;

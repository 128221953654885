import { Currency } from "api/api.generated";

export function CurrencyFloatAmountToBaseAmount(
  amount: number,
  currencyIso3: string,
  allCurrencies: Currency[],
): number | undefined {
  const currency = allCurrencies.find((c) => c.ISO3 === currencyIso3);
  if (!currency || currency.DecimalPlaces === undefined) {
    return undefined;
  }

  // cast to integer
  return Math.floor(amount * 10 ** currency.DecimalPlaces + 0.5);
}

import styles from "pages/checkout/checkoutSuccess/CheckoutSuccess.module.scss";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { CheckoutContext } from "pages/checkout/CheckoutPage";

const redirectTimeout = 7000;

export default function RedirectCounter() {
  const { t } = useTranslation("checkout");

  const [redirectAt, setRedirectAt] = useState<Date | null>(null);
  const [untilRedirect, setUntilRedirect] = useState<number | null>(null); // until redirect to success page

  const { sessionResp } = useContext(CheckoutContext);

  function startRedirectCountdown() {
    setRedirectAt(new Date(new Date().getTime() + redirectTimeout));
  }

  function calculateUntilRedirect(): number | null {
    if (!redirectAt) {
      return null;
    }

    const now = new Date().getTime();
    const timeLeft = redirectAt.getTime() - now;

    return Math.floor(timeLeft / 1000); // seconds
  }

  // handle payment status change
  useEffect(() => {
    startRedirectCountdown();
  }, []);

  // 1 second ticker to
  // - do expiry countdown and get new payment attempt if expired
  // - do countdown to redirect if paid
  useEffect(() => {
    setUntilRedirect(calculateUntilRedirect());

    const oneSecondTickerTimer = setInterval(() => {
      setUntilRedirect(calculateUntilRedirect());

      const untilRedirect = calculateUntilRedirect();
      if (untilRedirect != null && untilRedirect <= 0) {
        console.log("Redirecting to success page");

        if (sessionResp?.data?.redirectUrl) {
          window.location.href = sessionResp?.data?.redirectUrl;
        } else {
          setRedirectAt(null);
          setUntilRedirect(null);
        }
      }
    }, 1000);

    // Clear interval on unmount
    return () => clearInterval(oneSecondTickerTimer);
  }, [sessionResp?.data, redirectAt]);

  return (
    <div className={styles.redirectMessage}>
      {t("RedirectingIn")} {untilRedirect} {t("sec")}
    </div>
  );
}

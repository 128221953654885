import { useTranslation } from "react-i18next";
import { ArrowHookUpLeft20Regular as RefundsIcon, Add20Regular as AddIcon } from "@fluentui/react-icons";
import PopoutPageTemplate from "features/templates/popoutPageTemplate/PopoutPageTemplate";
import { useNavigate, useParams } from "react-router-dom";
import styles from "pages/refunds/refunds.module.scss";
import api, { RefundSessionStatus, RefundSession, RefundSessionListRecord } from "api/api";
import { useAppSelector } from "store/hooks";
import { selectSelectedCompanyId } from "features/navigation/navSlice";
import { formatAmountFunc, formatTranslateFunc } from "features/table/columnFormatFunctions";
import { Checkbox, Form, Input, TextArea } from "components/forms/forms";
import { useContext, useEffect, useRef, useState } from "react";
import Button, { ButtonRow, ColorVariant } from "components/buttons/Button";
import { DateTimeLocalAsTimeString, FormToJson } from "api/apiUtils";
import { CurrencyFloatAmountToBaseAmount } from "utils/currency";
import { format } from "date-fns-tz";
import ToastContext from "features/toast/context";
import Note, { NoteType } from "features/note/Note";
import { getErrorResponse } from "components/errors/validationErrors";
import { useHasPermission } from "features/authorization/ComponentPermissionChecker";
import { toastCategory } from "features/toast/Toasts";
import { useConfirmation } from "components/dialogue/ConfirmContext";
import { CLAIM_REFUND } from "constants/routes";

function getClaimRefundLink(refundSessionId?: string) {
  if (!refundSessionId) {
    return "";
  }

  return window.location.host + CLAIM_REFUND + "/" + refundSessionId;
}

function refundExceedsPaymentSession(
  newRefund: RefundSession,
  existingRefunds: RefundSessionListRecord[] | undefined,
  paymentSession: RefundSession,
): boolean {
  let refundsAmountTotal = newRefund.amount || 0;

  existingRefunds?.forEach((refund) => {
    if (
      refund.status === RefundSessionStatus.Initiated ||
      refund.status === RefundSessionStatus.Active ||
      refund.status === RefundSessionStatus.Accepted ||
      refund.status === RefundSessionStatus.Exchanged ||
      refund.status === RefundSessionStatus.Refunded
    ) {
      refundsAmountTotal += refund.amount || 0;
    }
  });

  if (refundsAmountTotal > (paymentSession?.amount || 0)) {
    return true;
  }

  return false;
}

function RefundModal() {
  const navigate = useNavigate();
  const { t } = useTranslation("payment");
  const [fetchErrMessage, setFetchErrMessage] = useState<string>("");
  const toastRef = useContext(ToastContext);
  const confirm = useConfirmation();
  const customersReadPermission = useHasPermission(["companyCustomersRead"]);
  const [newRefundFormVisible, setNewRefundFormVisible] = useState<boolean>(false);

  const { data: currencies, error: currenciesError } = api.useGetCurrenciesQuery();

  const { paymentSessionId } = useParams();
  const selectedCompanyId = useAppSelector(selectSelectedCompanyId);

  const { data: paymentSession, error: paymentSessionError } = api.useGetCompanyPaymentSessionQuery(
    {
      companyId: selectedCompanyId || "",
      paymentSessionId: paymentSessionId || "",
    },
    { skip: !paymentSessionId || !selectedCompanyId, refetchOnMountOrArgChange: true },
  );

  const { data: paymentSessionCustomer, error: paymentSessionCustomerError } = api.useGetCustomerFromCompanyQuery(
    {
      companyId: selectedCompanyId || "",
      customerId: paymentSession?.customerId || "",
    },
    {
      skip: !paymentSession?.customerId || !selectedCompanyId || !customersReadPermission,
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    data: existingRefundSessionsData,
    error: existingRefundSessionsError,
    isSuccess: existingRefundSessionsSuccess,
  } = api.useGetPaymentSessionRefundSessionsQuery(
    {
      companyId: selectedCompanyId || "",
      paymentSessionId: paymentSessionId || "",
    },
    { skip: !paymentSessionId || !selectedCompanyId, refetchOnMountOrArgChange: true },
  );

  const oldRefundSessions = existingRefundSessionsData?.records;

  const [createRefund, createRefundResp] = api.useCreateCompanyRefundSessionMutation();

  const [cancelRefund, cancelRefundResp] = api.useCancelCompanyRefundSessionMutation();

  const [refundFullAmount, setRefundFullAmount] = useState<boolean>(false);

  const amountInputRef = useRef<HTMLInputElement>(null);

  // show or hide form based on existing refund sessions
  useEffect(() => {
    if (existingRefundSessionsSuccess && !oldRefundSessions?.length) {
      setNewRefundFormVisible(true);
    }
  }, oldRefundSessions);

  useEffect(() => {
    if (!amountInputRef.current) {
      return;
    }

    if (refundFullAmount && paymentSession?.amountFloat) {
      amountInputRef.current.value = paymentSession?.amountFloat.toString();
      return;
    }

    if (!refundFullAmount) {
      amountInputRef.current.value = "";
    }
  }, [refundFullAmount, paymentSession?.amountFloat]);

  useEffect(() => {
    if (createRefundResp.isSuccess) {
      toastRef?.current?.addToast(t("Refund Initiated"), toastCategory.success);
      // hide new refund when success
      setNewRefundFormVisible(false);
    }
  }, [createRefundResp.isSuccess]);

  useEffect(() => {
    const err = getErrorResponse(createRefundResp.error);
    if (err?.message) {
      toastRef?.current?.addToast(err?.message, toastCategory.error);
    }
  }, [createRefundResp.error]);

  useEffect(() => {
    if (cancelRefundResp.isSuccess) {
      toastRef?.current?.addToast(t("Refund Cancelled"), toastCategory.warn);
    }
  }, [cancelRefundResp.isSuccess]);

  useEffect(() => {
    const err = getErrorResponse(cancelRefundResp.error);
    if (err?.message) {
      toastRef?.current?.addToast(err?.message, toastCategory.error);
    }
  }, [cancelRefundResp.error]);

  useEffect(() => {
    let errMsg: string = "";

    if (paymentSessionError) {
      const err = getErrorResponse(paymentSessionError);
      errMsg += errMsg ? ", " + err?.message : err?.message;
    }

    if (paymentSessionCustomerError) {
      const err = getErrorResponse(paymentSessionCustomerError);
      errMsg += errMsg ? ", " + err?.message : err?.message;
    }

    if (existingRefundSessionsError) {
      const err = getErrorResponse(existingRefundSessionsError);
      errMsg += errMsg ? ", " + err?.message : err?.message;
    }

    if (currenciesError) {
      const err = getErrorResponse(currenciesError);
      errMsg += errMsg ? ", " + err?.message : err?.message;
    }

    setFetchErrMessage(errMsg);
  }, [paymentSessionError, paymentSessionCustomerError, existingRefundSessionsError, currenciesError]);

  async function submitRefund(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!paymentSession || !currencies) {
      return;
    }

    const formJson = FormToJson<RefundSession>(event);

    formJson.currencyIso3 = paymentSession?.currencyIso3;

    // cast to base amount
    formJson.amount = CurrencyFloatAmountToBaseAmount(formJson.amount || 0, formJson.currencyIso3 || "", currencies);

    // cast datetime input
    formJson.expiresAt = DateTimeLocalAsTimeString(formJson.expiresAt);

    let result = true;

    if (refundExceedsPaymentSession(formJson, oldRefundSessions, paymentSession)) {
      result = await confirm({
        title: t("Confirm Action", { ns: "common" }),
        message: t("confirmIssueRefundLargerThanPaymentSession"),
      });
    }

    if (result) {
      createRefund({
        companyId: selectedCompanyId || "",
        paymentSessionId: paymentSessionId || "",
        refundSession: formJson,
      });
    }
  }

  function handleCancelRefund(refundSessionId: string | undefined) {
    if (!refundSessionId || !paymentSession || !selectSelectedCompanyId) {
      return;
    }

    cancelRefund({
      companyId: selectedCompanyId || "",
      refundSessionId: refundSessionId,
      paymentSessionId: paymentSessionId || "",
    });
  }

  return (
    <PopoutPageTemplate
      title={t("Refund") + " " + paymentSession?.description}
      show={true}
      onClose={() => navigate(-1)}
      icon={<RefundsIcon />}
    >
      <div className={styles.modalContentWrapper}>
        {fetchErrMessage && (
          <Note noteType={NoteType.error} title={t("Error")}>
            {fetchErrMessage}
          </Note>
        )}
        <div className={styles.originalPaymentSessionContent}>
          <h4>{t("originalPaymentSession")}</h4>
          <div className={styles.originalPaymentSessionRow}>
            <div>ID</div>
            <div>{paymentSession?.paymentSessionId}</div>
          </div>
          <div className={styles.originalPaymentSessionRow}>
            <div>{t("amount")}</div>
            <div>{formatAmountFunc(paymentSession, "amountFloat")}</div>
          </div>
          {customersReadPermission && (
            <div className={styles.originalPaymentSessionRow}>
              <div>{t("customer")}</div>
              <div>
                {paymentSessionCustomer && (
                  <>
                    {paymentSessionCustomer?.firstName} {paymentSessionCustomer?.lastName} (
                    {paymentSessionCustomer?.email})
                  </>
                )}
                {!paymentSessionCustomer && <>{t("Not found", { ns: "common" })}</>}
              </div>
            </div>
          )}
          <div className={styles.originalPaymentSessionRow}>
            <div>{t("status")}</div>
            <div>{formatTranslateFunc(paymentSession, "status", { translationPackage: "payment" })}</div>
          </div>
          <div className={styles.originalPaymentSessionRow}>
            <div>{t("paidAt")}</div>
            <div>{paymentSession?.paidAt ? format(new Date(paymentSession?.paidAt), "yyyy-MM-dd HH:mm:ss") : "-"}</div>
          </div>
        </div>
        <div className={styles.newRefundContent}>
          <h4>{t("Refund")}</h4>
          {!newRefundFormVisible && (
            <div>
              <Button
                buttonColor={ColorVariant.primary}
                onClick={() => {
                  setNewRefundFormVisible(true);
                }}
                icon={<AddIcon />}
              >
                {t("New", { ns: "common" })} {t("Refund")}
              </Button>
            </div>
          )}
          {newRefundFormVisible && (
            <Form onSubmit={submitRefund} intercomTarget={"refund-form"}>
              <Checkbox
                label={t("refundFullAmount")}
                checked={refundFullAmount}
                onChange={(e) => {
                  setRefundFullAmount(e.target.checked);
                }}
              />
              <div className={styles.refundAmountInput}>
                <Input
                  ref={amountInputRef}
                  label={t("Amount to refund")}
                  type={"number"}
                  min={0}
                  step={0.01}
                  name={"amount"}
                  readOnly={refundFullAmount}
                />
                <span>{paymentSession?.currencyIso3}</span>
              </div>
              <Input label={t("refundReference")} name={"reference"} />
              <Input
                label={t("description")}
                name={"description"}
                defaultValue={paymentSession?.description ? t("Refund") + " " + paymentSession?.description : undefined}
              />
              <TextArea label={t("details")} name={"details"} />
              <Input
                label={t("expiresAt")}
                type={"datetime-local"}
                name={"expiresAt"}
                helpText={t("Leave empty to use a default value", { ns: "common" })}
              />
              <ButtonRow>
                <Button type={"submit"} disabled={createRefundResp.isLoading || !paymentSession || !currencies}>
                  {t("Issue a refund")}
                </Button>
              </ButtonRow>
            </Form>
          )}
        </div>
        {oldRefundSessions && oldRefundSessions.length > 0 && (
          <div className={styles.existingRefundsContent}>
            <h4>{t("Existing refunds")}</h4>
            {oldRefundSessions?.map((refundSession) => (
              <div className={styles.existingRefund} key={refundSession.refundSessionId}>
                <div className={styles.existingRefundRow}>
                  <div>ID</div>
                  <div>{refundSession?.refundSessionId}</div>
                </div>
                <div className={styles.existingRefundRow}>
                  <div>{t("Refund claim link")}</div>
                  <div>{getClaimRefundLink(refundSession?.refundSessionId)}</div>
                </div>
                <div className={styles.existingRefundRow}>
                  <div>{t("amount")}</div>
                  <div>{formatAmountFunc(refundSession, "amountFloat")}</div>
                </div>
                <div className={styles.existingRefundRow}>
                  <div>{t("description")}</div>
                  <div>{refundSession?.description}</div>
                </div>
                <div className={styles.existingRefundRow}>
                  <div>{t("status")}</div>
                  <div>{formatTranslateFunc(refundSession, "status", { translationPackage: "payment" })}</div>
                </div>
                <div className={styles.existingRefundRow}>
                  <div>{t("createdAt")}</div>
                  <div>
                    {refundSession?.createdAt ? format(new Date(refundSession?.createdAt), "yyyy-MM-dd HH:mm:ss") : "-"}
                  </div>
                </div>
                <div className={styles.existingRefundRow}>
                  <div>{t("refundedAt")}</div>
                  <div>
                    {refundSession?.paidAt ? format(new Date(refundSession?.paidAt), "yyyy-MM-dd HH:mm:ss") : "-"}
                  </div>
                </div>
                <div className={styles.existingRefundRow}>
                  <Button
                    type={"button"}
                    onClick={() => handleCancelRefund(refundSession.refundSessionId)}
                    buttonColor={ColorVariant.error}
                    disabled={refundSession?.status !== RefundSessionStatus.Initiated}
                  >
                    {t("Cancel")}
                  </Button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </PopoutPageTemplate>
  );
}

export default RefundModal;

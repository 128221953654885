import { useTranslation } from "react-i18next";
import { useContext } from "react";
import CustomerEmailLoginFields from "features/customerEmailLoginFields/CustomerEmailLoginFields";
import { RefundClaimContext } from "pages/refundClaim/RefundClaimPage";

export default function RefundEmailLoginFields() {
  const { t } = useTranslation("claimrefund");

  const { setCustomerResp, setEmail, email, refundSessionResp } = useContext(RefundClaimContext);

  return (
    <CustomerEmailLoginFields
      header={t("Recipient information")}
      refundSessionId={refundSessionResp?.data?.refundSessionId}
      setCustomerResp={setCustomerResp}
      setEmail={setEmail}
      email={email}
    />
  );
}

import {
  useCheckTokenQuery,
  useTokenResetPasswordUserMutation,
  useTokenResetPasswordCustomerMutation,
} from "api/manualApi";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LockOpen20Regular as UnlockIcon } from "@fluentui/react-icons";
import ToastContext from "features/toast/context";
import Input from "components/forms/input/Input";
import { Form } from "components/forms/forms";
import * as routes from "constants/routes";
import { TokenPasswordResetCustomerRequest, TokenPasswordResetUserRequest } from "types/api";
import React, { useEffect, useState } from "react";
import { toastCategory } from "features/toast/Toasts";
import styles from "features/auth/LoginPage.module.scss";
import Button, { ColorVariant } from "components/buttons/Button";
import { useTranslation } from "react-i18next";
import { FormToJson } from "api/apiUtils";
import { Tie, getErrorResponse } from "components/errors/validationErrors";
import { InputError } from "api/api.generated";
import Note, { NoteType } from "features/note/Note";
import { Password20Regular as PinIcon, Password20Regular as PasswordIcon } from "@fluentui/react-icons";
import Spinny from "features/spinny/Spinny";

const CustomerMinPasswordLength: number = 8;
const UserMinPasswordLength: number = 14;

function ResetPasswordPage() {
  const { t } = useTranslation("auth");
  const { t: tv } = useTranslation("validation");
  const { t: tc } = useTranslation("checkout");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get("token");
  const to = searchParams.get("to") || "";
  const [ie, setInputErrors] = useState<InputError[]>();
  const [resetUserPassword, { error: errorUser, isSuccess: isSuccessUser, isLoading: isLoadingUser }] =
    useTokenResetPasswordUserMutation();
  const [resetCustomerPassword, { error: errorCustomer, isSuccess: isSuccessCustomer, isLoading: isLoadingCustomer }] =
    useTokenResetPasswordCustomerMutation();

  const { data: checkTokenResp, error: tokenValidErr } = useCheckTokenQuery(token || "", { pollingInterval: 10000 });
  const [errMessage, setErrMessage] = useState<string>();
  const [checkTokenErrMessage, setCheckTokenErrMessage] = useState<string>();

  const toastRef = React.useContext(ToastContext);

  useEffect(() => {
    const err = getErrorResponse(errorUser || errorCustomer);
    setErrMessage(err?.message);
    if (err && err.inputErrors) {
      setInputErrors(err.inputErrors);
    }
  }, [errorUser, errorCustomer]);

  useEffect(() => {
    const err = getErrorResponse(tokenValidErr);
    if (err?.message) {
      setCheckTokenErrMessage(err?.message);
    }
    if (!err) {
      setCheckTokenErrMessage(undefined);
    }
  }, [tokenValidErr]);

  useEffect(() => {
    if (!token) {
      setCheckTokenErrMessage("Token missing");
    }
  }, [token]);

  useEffect(() => {
    if (isSuccessUser || isSuccessCustomer) {
      toastRef?.current?.addToast(t("passwordResetSuccess"), toastCategory.success);

      // Replace the current page with base path, to prevent the user from navigating back to it
      navigate(routes.ROOT, { replace: true });

      // Then navigate to the intended destination
      navigate(routes.ROOT + to);
    }
  }, [isSuccessUser, isSuccessCustomer]);

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (checkTokenResp?.validCustomer) {
      const formJson = FormToJson<TokenPasswordResetCustomerRequest>(e);
      formJson.signInToken = token || "";
      // remove pin if not set
      if (formJson.pin === "" && formJson.repeatPin === "") {
        delete formJson.pin;
        delete formJson.repeatPin;
      }
      await resetCustomerPassword(formJson);
    } else if (checkTokenResp?.validUser) {
      const formJson = FormToJson<TokenPasswordResetUserRequest>(e);
      formJson.signInToken = token || "";
      await resetUserPassword(formJson);
    }
  };

  return (
    <div className={styles.pageWrapper}>
      {checkTokenErrMessage && <Note noteType={NoteType.error}>{tv(checkTokenErrMessage)}</Note>}
      {!checkTokenErrMessage && (
        <Form className={styles.formWrapper} onSubmit={submit} intercomTarget={"login-form"}>
          <Input
            minLength={checkTokenResp?.validCustomer ? CustomerMinPasswordLength : UserMinPasswordLength}
            type="password"
            name={"password"}
            label={t("Password")}
            id={"password-field"}
            errors={Tie("Password", ie, ["user"])}
            leftIcon={<PasswordIcon />}
          />
          <Input
            minLength={checkTokenResp?.validCustomer ? CustomerMinPasswordLength : UserMinPasswordLength}
            type="password"
            name={"repeatPassword"}
            label={t("Repeat Password")}
            id={"repeat-password-field"}
            errors={Tie("RepeatPassword", ie, ["user"])}
            leftIcon={<PasswordIcon />}
          />
          {/* also allow pin reset for customer */}
          {checkTokenResp?.validCustomer && (
            <>
              <Input
                pinLength={4}
                maxLength={4}
                minLength={4}
                leftIcon={<PinIcon />}
                label={tc("PinCode")}
                name={"pin"}
                type={"password"}
                errors={Tie("PinCode", ie, ["checkout"])}
                helpText={tc("PinCodeCreateHelpText")}
              />
              <Input
                pinLength={4}
                maxLength={4}
                minLength={4}
                leftIcon={<PinIcon />}
                label={tc("RepeatPinCode")}
                name={"repeatPin"}
                type={"password"}
                errors={Tie("RepeatPinCode", ie, ["checkout"])}
              />
            </>
          )}
          <Button
            type="submit"
            buttonColor={ColorVariant.success}
            id={"submit-button"}
            intercomTarget={"reset-password-submit-button"}
            disabled={isLoadingUser || isLoadingCustomer}
            icon={isLoadingUser || isLoadingCustomer ? <Spinny /> : <UnlockIcon />}
          >
            {t("Reset Password")}
          </Button>
          {errMessage && <Note noteType={NoteType.error}>{tv(errMessage)}</Note>}
        </Form>
      )}
    </div>
  );
}

export default ResetPasswordPage;

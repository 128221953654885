import Button from "components/buttons/Button";
import styles from "pages/shop/shopHeader.module.scss";
import {
  BuildingShop20Regular as ShopIcon,
  Cart20Filled as CartIcon,
  Home20Filled as HomeIcon,
} from "@fluentui/react-icons";
import { useNavigate } from "react-router-dom";
import * as routes from "constants/routes";
import { CompanyShopRecord } from "api/api.generated";
import { getRestEndpoint } from "utils/apiUrlBuilder";
import { useEffect, useState } from "react";
import classNames from "classnames";

export default function ShopHeader(props: { cartId?: string; shopHandle: string; companyShop?: CompanyShopRecord }) {
  const navigate = useNavigate();
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled((document?.querySelector("#shop-layout-page")?.scrollTop || 0) > 0);
    };

    document?.querySelector("#shop-layout-page")?.addEventListener("scroll", handleScroll);
    return () => document?.querySelector("#shop-layout-page")?.removeEventListener("scroll", handleScroll);
  }, []);

  function shopBannerLogo() {
    return (
      <a rel={"noreferrer"} className={styles.shopBanner} href={props.companyShop?.website}>
        {/* logo */}
        {props.companyShop?.publicLogoAttachmentPath && (
          <img
            src={getRestEndpoint() + props.companyShop?.publicLogoAttachmentPath}
            alt={props.companyShop?.shopTitle}
          />
        )}

        {/* no logo */}
        {!props.companyShop?.publicLogoAttachmentPath && (
          <span>{props.companyShop?.shopTitle || props.companyShop?.displayName}</span>
        )}
      </a>
    );
  }

  return (
    <div className={classNames(styles.shopHeader, { [styles.scrolled]: scrolled })}>
      {shopBannerLogo()}
      <div className={styles.headerButtonsRight}>
        {(props.companyShop?.shopRedirectUrl || props.companyShop?.website) && (
          <Button
            onClick={() => {
              window.location.href = props.companyShop?.shopRedirectUrl || props.companyShop?.website || "";
            }}
            className={styles.headerButton}
            icon={<HomeIcon />}
          ></Button>
        )}
        <Button
          onClick={() => {
            navigate(routes.SHOP.replace(":shopHandle", props.shopHandle));
          }}
          className={styles.headerButton}
          icon={<ShopIcon />}
        ></Button>
        <Button
          onClick={() => {
            navigate(routes.SHOPPING_CART.replace(":shopHandle", props.shopHandle));
          }}
          disabled={!props.cartId}
          className={styles.headerButton}
          icon={<CartIcon />}
        ></Button>
      </div>
    </div>
  );
}

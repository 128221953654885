import { AttachmentRecord, AttachmentStatus, AttachmentType, AttachmentVisibility } from "api/api.generated";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import api from "api/api";
import { FormEvent, useContext, useEffect, useState } from "react";
import { FormToJson } from "api/apiUtils";
import { useHasPermission } from "features/authorization/ComponentPermissionChecker";
import File from "components/forms/file/File";
import Button, { ButtonRow, ColorVariant, SizeVariant } from "components/buttons/Button";
import { useTranslation } from "react-i18next";
import Form from "components/forms/form/Form";
import { getErrorResponse } from "components/errors/validationErrors";
import ToastContext from "features/toast/context";
import { toastCategory } from "features/toast/Toasts";
import { useConfirmation } from "components/dialogue/ConfirmContext";
import styles from "features/attachments/attachmentForm.module.scss";
import {
  ChevronUp20Filled as ChevronUpIcon,
  ChevronDown20Filled as ChevronDownIcon,
  ChevronDoubleUp20Filled as ChevronDoubleUpIcon,
  ChevronDoubleDown20Filled as ChevronDoubleDownIcon,
} from "@fluentui/react-icons";

export enum AttachmentFormVisualType {
  Picture = "picture",
  Document = "document",
}

const allowedImageTypes = [
  "image/jpeg",
  "image/gif",
  "image/png",
  "image/jpg",
  "image/svg+xml",
  "image/x-icon" /* favicon */,
];
const allowedDocumentTypes = ["image/*", "application/pdf", "application/msword", ".doc", ".docx", ".docm"];

export enum AttachmentCategory {
  Company = "company",
  ShopProductImage = "shopProductImage",
  Customer = "customer",
  CustomerIdentification = "customerIdentification",
}

export type AttachmentFormProps = {
  attachmentFormVisualType: AttachmentFormVisualType;
  category: AttachmentCategory;
  type: AttachmentType;
  defaultVisibility: AttachmentVisibility;
  onSuccess: () => void;
  // allowChangeVisibility?: boolean;
  title?: string;
  attachmentId?: string;
  primaryEntityId?: string; // company/customer/etc.
  secondaryEntityId?: string; // address/identification/etc.
  writePermissionNames?: string[];
  attachmentFileGetPath?: string;
  allowOrderChange?: boolean;
  maxFileSizeKB?: number;
};

function AttachmentForm(props: AttachmentFormProps) {
  const { t } = useTranslation("common");
  const writePermission = props.writePermissionNames ? useHasPermission(props.writePermissionNames) : undefined;
  const toastRef = useContext(ToastContext);
  const confirm = useConfirmation();

  const [fileData, setFileData] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string | undefined>();

  // TODO allow to change visibility in some cases (most cases should not be changed: for exampe favicon always public)
  //const [visibility, setVisibility] = useState<AttachmentVisibility>(props.defaultVisibility);
  const visibility = props.defaultVisibility;

  const getQueryResult = useGetAttachmentQueryByCategory(
    props.category,
    props.attachmentId,
    props.primaryEntityId,
    props.secondaryEntityId,
  );

  const { createTrigger, createResp } = useCreateAttachmentMutationByCategory(
    props.category,
    props.primaryEntityId,
    props.secondaryEntityId,
  );

  const { deleteTrigger, deleteResp } = useDeleteAttachmentMutationByCategory(
    props.category,
    props.attachmentId || "",
    props.primaryEntityId,
    props.secondaryEntityId,
  );

  const { updateOrderTrigger, updateOrderResp } = useUpdateAttachmentOrderMutationByCategory(
    props.category,
    props.attachmentId || "",
    props.primaryEntityId,
    props.secondaryEntityId,
  );

  useEffect(() => {
    // clear on attachment change
    setFileData(null);
    setFileName(undefined);
  }, [props.attachmentId, props.attachmentFileGetPath]);

  useEffect(() => {
    if (getQueryResult.data) {
      setFileName(getQueryResult.data.fileName);
    }
  }, [getQueryResult.data]);

  useEffect(() => {
    if (createResp?.isSuccess) {
      props.onSuccess();
      return;
    }

    if (createResp?.error) {
      const err = getErrorResponse(createResp?.error);
      if (err?.message) {
        toastRef?.current?.addToast(err.message, toastCategory.error);
      }
    }
  }, [createResp]);

  useEffect(() => {
    if (deleteResp?.isSuccess) {
      toastRef?.current?.addToast(t("Attachment deleted successfully"), toastCategory.success);
      return;
    }

    if (deleteResp?.error) {
      const err = getErrorResponse(deleteResp?.error);
      if (err?.message) {
        toastRef?.current?.addToast(err.message, toastCategory.error);
      }
    }
  }, [deleteResp]);

  useEffect(() => {
    // no need to notify on every success

    if (updateOrderResp?.error) {
      const err = getErrorResponse(updateOrderResp?.error);
      if (err?.message) {
        toastRef?.current?.addToast(err.message, toastCategory.error);
      }
    }
  }, [updateOrderResp]);

  async function handleDelete() {
    if (!deleteTrigger || !props.attachmentId) {
      return;
    }

    const result = await confirm({
      title: t("Confirm Action"),
      message: t("confirmDelete"),
    });
    if (result) {
      deleteTrigger();
    }
  }

  async function handleUpdateOrder(orderChangeAmount: number) {
    if (!updateOrderTrigger || !props.attachmentId) {
      return;
    }

    updateOrderTrigger(orderChangeAmount);
  }

  async function submit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (createTrigger && !props.attachmentId) {
      if (!writePermission) {
        return;
      }

      const formJson = FormToJson<AttachmentRecord>(event);
      if (fileData) {
        formJson.file = fileData;
        formJson.fileName = fileData.name;
        formJson.fileType = fileData.type;
        formJson.status = AttachmentStatus.Active; // TODO allow to set to inactive
        formJson.visibility = visibility;
        formJson.type = props.type;
      }

      createTrigger(formJson);
    }
  }

  return (
    <Form onSubmit={submit} intercomTarget={"create-attachment-form"}>
      <div className={styles.fileColumnsWrapper}>
        <File
          // TODO: Better way to replace images
          disabled={!writePermission || !!props.attachmentId}
          disabledInfoText={
            !!props.attachmentId
              ? "To replace the file, delete the existing one first"
              : !writePermission
                ? "No permission"
                : undefined
          }
          label={props.title}
          onFileChange={(file: File | null) => {
            setFileName(file?.name || "");
            setFileData(file);
          }}
          fileName={fileName}
          allowedFileTypes={
            props.attachmentFormVisualType === AttachmentFormVisualType.Picture
              ? allowedImageTypes
              : allowedDocumentTypes
          }
          fileGetPath={props.attachmentFileGetPath}
          className={styles.fileInput}
          maxFileSizeKB={props.maxFileSizeKB}
        />
        {props.attachmentId && props.allowOrderChange && (
          <div className={styles.orderButtons}>
            <Button
              type={"button"}
              disabled={!writePermission}
              buttonColor={ColorVariant.primary}
              onClick={() => handleUpdateOrder(-999999)}
              icon={<ChevronDoubleUpIcon />}
              buttonSize={SizeVariant.normal}
            ></Button>
            <Button
              type={"button"}
              disabled={!writePermission}
              buttonColor={ColorVariant.primary}
              onClick={() => handleUpdateOrder(-1)}
              icon={<ChevronUpIcon />}
              buttonSize={SizeVariant.normal}
            ></Button>
            <div> </div>
            <div> </div>
            <Button
              type={"button"}
              disabled={!writePermission}
              buttonColor={ColorVariant.primary}
              onClick={() => handleUpdateOrder(1)}
              icon={<ChevronDownIcon />}
              buttonSize={SizeVariant.normal}
            ></Button>
            <Button
              type={"button"}
              disabled={!writePermission}
              buttonColor={ColorVariant.primary}
              onClick={() => handleUpdateOrder(999999)}
              icon={<ChevronDoubleDownIcon />}
              buttonSize={SizeVariant.normal}
            ></Button>
          </div>
        )}
      </div>

      <ButtonRow>
        {/* cannot update, must first delete */}
        {!props.attachmentId && (
          <Button type={"submit"} disabled={!writePermission || !fileData} buttonColor={ColorVariant.primary}>
            {t("Save")}
          </Button>
        )}
        {props.attachmentId && (
          <Button type={"button"} disabled={!writePermission} buttonColor={ColorVariant.error} onClick={handleDelete}>
            {t("Delete")}
          </Button>
        )}
      </ButtonRow>
    </Form>
  );
}

function useGetAttachmentQueryByCategory(
  category: AttachmentCategory,
  attachmentId?: string,
  primaryEntityId?: string, // company/customer/etc.
  secondaryEntityId?: string, // address/identification/etc.
) {
  type AttachmentResponse = {
    data?: AttachmentRecord | undefined;
    isLoading: boolean;
    isFetching: boolean;
    isUninitialized: boolean;
    isSuccess: boolean;
    error?: FetchBaseQueryError | SerializedError;
  };

  let attachmentResponse: AttachmentResponse = {
    isLoading: false,
    isFetching: false,
    isUninitialized: true,
    isSuccess: false,
  };

  const companyAttachmentQueryResult = api.useGetCompanyAttachmentQuery(
    {
      companyId: primaryEntityId || "",
      attachmentId: attachmentId || "",
    },
    { skip: !primaryEntityId || !attachmentId },
  );

  const productAttachmentQueryResult = api.useGetCompanyProductAttachmentQuery(
    {
      companyId: primaryEntityId || "",
      productId: secondaryEntityId || "",
      attachmentId: attachmentId || "",
    },
    { skip: !primaryEntityId || !secondaryEntityId || !attachmentId },
  );

  switch (category) {
    case AttachmentCategory.Company: {
      attachmentResponse = { ...companyAttachmentQueryResult };
      break;
    }
    case AttachmentCategory.ShopProductImage: {
      attachmentResponse = { ...productAttachmentQueryResult };
      break;
    }

    default:
      attachmentResponse.error = {
        message: (category as string) + " not implemented",
      };
  }

  return attachmentResponse;
}

function createFormData(data: AttachmentRecord): FormData {
  const form = new FormData();
  form.append("file", data.file || new Blob(), data?.fileName || "");
  form.append("type", data.type || "");
  form.append("fileType", data.file?.type || "");
  form.append("visibility", data.visibility || "");

  return form;
}

function useCreateAttachmentMutationByCategory(
  category: AttachmentCategory,
  primaryEntityId?: string, // company/customer/etc.
  secondaryEntityId?: string, // address/identification/etc.
) {
  const [createCompanyAttachment, createCompanyAttachmentResp] = api.useCreateCompanyAttachmentMutation();
  const [createProductAttachment, createProductAttachmentResp] = api.useCreateCompanyProductAttachmentMutation();

  switch (category) {
    case AttachmentCategory.Company: {
      const triggerFunc = (data: AttachmentRecord) => {
        createCompanyAttachment({
          companyId: primaryEntityId || "",
          body: createFormData(data),
        });
      };

      return { createTrigger: triggerFunc, createResp: createCompanyAttachmentResp };
    }
    case AttachmentCategory.ShopProductImage: {
      const triggerFunc = (data: AttachmentRecord) => {
        createProductAttachment({
          companyId: primaryEntityId || "",
          productId: secondaryEntityId || "",
          body: createFormData(data),
        });
      };

      return { createTrigger: triggerFunc, createResp: createProductAttachmentResp };
    }
  }

  return { createTrigger: undefined, createResp: undefined };
}

function useDeleteAttachmentMutationByCategory(
  category: AttachmentCategory,
  attachmentId: string,
  primaryEntityId?: string, // company/customer/etc.
  secondaryEntityId?: string, // address/identification/etc.
) {
  const [deleteCompanyAttachment, deleteCompanyAttachmentResp] = api.useDeleteCompanyAttachmentMutation();
  const [deleteProductAttachment, deleteProductAttachmentResp] = api.useDeleteCompanyProductAttachmentMutation();

  switch (category) {
    case AttachmentCategory.Company: {
      const triggerFunc = () => {
        deleteCompanyAttachment({
          attachmentId: attachmentId,
          companyId: primaryEntityId || "",
        });
      };

      return { deleteTrigger: triggerFunc, deleteResp: deleteCompanyAttachmentResp };
    }
    case AttachmentCategory.ShopProductImage: {
      const triggerFunc = () => {
        deleteProductAttachment({
          attachmentId: attachmentId,
          companyId: primaryEntityId || "",
          productId: secondaryEntityId || "",
        });
      };

      return { deleteTrigger: triggerFunc, deleteResp: deleteProductAttachmentResp };
    }
  }

  return { deleteTrigger: undefined, deleteResp: undefined };
}

function useUpdateAttachmentOrderMutationByCategory(
  category: AttachmentCategory,
  attachmentId: string,
  primaryEntityId?: string, // company/customer/etc.
  secondaryEntityId?: string, // address/identification/etc.
) {
  const [updateProductAttachmentOrder, updateProductAttachmentOrderResp] =
    api.useUpdateCompanyProductAttachmentOrderMutation();

  switch (category) {
    case AttachmentCategory.ShopProductImage: {
      const triggerFunc = (orderChangeAmount: number) => {
        updateProductAttachmentOrder({
          attachmentId: attachmentId,
          companyId: primaryEntityId || "",
          productId: secondaryEntityId || "",
          attachmentOrderChange: {
            orderChangeAmount: orderChangeAmount,
          },
        });
      };

      return { updateOrderTrigger: triggerFunc, updateOrderResp: updateProductAttachmentOrderResp };
    }
  }

  return { updateOrderTrigger: undefined, updateOrderResp: undefined };
}

export default AttachmentForm;

import MerchantServicesLogo from "icons/mion-logo-ms.svg";
import { useEffect, useState } from "react";
import classNames from "classnames";
import styles from "pages/demo/demoHeader.module.scss";

export default function DemoHeader() {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled((document?.querySelector("#shop-layout-page")?.scrollTop || 0) > 0);
    };

    document?.querySelector("#shop-layout-page")?.addEventListener("scroll", handleScroll);
    return () => document?.querySelector("#shop-layout-page")?.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className={classNames(styles.shopHeader, { [styles.scrolled]: scrolled })}>
      <a rel={"noreferrer"} className={styles.shopBanner} href={"https://mion.group"}>
        <img src={MerchantServicesLogo} alt="Logo" />
      </a>
      <span className={styles.shopBanner}>Demo Shop</span>
    </div>
  );
}

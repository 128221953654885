import { LivechatCheckout } from "components/support/livechat";
import { Outlet } from "react-router-dom";

function CustomerLayout() {
  return (
    <span>
      <Outlet />
      <LivechatCheckout />
    </span>
  );
}

export default CustomerLayout;

import { ContactSupportText } from "components/support/contactSupportText";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as MerchantServicesLogo } from "icons/mion-logo-ms.svg";
import styles from "pages/refundClaim/refundClaimPage.module.scss";
import api, {
  CheckCustomerResponse,
  CheckRefundCustomerResponse,
  ClaimRefundStatusRecord,
  CustomerRecord,
  GetRefundSessionResponse,
  RefundSessionStatus,
} from "api/api";
import { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Note, { NoteType } from "features/note/Note";
import { useLivechatCustomer } from "components/support/livechatUserDataHooks";
import * as routes from "constants/routes";
import { QueryStatus } from "@reduxjs/toolkit/query";
import ExistingRefundCustomer from "pages/refundClaim/parts/ExistingRefundCustomer";
import RefundEmailLoginFields from "pages/refundClaim/parts/RefundEmailLoginFields";
import CreateAccountButton from "pages/refundClaim/parts/CreateAccountButton";
import RefundClaimAttempt from "pages/refundClaim/refundClaimAttempt/RefundClaimAttempt";
import { BTC } from "constants/backend";
import Button from "components/buttons/Button";

const refetchSessionInterval = 5000;

type RefundClaimContextType = {
  refundSessionId: string | undefined;
  refundSessionResp: GetRefundSessionResponse | undefined | null;
  refundSessionStatus: ClaimRefundStatusRecord | undefined;
  email: string | undefined;
  setEmail: (value?: string) => void;
  loggedInCustomer: CustomerRecord | undefined | null;
  customerResp: CheckCustomerResponse | undefined | null;
  setCustomerResp: (customer: CheckCustomerResponse) => void | null;
  checkRefundCustomerResp: CheckRefundCustomerResponse | undefined | null;
  pageError?: Array<string>;
  setPageError: (value?: Array<string>) => void;
};

export const RefundClaimContext = createContext<RefundClaimContextType>({
  refundSessionId: undefined,
  refundSessionResp: null,
  refundSessionStatus: undefined,
  email: undefined,
  setEmail: () => {},
  loggedInCustomer: undefined,
  customerResp: null,
  setCustomerResp: () => null,
  checkRefundCustomerResp: null,
  pageError: [],
  setPageError: () => {},
});

// can be accepted
function isActiveStatus(status?: RefundSessionStatus) {
  return status === RefundSessionStatus.Initiated || status === RefundSessionStatus.Active;
}

function isAcceptedStatus(status?: RefundSessionStatus) {
  return status === RefundSessionStatus.Accepted || status === RefundSessionStatus.Exchanged;
}

function isRefundedStatus(status?: RefundSessionStatus) {
  return status === RefundSessionStatus.Refunded;
}

function isFailedStatus(status?: RefundSessionStatus) {
  return status === RefundSessionStatus.Expired || status === RefundSessionStatus.Cancelled;
}

/*
function isFinalStatus(status?: RefundSessionStatus) {
  return isRefundedStatus(status) || isFailedStatus(status);
}
*/

function RefundClaimPage() {
  const { t } = useTranslation("claimrefund");

  const { refundSessionId } = useParams();

  const [pageError, setPageError] = useState<Array<string> | undefined>([]);
  const [email, setEmail] = useState<string | undefined>();
  const [loggedInCustomer, setLoggedInCustomer] = useState<CustomerRecord | undefined | null>();
  const [customerResp, setCustomerResp] = useState<CheckCustomerResponse | undefined | null>();

  const refundSessionResp = api.useGetClaimRefundRefundSessionQuery(refundSessionId || "", { skip: !refundSessionId });

  const loggedInCustomerResp = api.useGetCustomerQuery(undefined, {
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
  });

  useLivechatCustomer(email, loggedInCustomerResp.data, undefined, refundSessionId);

  // poll every 5sec
  const { data: refundStatus, error: refundStatusFetchError } = api.useGetClaimRefundStatusQuery(
    refundSessionId || "",
    {
      skip: !refundSessionId,
      refetchOnMountOrArgChange: true,
      pollingInterval: refetchSessionInterval,
    },
  );

  const checkRefundCustomerResp = api.useGetCheckClaimRefundCustomerQuery(
    refundSessionResp?.data?.refundSessionId || "",
    {
      skip: !refundSessionResp?.data?.refundSessionId,
      refetchOnMountOrArgChange: true,
    },
  );

  // check if customer is logged in
  useEffect(() => {
    if (
      loggedInCustomerResp?.isSuccess === true &&
      !loggedInCustomerResp?.isFetching &&
      loggedInCustomerResp.status === QueryStatus.fulfilled
    ) {
      setLoggedInCustomer(loggedInCustomerResp?.data);
      setEmail(loggedInCustomerResp?.data?.email);

      if (loggedInCustomerResp?.data?.isGuest) {
        console.warn("Logged in as guest. Must create an account to claim refund.");
      }
    } else if (
      loggedInCustomerResp.isError ||
      loggedInCustomerResp.isUninitialized ||
      loggedInCustomerResp.status === QueryStatus.rejected
    ) {
      setLoggedInCustomer(undefined);
    }
  }, [loggedInCustomerResp]);

  // try refetching session if it does not exist
  useEffect(() => {
    const refetchTimer = setInterval(() => {
      if (!refundSessionResp?.data) {
        refundSessionResp?.refetch();
      }
    }, refetchSessionInterval);

    // Clear interval on unmount
    return () => clearInterval(refetchTimer);
  }, []);

  // handle session fetch error
  useEffect(() => {
    if (refundSessionResp?.error) {
      // TODO: Properly format errors
      console.error("Error fetching refund session", refundSessionResp?.error);
      setPageError([t("Error fetching refund session.")]);
      return;
    }

    if (refundStatusFetchError) {
      // TODO: Properly format errors
      console.error("Error fetching refund status", refundStatusFetchError);
      setPageError([t("Error fetching refund status.")]);
      return;
    }

    setPageError([]);
  }, [refundSessionResp?.error, refundStatusFetchError]);

  return (
    <RefundClaimContext.Provider
      value={{
        refundSessionId,
        refundSessionResp,
        refundSessionStatus: refundStatus,
        email,
        setEmail,
        loggedInCustomer,
        customerResp,
        setCustomerResp,
        checkRefundCustomerResp,
        pageError,
        setPageError,
      }}
    >
      <RefundClaimContent />
    </RefundClaimContext.Provider>
  );
}

export default RefundClaimPage;
function RefundClaimContent() {
  const { t } = useTranslation("claimrefund");
  const { t: tc } = useTranslation("currency");
  const navigate = useNavigate();

  const {
    refundSessionId,
    customerResp,
    loggedInCustomer,
    refundSessionResp,
    checkRefundCustomerResp,
    refundSessionStatus,
    pageError,
  } = useContext(RefundClaimContext);

  function navigateToWithdraw() {
    navigate(routes.CONSUMER_ACCOUNT_BITCOIN_WITHDRAW, {
      state: { background: routes.CONSUMER_ACCOUNT, navBackToBackground: true },
    });
  }

  return (
    <div className={styles.refundClaimPageWrapper}>
      <div className={styles.background} />
      <div className={styles.leftWrapper}>
        <div className={styles.leftContainer}>
          <div className={styles.logoWrapper}>
            <MerchantServicesLogo />
          </div>
          <div className={styles.header}>{t("Claim Refund")}</div>
          <div className={styles.amountWrapper}>
            <div className={styles.amountLabel}>
              {refundSessionResp?.data?.amount &&
                (isActiveStatus(refundSessionStatus?.status) ? t("Refund amount available") : t("Amount"))}
            </div>
            {refundSessionResp?.data?.currencyIso3 === BTC && refundSessionResp?.data?.amount && (
              <div className={styles.btcAmount}>
                {tc("SatSpaceFormatterWithSymbol", {
                  value: refundSessionResp?.data?.amount * 100_000_000,
                  currency: refundSessionResp?.data?.currencyIso3,
                })}
              </div>
            )}
            {refundSessionResp?.data?.currencyIso3 !== BTC && (
              <div className={styles.fiatAmount}>
                {tc("CurrencyFormatter", {
                  value: refundSessionResp?.data?.amount,
                  currency: refundSessionResp?.data?.currencyIso3,
                })}
              </div>
            )}
          </div>
          {!!pageError?.length && (
            <Note className={styles.paddedNote} noteType={NoteType.error} title={t("RefundError")}>
              {pageError}
            </Note>
          )}
          {isFailedStatus(refundSessionStatus?.status) && (
            <Note className={styles.paddedNote} noteType={NoteType.error}>
              {refundSessionStatus?.status === RefundSessionStatus.Expired && t("claimRefundExpiredInfoText")}
              {refundSessionStatus?.status === RefundSessionStatus.Cancelled && t("claimRefundCancelledInfoText")}
            </Note>
          )}

          {/* not logged in */}
          {!loggedInCustomer && !isFailedStatus(refundSessionStatus?.status) && (
            <div className={styles.customerInfoWrapper}>
              <div>{t("youMustUseMionAccountInfoText")}</div>
              {refundSessionResp?.data?.customerLocked && (
                <Note className={styles.paddedNote} noteType={NoteType.warning}>
                  {t("mustUseSameEmailInfoText")}
                </Note>
              )}
            </div>
          )}

          {/* logged in with wrong account */}
          {loggedInCustomer && !checkRefundCustomerResp?.isSuccess && !checkRefundCustomerResp?.isLoading && (
            <div className={styles.customerInfoWrapper}>
              <Note className={styles.paddedNote} noteType={NoteType.error}>
                {t("wrongLoggedInEmailInfoText")}
              </Note>
            </div>
          )}

          {/* Initial email input. PW/pin input also here if customer has an account */}
          {!loggedInCustomer && !isFailedStatus(refundSessionStatus?.status) && <RefundEmailLoginFields />}

          {/* Logged in, edit off */}
          {loggedInCustomer && (
            <ExistingRefundCustomer navigateToOnEdit={`${routes.CONSUMER_ACCOUNT_EDIT}?rs=${refundSessionId}`} />
          )}

          {/* Customer has given email and customer doesn't have an account */}
          {!loggedInCustomer && customerResp?.isSuccess && !customerResp.data.password && !customerResp.data.pin && (
            <CreateAccountButton />
          )}
          {/* if is guest, must create account */}
          {loggedInCustomer?.isGuest && <CreateAccountButton />}

          {loggedInCustomer && checkRefundCustomerResp?.isSuccess && (
            <>
              <div className={styles.header}>{t("Refund")}</div>
              <div className={styles.refundSectionContent}>
                {/* allow to settle */}
                {isActiveStatus(refundSessionStatus?.status) && <RefundClaimAttempt />}

                {isAcceptedStatus(refundSessionStatus?.status) && (
                  <Note className={styles.paddedNote} noteType={NoteType.info}>
                    {t("claimRefundPendingInfoText")}
                  </Note>
                )}

                {isRefundedStatus(refundSessionStatus?.status) && (
                  <div>
                    <Note className={styles.paddedNote} noteType={NoteType.success}>
                      {t("refundReadyForWithdrawInfoText")}
                    </Note>
                    <Button onClick={navigateToWithdraw}>{t("Proceed to withdraw")}</Button>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        <ContactSupportText />
      </div>
      <div className={styles.rightWrapper} />
    </div>
  );
}

import { useTranslation } from "react-i18next";
import { useContext } from "react";
import ExistingCustomer from "features/existingCustomer/ExistingCustomer";
import { RefundClaimContext } from "pages/refundClaim/RefundClaimPage";

export default function ExistingRefundCustomer(props: { navigateToOnEdit?: string }) {
  const { t } = useTranslation("claimrefund");

  const { setEmail, loggedInCustomer } = useContext(RefundClaimContext);

  return (
    <ExistingCustomer
      header={t("Recipient information")}
      loggedInCustomer={loggedInCustomer}
      setEmail={setEmail}
      navigateToOnEdit={props.navigateToOnEdit}
    />
  );
}

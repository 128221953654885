import styles from "pages/shop/shoppingCart.module.scss";
import { useTranslation } from "react-i18next";
import api, { CompleteShoppingCartResp } from "api/api";
import { CurrencyIso3, CurrencyUnits } from "constants/backend";
import Button, { ButtonRow, SizeVariant } from "components/buttons/Button";
import { useNavigate } from "react-router-dom";
import * as routes from "constants/routes";
import { isRequiredShippingAddressSet, ShopViewParams } from "pages/shop/ShopPage";
import { ServerErrorResultType } from "components/errors/errors";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import {
  Subtract20Filled as MinusIcon,
  Add20Filled as PlusIcon,
  Delete20Filled as DeleteIcon,
} from "@fluentui/react-icons";
import Spinny from "features/spinny/Spinny";
import { useEffect, useState } from "react";
import HelpTooltip from "components/forms/helpTooltip/helpTooltip";
import { TextArea } from "components/forms/forms";

function ShoppingCart(
  props: ShopViewParams & { editingShippingAddress: boolean; setEditingShippingAddress: (editing: boolean) => void },
) {
  const { t } = useTranslation("shop");
  const navigate = useNavigate();

  const [customerNotesInput, setCustomerNotesInput] = useState<string>("");

  const [completeCart, completeCartResponse] = api.useCompleteShoppingCartMutation();
  const [updateCart, updateCartResponse] = api.useUpdateShoppingCartMutation();

  useEffect(() => {
    // skip edit by default if required address is set
    if (isRequiredShippingAddressSet(props.shoppingCart)) {
      props.setEditingShippingAddress(false);
    }
  }, [props.shoppingCart?.shippingAddress]);

  useEffect(() => {
    if (!customerNotesInput) {
      setCustomerNotesInput(props.shoppingCart?.customerNotes || "");
    }
  }, [props.shoppingCart?.customerNotes]);

  async function handleProceedToCheckout() {
    if (!props.shoppingCart?.shoppingCartId || !props.shop?.shopHandle) {
      return;
    }

    // first update the customer notes if set
    if (customerNotesInput) {
      await updateCart({
        companyShop: props.shop.shopHandle,
        shoppingCartId: props.shoppingCart?.shoppingCartId,
        shoppingCartUpdateRecord: {
          customerNotes: customerNotesInput,
        },
      })
        .unwrap()
        .catch((err: ServerErrorResultType | FetchBaseQueryError) => {
          props.handleApiError(err);
        });
    }

    completeCart({
      companyShop: props.shop.shopHandle,
      shoppingCartId: props.shoppingCart?.shoppingCartId,
    })
      .unwrap()
      .catch((err: ServerErrorResultType | FetchBaseQueryError) => {
        props.handleApiError(err);
      })
      .then((res: CompleteShoppingCartResp | void) => {
        if (!res?.paymentSessionId) {
          console.error("No payment session id returned after creating a payment session");
          return;
        }

        // clear from session and navigate to checkout
        sessionStorage.removeItem(props.shop?.shopHandle + "-cartId");
        navigate(routes.CHECKOUT + "/" + res.paymentSessionId);
      });
  }

  function calculateSubTotal(): number | undefined {
    if (!props.shoppingCart) {
      return undefined;
    }

    return props.shoppingCart?.products?.reduce(
      (acc, product) => acc + (product.priceFloat || 0) * (product.quantity || 0) * (1 + (product.vatMultiplier || 0)),
      0,
    );
  }

  function calculateSubTotalVAT(): number | undefined {
    if (!props.shoppingCart) {
      return undefined;
    }

    return props.shoppingCart?.products?.reduce(
      (acc, product) => acc + (product.priceFloat || 0) * (product.quantity || 0) * (product.vatMultiplier || 0),
      0,
    );
  }

  return (
    <div className={styles.shoppingCartContainer}>
      <h2>{t("shoppingCart")}</h2>
      {!props.shoppingCart?.products?.length && (
        <div className={styles.shoppingCartEmpty}>
          <b>{t("shoppingCartEmpty")}</b>
        </div>
      )}
      <div className={styles.amountsContainer}>
        <div className={styles.productsRow}>
          {props.shoppingCart?.products
            ?.toSorted((a, b) => (a.displayName || "").localeCompare(b.displayName || ""))
            .map((product) => (
              <div key={product.productId}>
                <div className={styles.productRow}>
                  {/* todo image */}
                  <div className={styles.productInfo}>
                    <div>
                      {product.quantity}x <b>{product.displayName}</b>
                    </div>
                    <div className={styles.productUpdateButtons}>
                      <Button
                        buttonSize={SizeVariant.tiny}
                        icon={<DeleteIcon />}
                        disabled={!product.quantity}
                        onClick={() =>
                          props.updateProductInCart?.(product, 0, props.shoppingCart?.shoppingCartId || "")
                        }
                      ></Button>
                      <Button
                        buttonSize={SizeVariant.tiny}
                        icon={<MinusIcon />}
                        disabled={!product.quantity}
                        onClick={() =>
                          props.updateProductInCart?.(
                            product,
                            product.quantity ? product.quantity - 1 : 0,
                            props.shoppingCart?.shoppingCartId || "",
                          )
                        }
                      ></Button>
                      <Button
                        buttonSize={SizeVariant.tiny}
                        icon={<PlusIcon />}
                        onClick={() =>
                          props.updateProductInCart?.(
                            product,
                            product.quantity ? product.quantity + 1 : 1,
                            props.shoppingCart?.shoppingCartId || "",
                          )
                        }
                      ></Button>
                    </div>
                  </div>
                  <div>
                    {((product.priceFloat || 0) * (product.quantity || 0) * (1 + (product.vatMultiplier || 0))).toFixed(
                      2,
                    )}
                    {CurrencyUnits[product.currencyIso3 as CurrencyIso3]}
                  </div>
                </div>
                {(product.vatMultiplier || 0) > 0 && (
                  <div className={styles.VATRow}>
                    <div>
                      {t("IncludingVAT")} {((product.vatMultiplier || 0) * 100)?.toFixed(1)} %
                    </div>
                    <div>
                      {((product.priceFloat || 0) * (product.quantity || 0) * (product.vatMultiplier || 0)).toFixed(2)}
                      {CurrencyUnits[product.currencyIso3 as CurrencyIso3]}
                    </div>
                  </div>
                )}
              </div>
            ))}
        </div>
        <div className={styles.totalRow}>
          <div>
            <b>{t("subTotal")}</b>
          </div>
          <div>
            {calculateSubTotal()?.toFixed(2)}
            {CurrencyUnits[props.shoppingCart?.currencyIso3 as CurrencyIso3]}
          </div>
        </div>
        <div className={styles.VATRow}>
          <div>{t("IncludingVAT")}</div>
          <div>
            {calculateSubTotalVAT()?.toFixed(2)}
            {CurrencyUnits[props.shoppingCart?.currencyIso3 as CurrencyIso3]}
          </div>
        </div>
        <div className={styles.shippingCostRow}>
          <div>
            <b>{t("shippingCost")}</b>
          </div>
          <div>
            {(
              (props.shoppingCart?.shippingCostFloat || 0) *
              (1 + (props.shoppingCart?.shippingCostVatMultiplier || 0))
            ).toFixed(2)}
            {CurrencyUnits[props.shoppingCart?.currencyIso3 as CurrencyIso3]}
          </div>
        </div>
        {(props.shoppingCart?.shippingCostVatMultiplier || 0) > 0 && (
          <div className={styles.VATRow}>
            <div>
              {t("IncludingVAT")} {((props.shoppingCart?.shippingCostVatMultiplier || 0) * 100)?.toFixed(1)} %
            </div>
            <div>
              {(
                (props.shoppingCart?.shippingCostFloat || 0) * (props.shoppingCart?.shippingCostVatMultiplier || 0)
              ).toFixed(2)}
              {CurrencyUnits[props.shoppingCart?.currencyIso3 as CurrencyIso3]}
            </div>
          </div>
        )}
        <div className={styles.totalRow}>
          <div>
            <b>{t("total")}</b>
          </div>
          <div>
            <b>
              {props.shoppingCart?.totalAmountFloat?.toFixed(2)}
              {CurrencyUnits[props.shoppingCart?.currencyIso3 as CurrencyIso3]}
            </b>
          </div>
        </div>
        <div className={styles.VATRow}>
          <div className={styles.VATInfo}>
            <div>{t("IncludingVAT")}</div>
            {props.shop?.shopVatType === "eu_dynamic" && (
              <HelpTooltip label={t("VATTooltipHeader")} text={t("VATTooltipText")} />
            )}
          </div>
          <div>
            {(props.shoppingCart?.totalVatFloat || 0).toFixed(2)}
            {CurrencyUnits[props.shoppingCart?.currencyIso3 as CurrencyIso3]}
          </div>
        </div>
      </div>
      <div className={styles.customerNotes}>
        <TextArea
          label={t("AdditionalInformation")}
          helpText={t("AdditionalInformationHelpText")}
          value={customerNotesInput || ""}
          onChange={(e) => setCustomerNotesInput(e.target.value || "")}
        />
      </div>
      {(props.editingShippingAddress || !isRequiredShippingAddressSet(props.shoppingCart)) && (
        <div className={styles.mustSaveShippingAddressText}>{t("saveShippingAddressBeforeCheckout")}</div>
      )}
      <ButtonRow>
        <Button onClick={() => props.setEditingShippingAddress(true)} disabled={props.editingShippingAddress}>
          {t("editShippingAddress")}
        </Button>
        <Button
          onClick={handleProceedToCheckout}
          disabled={
            completeCartResponse.isLoading ||
            updateCartResponse.isLoading ||
            !props.shoppingCart?.shoppingCartId ||
            !isRequiredShippingAddressSet(props.shoppingCart) || // must have shipping address to proceed
            !props.shop?.shopHandle ||
            props.editingShippingAddress // cannot proceed if editing shipping address
          }
          icon={completeCartResponse.isLoading || updateCartResponse.isLoading ? <Spinny /> : undefined}
        >
          {t("proceedToCheckout")}
        </Button>
      </ButtonRow>
    </div>
  );
}

export default ShoppingCart;

import { useEffect } from "react";

const currentRefundSessionIdKey = "currentRefundSessionId";

export function getCurrentRefundSessionId(): string | null {
  return sessionStorage.getItem(currentRefundSessionIdKey);
}

const useCurrentRefundSession = (refundSessionId: string | undefined) => {
  useEffect(() => {
    if (refundSessionId) {
      sessionStorage.setItem(currentRefundSessionIdKey, refundSessionId);
    } else {
      sessionStorage.removeItem(currentRefundSessionIdKey);
    }

    return () => {
      sessionStorage.removeItem(currentRefundSessionIdKey);
    };
  }, [refundSessionId]);
};

export default useCurrentRefundSession;

import { createContext, FC, ReactNode, useState } from "react";
import { Helmet } from "react-helmet";

export type DynamicHtmlHeadContextType = {
  setTitle: (value: string | undefined) => void;
  setFaviconUrl: (value: string | undefined) => void;
};

// reset data by calling set with undefined
export const DynamicHtmlHeadContext = createContext<DynamicHtmlHeadContextType>({
  setTitle: () => {},
  setFaviconUrl: () => {},
});

export const DynamicHtmlHeadProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [title, setTitle] = useState<string | undefined>();
  const [faviconUrl, setFaviconUrl] = useState<string | undefined>();

  return (
    <DynamicHtmlHeadContext.Provider value={{ setTitle, setFaviconUrl }}>
      <Helmet>
        {faviconUrl && <link rel="icon" sizes="32x32" href={faviconUrl} type="image/x-icon" />}
        {!faviconUrl && (
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" data-react-helmet="true" />
        )}
        {!faviconUrl && (
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" data-react-helmet="true" />
        )}
        {title && <title>{title}</title>}
        {!title && <title data-react-helmet="true">Mion</title>}
        {/* add other dynamic head metadata */}
      </Helmet>
      {children}
    </DynamicHtmlHeadContext.Provider>
  );
};

import React from "react";
import api from "api/api";
import { ComplianceHitListRecordInternal, GetAllComplianceHitsResp } from "api/api.generated";
import Table from "features/table/Table";
import DefaultCellRenderer from "features/table/DefaultCellRenderer";
import { useTranslation } from "react-i18next";
import { ColumnMetaData } from "features/table/types";
import TablePageTemplate, { TableControlSection } from "features/templates/tablePageTemplate/TablePageTemplate";
import { useEffect } from "react";
import { usePagination } from "components/table/pagination/usePagination";
import { getErrorResponse } from "components/errors/validationErrors";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import ToastContext from "features/toast/context";
import { toastCategory } from "features/toast/Toasts";
import { useHasPermission } from "features/authorization/ComponentPermissionChecker";
import {
  EditRegular as EditIcon,
  DeleteRegular as ExemptIcon,
  ArrowSync20Regular as RefreshIcon,
} from "@fluentui/react-icons";
import { ColorVariant } from "components/buttons/buttonVariants";
import { useTableColumns } from "features/table/tableColumnHooks";
import Button from "components/buttons/Button";

const columns: ColumnMetaData<ComplianceHitListRecordInternal>[] = [
  {
    heading: "",
    type: "LinkCell",
    key: "complianceHitId",
    valueType: "multilink",
    multilinkData: [
      {
        basePath: "/admin/compliance-hits/status/",
        text: "Resolve",
        icon: <EditIcon />,
        internal: true,
        disabled: false,
        localizePackage: "compliance",
      },
      {
        basePath: "/admin/compliance-hits/exempt/",
        text: "Exempt",
        icon: <ExemptIcon />,
        color: ColorVariant.error,
        internal: true,
        disabled: false,
        localizePackage: "compliance",
      },
    ],
  },
  {
    heading: "HitStatus",
    type: "TextCell",
    key: "status",
    valueType: "string",
    headingLocalizePackage: "compliance",
    sortable: true,
  },
  {
    heading: "CustomerFirstName",
    type: "TextCell",
    key: "customerFirstName",
    valueType: "string",
    headingLocalizePackage: "customer",
    sortable: true,
  },
  {
    heading: "CustomerLastName",
    type: "TextCell",
    key: "customerLastName",
    valueType: "string",
    headingLocalizePackage: "customer",
    sortable: true,
  },
  {
    heading: "CustomerEmail",
    type: "TextCell",
    key: "customerEmail",
    valueType: "string",
    headingLocalizePackage: "customer",
    sortable: true,
  },
  {
    heading: "CompanyName",
    type: "TextCell",
    key: "companyName",
    valueType: "string",
    headingLocalizePackage: "company",
    sortable: true,
  },
  {
    heading: "DataType",
    type: "TextCell",
    key: "dataType",
    valueType: "string",
    headingLocalizePackage: "compliance",
    sortable: true,
  },
  {
    heading: "RuleName",
    type: "TextCell",
    key: "ruleName",
    valueType: "string",
    headingLocalizePackage: "compliance",
    sortable: true,
  },
  {
    heading: "RuleCode",
    type: "TextCell",
    key: "ruleCode",
    valueType: "string",
    headingLocalizePackage: "compliance",
    sortable: true,
  },
  {
    heading: "ResourceName",
    type: "TextCell",
    key: "resourceName",
    valueType: "string",
    headingLocalizePackage: "compliance",
    sortable: true,
  },
  {
    heading: "PostOperation",
    type: "TextCell",
    key: "postOperation",
    valueType: "string",
    headingLocalizePackage: "compliance",
    sortable: true,
  },
  {
    heading: "StatusUpdateDescription",
    type: "LongTextCellWithModalTooltip",
    key: "statusUpdateDescription",
    valueType: "string",
    headingLocalizePackage: "compliance",
  },
  {
    heading: "HitNumber",
    type: "TextCell",
    key: "number",
    valueType: "string",
    headingLocalizePackage: "compliance",
  },
  {
    heading: "ClientFlags",
    type: "TextCell",
    key: "clientFlags",
    valueType: "string",
    headingLocalizePackage: "compliance",
  },
  {
    heading: "CreatedAt",
    type: "DateCell",
    key: "createdAt",
    valueType: "datetime",
    headingLocalizePackage: "common",
    sortable: true,
  },
  {
    heading: "UpdatedAt",
    type: "DateCell",
    key: "updatedAt",
    valueType: "datetime",
    headingLocalizePackage: "common",
    sortable: true,
  },
  {
    heading: "paymentSessionId",
    type: "TextCell",
    key: "paymentSessionId",
    valueType: "string",
    headingLocalizePackage: "payment",
  },
  {
    heading: "refundSessionId",
    type: "TextCell",
    key: "refundSessionId",
    valueType: "string",
    headingLocalizePackage: "refunds",
  },
  /*
  // Cannot have dupliacte keys
  {
    heading: "ComplianceHitId",
    type: "TextCell",
    key: "complianceHitId",
    valueType: "string",
    headingLocalizePackage: "compliance",
  },
  */
  {
    heading: "CustomerId",
    type: "TextCell",
    key: "customerId",
    valueType: "string",
    headingLocalizePackage: "customer",
  },
  {
    heading: "RuleDefinitionId",
    type: "TextCell",
    key: "ruleDefinitionId",
    valueType: "string",
    headingLocalizePackage: "compliance",
  },
];

function ComplianceHitsPage() {
  const { t: tc } = useTranslation("compliance");
  const toastRef = React.useContext(ToastContext);
  const hasEdenAdminPermission = useHasPermission(["edenAdmin"]);

  const [cols, sortBy, sortDirection] = useTableColumns<ComplianceHitListRecordInternal>(columns);

  const [getPaginator, limit, offset] = usePagination("complianceHitsPagination", 50);

  const complianceHitsResponse = api.useGetAllComplianceHitsQuery<{
    data: GetAllComplianceHitsResp;
    error: FetchBaseQueryError | SerializedError | undefined;
    isLoading: boolean;
    isFetching: boolean;
    isUninitialized: boolean;
    isSuccess: boolean;
  }>(
    {
      limit: limit,
      offset: offset,
      sortby: sortBy,
      sortdirection: sortDirection,
    },
    // 1 min polling
    { skip: !hasEdenAdminPermission, pollingInterval: 60000, refetchOnMountOrArgChange: true },
  );

  const data = complianceHitsResponse?.data?.records || [];
  const totalCount = complianceHitsResponse?.data?.totalCount || 0;

  useEffect(() => {
    const err = getErrorResponse(complianceHitsResponse.error);
    if (err?.message) {
      toastRef?.current?.addToast(err.message, toastCategory.error);
    }
  }, [complianceHitsResponse.error]);

  const tableControls = (
    <TableControlSection intercomTarget={"table-page-controls"}>
      <Button
        intercomTarget="refresh-compliance-hits-table"
        buttonColor={ColorVariant.primary}
        icon={<RefreshIcon />}
        onClick={() => {
          complianceHitsResponse.refetch();
        }}
      />
    </TableControlSection>
  );
  return (
    <TablePageTemplate
      title={tc("ComplianceHits")}
      titleContent={""}
      tableControls={tableControls}
      pagination={getPaginator(totalCount || 0)}
    >
      <Table
        intercomTarget={"compliance-hits-table"}
        cellRenderer={DefaultCellRenderer}
        data={data}
        activeColumns={cols}
        isLoading={
          complianceHitsResponse.isLoading ||
          complianceHitsResponse.isFetching ||
          complianceHitsResponse.isUninitialized
        }
      />
    </TablePageTemplate>
  );
}

export default ComplianceHitsPage;

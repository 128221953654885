import api from "api/api";
import { AttachmentType, AttachmentVisibility } from "api/api.generated";
import AttachmentForm, { AttachmentCategory, AttachmentFormVisualType } from "features/attachments/AttachmentForm";
import { Image20Regular as ImageIcon } from "@fluentui/react-icons";
import PopoutPageTemplate from "features/templates/popoutPageTemplate/PopoutPageTemplate";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

export function CompanyLogoModal() {
  const { t } = useTranslation("company");
  const navigate = useNavigate();

  const { companyId } = useParams();

  const companyResp = api.useGetCompanyByIdQuery(companyId || "", { skip: !companyId });

  return (
    <PopoutPageTemplate
      title={t("Company") + " " + t("Logo")}
      show={true}
      onClose={() => navigate(-1)}
      icon={<ImageIcon />}
    >
      <div>
        <AttachmentForm
          attachmentFormVisualType={AttachmentFormVisualType.Picture}
          title={t("Company") + " " + t("Logo")}
          attachmentId={companyResp?.data?.publicLogoAttachmentId}
          category={AttachmentCategory.Company}
          type={AttachmentType.Logo}
          primaryEntityId={companyId}
          writePermissionNames={["companyWriteRead"]}
          defaultVisibility={AttachmentVisibility.Public}
          onSuccess={() => navigate(-1)}
          attachmentFileGetPath={companyResp?.data?.publicLogoAttachmentPath}
          maxFileSizeKB={2048} // 2 MB
        />
      </div>
    </PopoutPageTemplate>
  );
}

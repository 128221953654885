// Convert form data to json. Use T as the type of the json object.
//
import { FormEvent } from "react";

type FormDataValue = string | File | number | number[] | boolean | null | undefined;
type FormSubObject = object;

// FormToJson takes the form event and return json
export function FormToJson<T extends { [key: string]: FormDataValue | FormSubObject }>(
  event: FormEvent<HTMLFormElement>,
  keyPrefix?: string,
): T {
  const form = event.currentTarget;
  const formData = new FormData(form);

  // Initialize JSON data object
  const data: T = {} as T;

  // Handle checkboxes separately
  const checkboxes = form.querySelectorAll<HTMLInputElement>('input[type="checkbox"]');
  checkboxes.forEach((checkbox) => {
    let fieldName = checkbox.name;
    if (checkbox.name) {
      if (keyPrefix) {
        if (!checkbox.name.startsWith(keyPrefix)) {
          // wrong prefix, ignore
          return;
        }
        fieldName = checkbox.name.slice(keyPrefix.length + 1); // remove prefix + "."
      } else if (checkbox.name.includes(".") || typeof data[fieldName as keyof T] === "object") {
        // ignore subobject keys
        return;
      }
      // Set the value to true if checked, false otherwise
      data[fieldName as keyof T] = checkbox.checked as T[typeof checkbox.name];
    }
  });

  // Handle other form data
  formData.forEach((value, key) => {
    let fieldName = key;
    if (keyPrefix) {
      if (!key.startsWith(keyPrefix)) {
        // wrong prefix, ignore
        return;
      }
      fieldName = key.slice(keyPrefix.length + 1); // remove prefix + "."
    } else if (key.includes(".") || typeof data[fieldName as keyof T] === "object") {
      // ignore subobject keys
      return;
    }

    if (form.querySelector(`input[type="checkbox"][name="${key}"]`)) {
      // Ignore checkbox values since we have already handled them
      return;
    }

    if (typeof value !== "string") {
      data[fieldName as keyof T] = value as T[typeof key];
      return;
    }
    // For string values, check if the input type is not text or password
    const inputType = form.querySelector(`input[name="${key}"]`)?.getAttribute("type");
    if (inputType !== "text" && inputType !== "password" && inputType !== "email") {
      const isNumericValue = !isNaN(parseFloat(value)) && isFinite(value as unknown as number);
      if (isNumericValue) {
        data[fieldName as keyof T] = parseFloat(value) as T[typeof key];
        return;
      }
    }

    // Default case for text, hidden, password, and other string values
    data[fieldName as keyof T] = value as T[typeof key];
  });

  return data;
}

export function Json2FormData<T extends { [key: string]: FormDataValue }>(data: T): FormData {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    const value = data[key as keyof T];
    if (value === null || value === undefined) {
      return;
    }

    if (typeof value === "boolean") {
      formData.append(key, value ? "true" : "false");
      return;
    }

    if (typeof value === "number") {
      formData.append(key, value.toString());
      return;
    }

    if (typeof value === "string") {
      formData.append(key, value);
      return;
    }

    if (Array.isArray(value)) {
      value.forEach((v) => {
        if (typeof v === "string") {
          formData.append(key, v);
        }
      });
      return;
    }

    if (value instanceof File) {
      formData.append(key, value);
      return;
    }
  });

  return formData;
}

// // Handle other form data
//  formData.forEach((value, key) => {
//    // Directly assign non-string values (e.g., File)
//    if (typeof value !== "string") {
//      data[key as keyof T] = value as T[typeof key];
//      return;
//    }
//
//    // For string values, check if the input type is not text or password
//    const inputType = form.querySelector(`input[name="${key}"]`)?.getAttribute("type");
//    if (inputType !== "text" && inputType !== "password") {
//      const isNumericValue = !isNaN(parseFloat(value)) && isFinite(value as unknown as number);
//      if (isNumericValue) {
//        data[key as keyof T] = parseFloat(value) as T[typeof key];
//        return;
//      }
//    }
//
//    // Default case for text, password, and other string values
//    data[key as keyof T] = value as T[typeof key];
//  });

// Get the value of the input field in the object
// The input name must be in the format of "objName.innerObjName.fieldName"
// Arbitrary number of inner object names are allowed
export function Json2InputValue(obj: object, inputName: string): string {
  const inputNameSplit = inputName?.split(".");

  // last part of input name is the actual field name
  const fieldName = inputNameSplit[inputNameSplit.length - 1];
  const innerObjNames = inputNameSplit.slice(0, inputNameSplit.length - 1);

  return getInputValueInObject(innerObjNames, obj, fieldName);
}

// recursive function to get the field value
function getInputValueInObject(innerObjNames: string[], obj: object, fieldName: string): string {
  // is the deepest level of the object
  if (innerObjNames.length === 0) {
    if (obj != null && obj != undefined && Object.hasOwn(obj, fieldName)) {
      // get the field from the inner object by the input name
      const val = obj[fieldName as keyof typeof obj];
      if (val == null || val == undefined) {
        return "";
      }
      return String(val);
    }

    // field not found in object, maybe the object is nullable
    // or the input name is wrong
    return "";
  }

  // direct child object of the current object
  const childObj = obj[innerObjNames[0] as keyof typeof obj];
  // the rest of the objects
  innerObjNames = innerObjNames.slice(1, innerObjNames.length);
  return getInputValueInObject(innerObjNames, childObj, fieldName);
}

type EnumType = { [key: string]: string | number };

export function EnumAsOptionsLabelKey(enumObj: EnumType): Array<{ label: string; value: string }> {
  return Object.entries(enumObj).map((entry) => {
    const [key, value] = entry;
    return { label: key as string, value: value as string };
  });
}

// Map enum into options where label is value
export function EnumAsOptionsLabelValue(enumObj: EnumType): Array<{ label: string; value: string }> {
  return Object.entries(enumObj).map((entry) => {
    const [, value] = entry;
    return { label: value as string, value: value as string };
  });
}

export function DateTimeLocalAsTimeString(dateTimeLocal: string | undefined): string | undefined {
  if (!dateTimeLocal) {
    return undefined;
  }

  const d = new Date(dateTimeLocal);
  if (!d.getTime()) {
    return undefined;
  }

  return d.toISOString();
}

import PopoutPageTemplate from "features/templates/popoutPageTemplate/PopoutPageTemplate";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Building24Regular as CompanyIcon, Save20Regular as SaveIcon } from "@fluentui/react-icons";
import Button, { ButtonPosition, ColorVariant } from "components/buttons/Button";
import { Form, Input, ResultField, Select } from "components/forms/forms";
import api, { Company, CountryOption, getCountriesAsOptions, InputError } from "api/api";
import { FormToJson } from "api/apiUtils";
import { useTranslation } from "react-i18next";
import { FormEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getErrorResponse, Tie } from "components/errors/validationErrors";
import Spinny from "features/spinny/Spinny";
import Note, { NoteType } from "features/note/Note";
import { useAppDispatch } from "store/hooks";
import { setSelectedCompanyId } from "features/navigation/navSlice";
import * as routes from "constants/routes";

function CreateCompanyModal() {
  const { t: tc } = useTranslation("company");
  const { t } = useTranslation("common");
  const { t: tv } = useTranslation("validation");
  const navigate = useNavigate();
  const [ie, setInputErrors] = useState<InputError[]>();
  const [errMessage, setErrMessage] = useState<string>("");
  const dispatch = useAppDispatch();

  // get the parent company id from the query params
  const [searchParams] = useSearchParams();
  const parent = searchParams.get("parent");

  const [country, setCountry] = useState<CountryOption>();

  // create company mutation with success and error handlers
  const [
    createCompany,
    {
      data: createdCompany,
      error: createCompanyErrors,
      isLoading: loadingCreateCompany,
      isSuccess: isSuccessCreateCompany,
    },
  ] = api.useCreateCompanyMutation();

  // get the parent company if set
  const { data: parentCompany } = api.useGetCompanyByIdQuery(parent || "", {
    skip: !parent,
  });

  // fetch the countries
  api.useGetCountriesQuery();
  const countryOptions = useSelector(getCountriesAsOptions);

  function submit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    // Get all the data from the form
    const formJson = FormToJson<Company>(event);

    if (formJson.countryIso3 === "") {
      formJson.countryIso3 = undefined;
    }

    // Send it to the API
    createCompany(formJson);
  }

  useEffect(() => {
    if (isSuccessCreateCompany && createdCompany?.companyId) {
      dispatch(setSelectedCompanyId(createdCompany.companyId));
      dispatch(api.util.invalidateTags(["companies", "userCompanies"]));
      navigate(routes.COMPANY, { replace: true });
    }
  }, [isSuccessCreateCompany, createdCompany]);

  useEffect(() => {
    const err = getErrorResponse(createCompanyErrors);
    setErrMessage(err?.message || "");
    if (err && err.inputErrors) {
      setInputErrors(err.inputErrors);
    } else {
      setInputErrors([]);
    }
  }, [createCompanyErrors]);

  return (
    <PopoutPageTemplate title={tc("CreateCompany")} show={true} onClose={() => navigate(-1)} icon={<CompanyIcon />}>
      <div>
        <Form onSubmit={submit} intercomTarget={"create-company-form"}>
          {parent && <Input hidden={true} name={"parentCompanyId"} value={parent} />}
          {parent && <ResultField label={tc("ParentCompany")}>{parentCompany?.companyName}</ResultField>}
          <Input name={"companyName"} label={tc("CompanyName") + " *"} errors={Tie("CompanyName", ie, ["company"])} />
          <Input name={"displayName"} label={tc("DisplayName")} errors={Tie("DisplayName", ie, ["company"])} />
          <Select
            intercomTarget={"select-countries"}
            label={tc("Country") + " *"}
            name={"countryIso3"}
            options={countryOptions}
            value={country}
            onChange={(newValue) => {
              setCountry(countryOptions.find((country) => country.value === newValue));
            }}
            errors={Tie("CountryISO3", ie, ["company"])}
          />
          {/*<Input name={"phone"} label={tc("Phone")} /> */}
          <Input name={"website"} label={tc("Website")} />
          <Input name={"billingEmail"} label={tc("BillingEmail")} />
          <Input name={"adminEmail"} label={tc("AdminEmail")} helpText={tc("AdminEmailHelpText")} />
          <Input name={"industryCategory"} label={tc("IndustryCategory")} />
          {/*<Input name={"logo"} label={t.Logo} />*/}
          {/*<Input name={"symbol"} label={t.Symbol} />*/}

          {createCompanyErrors && (
            <Note noteType={NoteType.error} title={t("Error")}>
              {tv(errMessage)}
            </Note>
          )}
          <Button
            intercomTarget="company-save-create-button"
            type={"submit"}
            buttonColor={ColorVariant.success}
            buttonPosition={ButtonPosition.fullWidth}
            disabled={loadingCreateCompany}
            icon={loadingCreateCompany ? <Spinny /> : <SaveIcon />}
          >
            {t("Save")}
          </Button>
        </Form>
      </div>
    </PopoutPageTemplate>
  );
}

export default CreateCompanyModal;

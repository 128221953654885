import styles from "pages/checkout/paymentMethods/OnChain/OnChainAttempt.module.scss";
import { QRCodeCanvas } from "qrcode.react";
import { useTranslation } from "react-i18next";
import api, { PaymentMethod } from "api/api";
import { useContext, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import Note from "features/note/Note";
import {
  ChevronDown12Filled as ChevronDownIcon,
  ChevronUp12Filled as ChevronUpIcon,
  Copy20Regular as CopyIcon,
} from "@fluentui/react-icons";
import { CopyButton, ExternalLinkButton, ButtonPosition } from "components/buttons/Button";
import i18n from "i18n";
import { CheckoutContext } from "pages/checkout/CheckoutPage";
import useWindowWidth, { mobileWidth } from "features/width/useWindowWidth";
import { format } from "date-fns";
import { BTC } from "constants/backend";

function mSatRoundUpToSat(amountMsat: number): number {
  return (amountMsat + 500) / 1000;
}

function formatMSatAmount(amountMsat: number): string {
  const btcAmount = amountToBtcStr(amountMsat);
  const roundedSats = Number(btcAmount) * 1e8;

  return i18n.t("SatSpaceFormatterWithSymbol", { value: roundedSats, ns: "currency" });
}

function amountToBtcStr(amountMsat: number): string {
  // round up millisatoshis to be sure to get paid enough!
  const btcAmount = mSatRoundUpToSat(amountMsat) / 1e8;

  return btcAmount.toFixed(8);
}

function createPaymentLink(address: string | undefined, amountMsat: number | undefined): string {
  if (amountMsat === undefined || !address) {
    return "";
  }

  return `bitcoin:${address}?amount=${amountToBtcStr(amountMsat)}`;
}

function getExpiryString(expiry: string): string {
  const expiryTime = new Date(expiry);

  return format(expiryTime as Date, "HH:mm:ss");
}

export default function OnChainAttempt() {
  const { t } = useTranslation("checkout");
  const [qrCollapsed, setQrCollapsed] = useState<boolean>(true);

  const { sessionResp, loggedInCustomer, setAttemptResp } = useContext(CheckoutContext);

  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= mobileWidth;

  useEffect(() => {
    if (!isMobile) {
      setQrCollapsed(false);
    }
  }, [isMobile]);

  const lastFetched = useRef<number>(0);

  const [requestAttempt, attemptResp] = api.useRequestCheckoutAttemptMutation();

  const attempt = attemptResp?.data;
  const isLoading = attemptResp?.isLoading;
  const isError = attemptResp?.isError;

  function getExchangeRateStr(): string | undefined {
    if (!attempt?.amountMsat || !sessionResp?.data?.amount) {
      return undefined;
    }

    if (sessionResp?.data?.currencyIso3 === BTC) {
      return undefined;
    }

    const amountBTC = mSatRoundUpToSat(attempt.amountMsat) / 1e8;
    const amountFiat = sessionResp?.data?.amount;

    const rate = amountFiat / amountBTC;

    return i18n.t("BtcEurPriceFormatter", { value: rate, ns: "currency" });
  }

  useEffect(() => {
    setAttemptResp(attemptResp);
  }, [attemptResp]);

  // Check expiry every second, and fetch a new one if expired
  useEffect(() => {
    const interval = setInterval(() => {
      if (!attempt?.expiry) {
        return;
      }

      const now = new Date().getTime();
      const expiryTime = new Date(attempt.expiry).getTime();

      if (expiryTime && expiryTime - now < 0) {
        fetchPaymentAttempt();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [attempt?.expiry]);

  useEffect(() => {
    // To go around the StrictMode running the query twice, do not allow to run the query twice within 1 second
    if (new Date().getTime() - lastFetched.current > 1000) {
      fetchPaymentAttempt();
    }
    return () => {
      lastFetched.current = new Date().getTime();
    };
  }, [sessionResp?.data?.paymentSessionId]);

  useEffect(() => {
    // Try refetching after 5 sec if there is error in the query
    if (isError) {
      setTimeout(() => {
        fetchPaymentAttempt();
      }, 5000);
    }
  }, [isError]);

  function fetchPaymentAttempt() {
    if (!isLoading && sessionResp?.data?.paymentSessionId) {
      requestAttempt({
        paymentSessionId: sessionResp?.data?.paymentSessionId || "",
        checkoutAttempt: {
          customerId: loggedInCustomer?.customerId,
          paymentMethod: PaymentMethod.OnChain,
        },
      });
    }
  }

  // error shown in PaymentMethodRenderer
  if (attempt?.blockedByCompliance || isError) {
    return <div></div>;
  }

  return (
    <div className={styles.paymentRequestContent}>
      <div className={styles.infoWrapper}>
        <div className={classNames(styles.amountAndRateWrapper, { [styles.loading]: isLoading })}>
          <div className={classNames(styles.satAmountWrapper)}>
            {attempt?.amountMsat && formatMSatAmount(attempt.amountMsat)}
          </div>
          <div className={classNames(styles.exchangeRateWrapper)}>{getExchangeRateStr()}</div>
        </div>
        <div>
          {attempt?.suggestedFeeRate && (
            <div className={styles.expiryWrapper}>
              <div>{t("SuggestedFeeRate")}:</div>
              <div className={styles.suggestedFeeRate}>{attempt.suggestedFeeRate} sat/vB</div>
            </div>
          )}
        </div>
        <div>
          <div className={classNames({ [styles.loading]: isLoading })} />{" "}
          {attempt?.expiry && (
            <div className={styles.expiryWrapper}>
              <div>{t("ExpiresAt")}:</div>
              <div className={styles.expiryTime}>{getExpiryString(attempt.expiry)}</div>
            </div>
          )}
        </div>
      </div>
      <div>
        <ExternalLinkButton
          intercomTarget={"bitcoin-pay-in-wallet-link"}
          href={createPaymentLink(attempt?.onChainAddress, attempt?.amountMsat)}
          buttonPosition={ButtonPosition.fullWidth}
          disabled={isLoading || !attempt?.onChainAddress || !attempt?.amountMsat}
          //icon={<BitcoinIcon ?? />}
        >
          {t("Pay with wallet")}
        </ExternalLinkButton>
      </div>
      <div className={styles.copyAndCollapseWrapper}>
        <div className={styles.copyWrapper}>
          <CopyButton
            className={styles.copyButton}
            intercomTarget={"copy-on-chain-address-button"}
            copyText={attempt?.onChainAddress}
            disabled={isLoading || !attempt?.onChainAddress}
          >
            <CopyIcon /> {t("CopyAddress")}
          </CopyButton>
          <CopyButton
            className={styles.copyButton}
            intercomTarget={"copy-on-chain-amount-button"}
            copyText={amountToBtcStr(attempt?.amountMsat || 0)}
            disabled={isLoading || !attempt?.amountMsat}
          >
            <CopyIcon /> {t("CopyAmount")}
          </CopyButton>
        </div>
        {isMobile && (
          <div className={styles.collapseLink}>
            {qrCollapsed && (
              <span onClick={() => setQrCollapsed(false)}>
                {t("ShowQR")}
                <ChevronDownIcon />
              </span>
            )}
            {!qrCollapsed && (
              <span onClick={() => setQrCollapsed(true)}>
                {t("HideQR")}
                <ChevronUpIcon />
              </span>
            )}
          </div>
        )}
      </div>
      <div
        className={classNames(styles.qrCodeWrapper, {
          [styles.collapse]: qrCollapsed,
        })}
      >
        {attempt?.onChainAddress && attempt?.amountMsat && (
          <div className={classNames(styles.qrPlaceholder, { [styles.collapse]: qrCollapsed })}>
            <QRCodeCanvas
              size={400}
              value={createPaymentLink(attempt?.onChainAddress, attempt?.amountMsat)}
              style={{
                width: "100%",
                height: "auto",
              }}
            />
          </div>
        )}
        {(!attempt?.onChainAddress || !attempt?.amountMsat) && (
          <canvas className={classNames(styles.qrPlaceholder, styles.loading)} width={"240px"} height={"240px"} />
        )}
      </div>
      <div
        className={classNames(styles.addressAndAmountNote, {
          [styles.loading]: isLoading,
        })}
      >
        {attempt?.onChainAddress && attempt?.amountMsat && (
          <Note title={t("AmountAndAddress")}>
            <div className={styles.amountAndAddress}>
              <div>{amountToBtcStr(attempt.amountMsat)}</div>
              <div>{attempt.onChainAddress}</div>
            </div>
          </Note>
        )}
      </div>
    </div>
  );
}

import styles from "pages/checkout/paymentMethods/Lightning/LightningAttempt.module.scss";
import { QRCodeCanvas } from "qrcode.react";
import { useTranslation } from "react-i18next";
import api, { PaymentMethod } from "api/api";
import { useContext, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import Note from "features/note/Note";
import {
  ChevronDown12Filled as ChevronDownIcon,
  ChevronUp12Filled as ChevronUpIcon,
  Copy20Regular as CopyIcon,
} from "@fluentui/react-icons";
import { ButtonPosition, CopyButton, ExternalLinkButton } from "components/buttons/Button";
import i18n from "i18n";
import useWindowWidth, { mobileWidth } from "features/width/useWindowWidth";
import { CheckoutContext } from "pages/checkout/CheckoutPage";
import { BTC, MilliSatInBTC } from "constants/backend";

type UntilExpiry = {
  minutes?: number;
  seconds?: number;
};

function formatMSatAmount(amountMsat: number): string {
  return i18n.t("SatSpaceFormatterWithSymbol", { value: amountMsat / 1000, ns: "currency" });
}

export default function LightningAttempt() {
  const { t } = useTranslation("checkout");
  const [qrAndInvoiceCollapsed, setQrAndInvoiceCollapsed] = useState<boolean>(true);

  const { sessionResp, loggedInCustomer, paymentSessionStatus, setAttemptResp } = useContext(CheckoutContext);

  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= mobileWidth;

  useEffect(() => {
    if (!isMobile) {
      setQrAndInvoiceCollapsed(false);
    }
  }, [isMobile]);

  const lastFetched = useRef<number>(0);

  const [requestAttempt, attemptResp] = api.useRequestCheckoutAttemptMutation();

  const attempt = attemptResp?.data;
  const isLoading = attemptResp?.isLoading;
  const isError = attemptResp?.isError;

  const [untilExpiry, setUntilExpiry] = useState<UntilExpiry>({ minutes: undefined, seconds: undefined });

  function getExchangeRateStr(): string | undefined {
    if (!attempt?.amountMsat || !sessionResp?.data?.amount || !paymentSessionStatus?.status) {
      return undefined;
    }

    if (sessionResp?.data?.currencyIso3 === BTC) {
      return undefined;
    }

    const amountBTC = attempt.amountMsat / MilliSatInBTC;
    const amountFiat = sessionResp?.data?.amount;
    const amountAvailableBTC = paymentSessionStatus?.fundingAmountAvailable
      ? paymentSessionStatus?.fundingAmountAvailable / MilliSatInBTC
      : 0;

    const rate = amountFiat / (amountBTC + amountAvailableBTC);

    return i18n.t("BtcEurPriceFormatter", { value: rate, ns: "currency" });
  }

  function calculateUntilExpiry() {
    const expiry = attempt?.expiry;
    if (!expiry) {
      return;
    }

    const now = new Date().getTime();
    const expiryTime = new Date(expiry).getTime();
    const timeLeft = expiryTime - now;

    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

    setUntilExpiry({ minutes, seconds });
  }

  // Update the count-down every 100 milliseconds
  useEffect(() => {
    const interval = setInterval(calculateUntilExpiry, 100);

    return () => clearInterval(interval);
  }, [attempt?.expiry]);

  // When the invoice expires, fetch a new one
  useEffect(() => {
    if (untilExpiry?.seconds != null && untilExpiry.seconds < 0) {
      fetchPaymentAttempt();
    }
  }, [untilExpiry?.seconds]);

  useEffect(() => {
    setAttemptResp(attemptResp);
  }, [attemptResp]);

  useEffect(() => {
    // To go around the StrictMode running the query twice, do not allow to run the query twice within 1 second
    if (new Date().getTime() - lastFetched.current > 1000) {
      fetchPaymentAttempt();
    }
    return () => {
      lastFetched.current = new Date().getTime();
    };
  }, [sessionResp?.data?.paymentSessionId]);

  useEffect(() => {
    // Try refetching after 5 sec if there is error in the query
    if (isError) {
      setTimeout(() => {
        fetchPaymentAttempt();
      }, 5000);
    }
  }, [isError]);

  function fetchPaymentAttempt() {
    if (!isLoading && sessionResp?.data?.paymentSessionId) {
      requestAttempt({
        paymentSessionId: sessionResp?.data?.paymentSessionId || "",
        checkoutAttempt: {
          customerId: loggedInCustomer?.customerId,
          paymentMethod: PaymentMethod.Lightning,
        },
      });
    }
  }

  // error shown in PaymentMethodRenderer
  if (attempt?.blockedByCompliance || isError) {
    return <div></div>;
  }

  return (
    <div className={styles.paymentRequestContent}>
      <div className={styles.infoWrapper}>
        <div className={classNames(styles.amountAndRateWrapper, { [styles.loading]: isLoading })}>
          <div className={classNames(styles.satAmountWrapper)}>
            {attempt?.amountMsat && formatMSatAmount(attempt.amountMsat)}
          </div>
          <div className={classNames(styles.exchangeRateWrapper)}>{getExchangeRateStr()}</div>
        </div>
        <div>
          <div className={classNames({ [styles.loading]: isLoading })} />
          {untilExpiry?.seconds != null && untilExpiry.seconds >= 0 && (
            <span>
              {t("ExpiresIn")}: {untilExpiry.minutes} {t("min")} {untilExpiry.seconds} {t("sec")}
            </span>
          )}
        </div>
      </div>
      <div>
        <ExternalLinkButton
          intercomTarget={"lightning-pay-in-wallet-link"}
          href={`lightning:${attempt?.lnInvoice}`}
          buttonPosition={ButtonPosition.fullWidth}
          disabled={isLoading || !attempt?.lnInvoice}
          // icon={<LightningIcon className={styles.lightningIcon} />}
        >
          {t("Open wallet")}
        </ExternalLinkButton>
      </div>
      <div className={styles.copyAndCollapseWrapper}>
        <CopyButton
          className={styles.copyInvoiceButton}
          intercomTarget={"copy-lightning-invoice-button"}
          copyText={attempt?.lnInvoice}
          disabled={isLoading || !attempt?.lnInvoice}
        >
          <CopyIcon /> {t("CopyInvoice")}
        </CopyButton>
        {isMobile && (
          <div className={styles.collapseLink}>
            {qrAndInvoiceCollapsed && (
              <span onClick={() => setQrAndInvoiceCollapsed(false)}>
                {t("ShowQRAndInvoice")}
                <ChevronDownIcon />
              </span>
            )}
            {!qrAndInvoiceCollapsed && (
              <span onClick={() => setQrAndInvoiceCollapsed(true)}>
                {t("HideQRAndInvoice")}
                <ChevronUpIcon />
              </span>
            )}
          </div>
        )}
      </div>
      <div
        className={classNames(styles.qrCodeWrapper, {
          [styles.loading]: isLoading,
          [styles.collapse]: qrAndInvoiceCollapsed,
        })}
      >
        {attempt?.lnInvoice && (
          <QRCodeCanvas
            size={800}
            value={attempt?.lnInvoice || ""}
            style={{
              width: "100%",
              height: "auto",
            }}
          />
        )}
        {!attempt?.lnInvoice && (
          <canvas
            className={classNames(styles.qrPlaceholder, styles.loading, { [styles.collapse]: qrAndInvoiceCollapsed })}
            width={"480px"}
            height={"480px"}
          />
        )}
      </div>
      <div
        className={classNames(styles.paymentRequestString, {
          [styles.loading]: isLoading,
          [styles.collapse]: qrAndInvoiceCollapsed,
        })}
      >
        {attempt?.lnInvoice && <Note title={t("Payment request")}>{attempt?.lnInvoice}</Note>}
      </div>
    </div>
  );
}

import styles from "pages/demo/demoShopPage.module.scss";
import { products } from "pages/demo/products";
import PayEdenButton from "pages/demo/PayEdenButton";
import DemoHeader from "pages/demo/DemoHeader";

function DemoShopPage() {
  // const { t } = useTranslation();

  return (
    <div className={styles.demoPageWrapper}>
      <DemoHeader />
      <div className={styles.productGrid}>
        {products.map((product, index) => (
          <div key={index} className={styles.productCard}>
            <img src={product.image} alt={product.name} className={styles.productImage} />
            <h3 className={styles.productName}>{product.name}</h3>
            <p className={styles.productDescription}>{product.description}</p>
            <div className={styles.productPrice}>€{product.price}</div>
            <PayEdenButton product={product} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default DemoShopPage;

import api, { CustomerListInternal, CustomerListRecordInternal } from "api/api";
import * as routes from "constants/routes";
import { useAppSelector } from "store/hooks";
import { selectSelectedCompanyId } from "features/navigation/navSlice";
import Table from "features/table/Table";
import DefaultCellRenderer from "features/table/DefaultCellRenderer";
import { useTranslation } from "react-i18next";
import { usePagination } from "components/table/pagination/usePagination";
import { ColumnMetaData } from "features/table/types";
import TablePageTemplate, { TableControlSection } from "features/templates/tablePageTemplate/TablePageTemplate";
import { useHasPermission } from "features/authorization/ComponentPermissionChecker";
import { useLocation } from "react-router";
import NoMatch from "pages/noMatch/NoMatch";
import { formatCountryIso3ToNameFunc, joinAndOverwriteColumns } from "features/table/columnFormatFunctions";
import { useTableColumns } from "features/table/tableColumnHooks";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import { getErrorResponse } from "components/errors/validationErrors";
import { toastCategory } from "features/toast/Toasts";
import { useEffect, useState } from "react";
import React from "react";
import ToastContext from "features/toast/context";
import Button, { ColorVariant } from "components/buttons/Button";
import ViewsSidebar from "features/viewManagement/ViewsSidebar";
import { Options20Regular as OptionsIcon, ArrowSync20Regular as RefreshIcon } from "@fluentui/react-icons";
import { FilterInterface } from "features/sidebar/sections/filter/filter";

const columns: ColumnMetaData<CustomerListRecordInternal>[] = [
  {
    heading: "FirstName",
    type: "TextCell",
    key: "firstName",
    valueType: "string",
    headingLocalizePackage: "user",
    sortable: true,
    filterable: true,
  },
  {
    heading: "LastName",
    type: "TextCell",
    key: "lastName",
    valueType: "string",
    headingLocalizePackage: "user",
    sortable: true,
    filterable: true,
  },
  {
    heading: "Email",
    type: "TextCell",
    key: "email",
    valueType: "string",
    headingLocalizePackage: "user",
    sortable: true,
    filterable: true,
  },
  {
    heading: "Country",
    type: "TextCell",
    key: "residenceCountryIso3",
    valueType: "string",
    formatFunction: formatCountryIso3ToNameFunc,
    headingLocalizePackage: "customer",
  },
  {
    heading: "PaymentsCount",
    type: "NumericCell",
    key: "paymentSessionCount",
    valueType: "number",
    headingLocalizePackage: "customer",
    sortable: true,
  },
];

const adminColumns: ColumnMetaData<CustomerListRecordInternal>[] = [
  {
    heading: "BirthDate",
    type: "DateCell",
    key: "birthDate",
    valueType: "date",
    headingLocalizePackage: "customer",
  },
  {
    heading: "KnowYourCustomerCheckedAt",
    type: "DateCell",
    key: "knowYourCustomerCheckedAt",
    valueType: "datetime",
    headingLocalizePackage: "customer",
    sortable: true,
    filterable: true,
  },
  {
    heading: "Companies",
    type: "TextCell",
    key: "companyNames",
    valueType: "string",
    headingLocalizePackage: "company",
    sortable: true,
    filterable: true,
  },
  {
    heading: "CreatedAt",
    type: "DateCell",
    key: "createdAt",
    valueType: "datetime",
    headingLocalizePackage: "common",
    sortable: true,
  },
  {
    heading: "CreatedByUserFullName",
    type: "TextCell",
    key: "createdByUserFullName",
    valueType: "string",
    headingLocalizePackage: "common",
  },
  {
    heading: "CreatedByApiKeyName",
    type: "TextCell",
    key: "createdByApiKeyName",
    valueType: "string",
    headingLocalizePackage: "common",
  },
  {
    heading: "UpdatedAt",
    type: "DateCell",
    key: "updatedAt",
    valueType: "datetime",
    headingLocalizePackage: "common",
    sortable: true,
  },
  {
    heading: "UpdatedByUserFullName",
    type: "TextCell",
    key: "updatedByUserFullName",
    valueType: "string",
    headingLocalizePackage: "common",
  },
  {
    heading: "UpdatedByApiKeyName",
    type: "TextCell",
    key: "updatedByApiKeyName",
    valueType: "string",
    headingLocalizePackage: "common",
  },
];

function CustomersPage() {
  const { t } = useTranslation("customer");
  const location = useLocation();
  const isAdminPage: boolean = location.pathname.includes(routes.ADMIN_CUSTOMERS);
  const selectedCompanyId = useAppSelector(selectSelectedCompanyId);
  const hasEdenAdminPermission = useHasPermission(["edenAdmin"]);
  const toastRef = React.useContext(ToastContext);
  const [sidebarExpanded, setSidebarExpanded] = useState(false);

  const [cols, sortBy, sortDirection, filters, filterJSON, handleFilterUpdate] =
    useTableColumns<CustomerListRecordInternal>(isAdminPage ? joinAndOverwriteColumns(columns, adminColumns) : columns);

  const [getPaginator, limit, offset] = usePagination("customersPagination", 50);

  let isLoading: boolean = false;
  let isFetching: boolean = false;
  let isUninitialized: boolean = true;
  let error: FetchBaseQueryError | SerializedError | undefined = undefined;

  useEffect(() => {
    const err = getErrorResponse(error);
    if (err?.message) {
      toastRef?.current?.addToast(err.message, toastCategory.error);
    }
  }, [error]);

  const allCustomersResponse = api.useGetAllCustomersQuery<{
    data: CustomerListInternal;
    isLoading: boolean;
    isFetching: boolean;
    isUninitialized: boolean;
    isSuccess: boolean;
    error: FetchBaseQueryError | SerializedError | undefined;
  }>(
    {
      limit: limit,
      offset: offset,
      sortby: sortBy,
      sortdirection: sortDirection,
      filter: filterJSON,
    },
    { skip: !isAdminPage || !hasEdenAdminPermission },
  );

  const companyCustomersResponse = api.useGetCustomersByCompanyIdQuery<{
    data: CustomerListInternal;
    isLoading: boolean;
    isFetching: boolean;
    isUninitialized: boolean;
    isSuccess: boolean;
    error: FetchBaseQueryError | SerializedError | undefined;
  }>(
    {
      companyId: selectedCompanyId || "",
      limit: limit,
      offset: offset,
      sortby: sortBy,
      sortdirection: sortDirection,
      filter: filterJSON,
    },
    { skip: isAdminPage || !selectedCompanyId },
  );

  let data: CustomerListRecordInternal[] = [];
  let totalCount: number = 0;

  if (isAdminPage) {
    if (!hasEdenAdminPermission) {
      return <NoMatch />;
    }
    isLoading = allCustomersResponse?.isLoading;
    isFetching = allCustomersResponse?.isFetching;
    isUninitialized = allCustomersResponse?.isUninitialized;
    data = allCustomersResponse?.data?.records || [];
    totalCount = allCustomersResponse?.data?.totalCount || 0;
    error = allCustomersResponse?.error;
  } else {
    isLoading = companyCustomersResponse?.isLoading;
    isFetching = companyCustomersResponse?.isFetching;
    isUninitialized = companyCustomersResponse?.isUninitialized;
    data = companyCustomersResponse?.data?.records || [];
    totalCount = companyCustomersResponse?.data?.totalCount || 0;
    error = companyCustomersResponse?.error;
  }

  const closeSidebarHandler = () => {
    setSidebarExpanded(false);
  };

  const tableControls = (
    <TableControlSection intercomTarget={"table-page-controls"}>
      <Button
        intercomTarget="refresh-customers-table"
        buttonColor={ColorVariant.primary}
        icon={<RefreshIcon />}
        onClick={() => {
          if (isAdminPage) {
            allCustomersResponse.refetch();
          } else {
            companyCustomersResponse.refetch();
          }
        }}
      />
      <Button
        intercomTarget="table-settings"
        onClick={() => {
          setSidebarExpanded(!sidebarExpanded);
        }}
        icon={<OptionsIcon />}
        hideMobileText={true}
        id={"tableControlsButton"}
      >
        {t("TableOptions", { ns: "common" })}
      </Button>
    </TableControlSection>
  );

  const sidebar = (
    <ViewsSidebar
      onExpandToggle={closeSidebarHandler}
      expanded={sidebarExpanded}
      allColumns={cols}
      filterTemplate={{ key: "", category: "string" } as FilterInterface}
      filters={filters}
      handleFilterUpdate={handleFilterUpdate}
    />
  );

  return (
    <TablePageTemplate
      title={isAdminPage ? t("AllCustomers") : t("Customers")}
      titleContent={""}
      sidebarExpanded={sidebarExpanded}
      sidebar={sidebar}
      tableControls={tableControls}
      pagination={getPaginator(totalCount || 0)}
    >
      <Table
        intercomTarget={"customers-table"}
        cellRenderer={DefaultCellRenderer}
        data={data}
        activeColumns={cols}
        isLoading={isLoading || isFetching || isUninitialized}
      />
    </TablePageTemplate>
  );
}

export default CustomersPage;

import api from "api/api";
import { getErrorResponse } from "components/errors/validationErrors";
import { BTC } from "constants/backend";
import { useEffect, useState } from "react";
import BaseBitcoinWithdraw, { BitcoinWithdrawType } from "features/withdraw/bitcoinWithdraw/BaseBitcoinWithdraw";
import Note, { NoteType } from "features/note/Note";
import { useTranslation } from "react-i18next";

function CustomerBitcoinWithdraw() {
  const { t } = useTranslation("withdraw");
  const [errMessage, setErrMessage] = useState<string>();

  const loggedInCustomerResp = api.useGetCustomerQuery(undefined, {
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
  });

  const balanceResp = api.useGetCustomerBalanceQuery(BTC, {
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
    pollingInterval: 10000, // 10 sec polling
  });

  const [
    createLightningWithdraw,
    {
      error: createLightningWithdrawalError,
      isLoading: isLoadingCreateLightningWithdrawal,
      isSuccess: isSuccessCreateLightningWithdrawal,
    },
  ] = api.useCreateCustomerLightningWithdrawalMutation();

  useEffect(() => {
    const err = getErrorResponse(balanceResp?.error);
    setErrMessage(err?.message || "");
  }, [balanceResp?.error]);

  function withdrawLightning(paymentRequest: string, amountMsat?: number) {
    createLightningWithdraw({
      customerId: loggedInCustomerResp.data?.customerId || "",
      lightningWithdrawal: {
        amountMsat: amountMsat,
        paymentRequest: paymentRequest,
      },
    });
  }

  function withdrawOnChain(address: string, amountMsat: number) {
    // TODO ONCHAIN!
    console.log("TODO ONCHAIN!", address, amountMsat);
  }

  return (
    <div>
      {errMessage && (
        <div>
          <Note noteType={NoteType.error} title={t("Error", { ns: "common" })}>
            {errMessage}
          </Note>
        </div>
      )}
      <BaseBitcoinWithdraw
        type={BitcoinWithdrawType.customer}
        balanceMSat={balanceResp?.data?.balance}
        withdrawLightning={withdrawLightning}
        withdrawOnChain={withdrawOnChain}
        withdrawSuccess={isSuccessCreateLightningWithdrawal}
        withdrawError={createLightningWithdrawalError}
        withdrawLoading={isLoadingCreateLightningWithdrawal}
      />
    </div>
  );
}

export default CustomerBitcoinWithdraw;

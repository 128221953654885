import { PaymentSessionListRecord, RefundSessionListRecord } from "api/api.generated";
import { ColumnMetaData } from "features/table/types";
import i18n from "i18n";

const csvSeparator = ";";

export function createCsvFile<T extends PaymentSessionListRecord | RefundSessionListRecord>(
  data: Array<T>,
  filename: string,
  columns?: ColumnMetaData<T>[],
): void {
  // If there is no data, do not download anything
  if (data.length === 0) {
    console.warn("Tried to download CSV file with no data to download");
    return;
  }

  // get headers from defined colums or from the first object in the array
  const keys = columns !== undefined ? columns.map((column) => column.key) : Object.keys(data[0]);

  // TODO format functions for column data?

  // Convert objects to arrays of values
  const rows = data.map((obj: T) =>
    keys.map((key) => {
      return obj[key as keyof T] ?? "";
    }),
  );

  let headers = keys;

  // translate headers
  if (columns) {
    headers = columns?.map((col) => {
      if (col.headingLocalizePackage) {
        return i18n.t(col.heading, { ns: col.headingLocalizePackage || "" });
      }

      return col.heading;
    });
  }

  // Combine headers and rows into a single array
  const csvData = [headers, ...rows];

  // Convert the array to CSV format
  const csv = csvData.map((row) => row.join(csvSeparator)).join("\n");

  // Create a blob from the CSV string
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

  // Create a URL for the blob
  const url = URL.createObjectURL(blob);

  // Create a link element to trigger the download
  const link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("download", filename);

  // Append the link to the body and trigger the download
  document.body.appendChild(link);
  link.click();

  // Clean up the link and URL
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}

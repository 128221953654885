import { useTranslation } from "react-i18next";
import { Form, Input, InputRow, Select } from "components/forms/forms";
import Button, { ButtonPosition, ButtonRow, ColorVariant } from "components/buttons/Button";
import { getErrorResponse, Tie } from "components/errors/validationErrors";
import Note, { NoteType } from "features/note/Note";
import {
  ArrowReset20Regular as CancelIcon,
  Edit20Regular as EditIcon,
  Save20Regular as SaveIcon,
} from "@fluentui/react-icons";
import { FormEvent, useContext, useEffect, useRef, useState } from "react";
import api, { Company, CountryOption, getCountriesAsOptions, InputError } from "api/api";
import { toastCategory } from "features/toast/Toasts";
import { useHasPermission } from "features/authorization/ComponentPermissionChecker";
import ToastContext from "features/toast/context";
import { useSelector } from "react-redux";
import { FormToJson } from "api/apiUtils";
import { useAppSelector } from "store/hooks";
import { selectSelectedCompanyId } from "features/navigation/navSlice";
import Spinny from "features/spinny/Spinny";
import styles from "pages/company/CompanyPage.module.scss";
import classNames from "classnames";

export default function UpdateCompanyForm() {
  const { t } = useTranslation("common");
  const { t: tv } = useTranslation("validation");
  const { t: tc } = useTranslation("company");
  const selectedCompanyId = useAppSelector(selectSelectedCompanyId);

  // const readPermission = useHasPermission(["companyRead", "companyWriteRead"]);
  const [editing, setEditing] = useState(false);
  const writePermission = useHasPermission(["companyWriteRead"]);
  const [country, setCountry] = useState<CountryOption>();

  const [ie, setInputErrors] = useState<InputError[]>();
  const [errMessage, setErrMessage] = useState<string>("");
  const toastRef = useContext(ToastContext);

  const [emptyCheckoutBackgroundColor, setEmptyCheckoutBackgroundColor] = useState<boolean | undefined>(undefined);
  const [emptyCheckoutThemeColor, setEmptyCheckoutThemeColor] = useState<boolean | undefined>(undefined);

  const { data: company } = api.useGetCompanyByIdQuery(selectedCompanyId || "", {
    // skip: !selectedCompanyId || !readPermission,
    refetchOnMountOrArgChange: true,
  });

  api.useGetCountriesQuery();
  const countryOptions = useSelector(getCountriesAsOptions);

  useEffect(() => {
    if (company) {
      setCountry(countryOptions.find((country) => country.value === company.countryIso3));

      // initially default or not
      if (emptyCheckoutBackgroundColor === undefined) {
        setEmptyCheckoutBackgroundColor(!company.checkoutBackgroundColor);
      }
      if (emptyCheckoutThemeColor === undefined) {
        setEmptyCheckoutThemeColor(!company.checkoutThemeColor);
      }
    }
  }, [company, countryOptions]);

  const [
    updateCompany,
    { error: updateCompanyErrors, isLoading: loadingUpdateCompany, isSuccess: isSuccessUpdateCompany },
  ] = api.useUpdateCompanyMutation();

  useEffect(() => {
    const err = getErrorResponse(updateCompanyErrors);
    setErrMessage(err?.message || "");
    if (err && err.inputErrors) {
      setInputErrors(err.inputErrors);
    } else {
      setInputErrors([]);
    }
  }, [updateCompanyErrors]);

  useEffect(() => {
    if (isSuccessUpdateCompany) {
      toastRef?.current?.addToast(tc("companySuccessfullyUpdated"), toastCategory.success);
      setEditing(false);
    }
  }, [isSuccessUpdateCompany]);

  function submit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!writePermission || !selectedCompanyId) {
      return;
    }

    // Get all the data from the form
    const formJson = FormToJson<Company>(event);

    if (emptyCheckoutBackgroundColor) {
      formJson.checkoutBackgroundColor = undefined;
    }

    if (emptyCheckoutThemeColor) {
      formJson.checkoutThemeColor = undefined;
    }

    updateCompany({ company: formJson, companyId: selectedCompanyId });
  }

  // formRef
  const formRef = useRef<HTMLFormElement>(null);
  const checkoutBackgroundColorRef = useRef<HTMLInputElement>(null);
  const checkoutThemeColorRef = useRef<HTMLInputElement>(null);

  function resetForm() {
    setEditing(false);
    setInputErrors([]);
    setErrMessage("");
    // change the selected country
    setCountry(countryOptions.find((country) => country.value === company?.countryIso3));
    // rerender the form to reset values based on the "company" object
    if (formRef.current) {
      formRef?.current?.reset();
      setEmptyCheckoutBackgroundColor(!company?.checkoutBackgroundColor);
      setEmptyCheckoutThemeColor(!company?.checkoutThemeColor);
    }
  }

  return (
    <Form onSubmit={submit} intercomTarget={"update-company-form"} key={"company-" + company?.companyId} ref={formRef}>
      <InputRow verticalAlign="top">
        <Input
          name={"companyName"}
          label={tc("CompanyName") + " *"}
          defaultValue={company?.companyName}
          errors={Tie("CompanyName", ie, ["company"])}
          disabled={!editing}
        />
        <Input
          name={"displayName"}
          label={tc("DisplayName")}
          defaultValue={company?.displayName}
          errors={Tie("DisplayName", ie, ["company"])}
          disabled={!editing}
        />
      </InputRow>
      <Select
        intercomTarget={"select-countries"}
        label={tc("Country") + " *"}
        name={"countryIso3"}
        options={countryOptions}
        value={country}
        onChange={(newValue) => {
          setCountry(countryOptions.find((country) => country.value === newValue));
        }}
        errors={Tie("CountryISO3", ie, ["company"])}
        isDisabled={!editing}
      />
      <InputRow verticalAlign="top">
        <Input
          name={"billingEmail"}
          label={tc("BillingEmail")}
          defaultValue={company?.billingEmail}
          errors={Tie("BillingEmail", ie, ["company"])}
          disabled={!editing}
        />
        <Input
          name={"adminEmail"}
          label={tc("AdminEmail")}
          defaultValue={company?.adminEmail}
          errors={Tie("AdminEmail", ie, ["company"])}
          helpText={tc("AdminEmailHelpText")}
          disabled={!editing}
        />
      </InputRow>
      <InputRow verticalAlign="top">
        <Input
          name={"website"}
          label={tc("Website")}
          defaultValue={company?.website}
          errors={Tie("Website", ie, ["company"])}
          disabled={!editing}
        />
        <Input
          name={"industryCategory"}
          label={tc("IndustryCategory")}
          defaultValue={company?.industryCategory}
          errors={Tie("IndustryCategory", ie, ["company"])}
          disabled={!editing}
        />
      </InputRow>
      <InputRow verticalAlign="bottom">
        <InputRow verticalAlign="bottom">
          <Input
            name={"checkoutBackgroundColor"}
            label={tc("CheckoutBackgroundColor")}
            defaultValue={company?.checkoutBackgroundColor}
            errors={Tie("CheckoutBackgroundColor", ie, ["company"])}
            disabled={!editing}
            type={"color"}
            ref={checkoutBackgroundColorRef}
            onChange={() => {
              setEmptyCheckoutBackgroundColor(false);
            }}
            className={classNames({ [styles.colorInputValueUnset]: emptyCheckoutBackgroundColor })}
          />
          <Button
            type={"button"}
            className={styles.colorInputResetButton}
            buttonPosition={ButtonPosition.left}
            onClick={() => {
              if (checkoutBackgroundColorRef.current?.value) {
                checkoutBackgroundColorRef.current.value = "";
              }

              setEmptyCheckoutBackgroundColor(true);
            }}
            disabled={!editing}
          >
            {t("Reset", { ns: "common" })}
          </Button>
        </InputRow>
        <InputRow verticalAlign="bottom">
          <Input
            name={"checkoutThemeColor"}
            label={tc("CheckoutThemeColor")}
            defaultValue={company?.checkoutThemeColor}
            errors={Tie("CheckoutThemeColor", ie, ["company"])}
            disabled={!editing}
            type={"color"}
            ref={checkoutThemeColorRef}
            onChange={() => {
              setEmptyCheckoutThemeColor(false);
            }}
            className={classNames({ [styles.colorInputValueUnset]: emptyCheckoutThemeColor })}
          />
          <Button
            type={"button"}
            className={styles.colorInputResetButton}
            buttonPosition={ButtonPosition.left}
            onClick={() => {
              if (checkoutThemeColorRef.current?.value) {
                checkoutThemeColorRef.current.value = "";
              }

              setEmptyCheckoutThemeColor(true);
            }}
            disabled={!editing}
          >
            {t("Reset", { ns: "common" })}
          </Button>
        </InputRow>
      </InputRow>

      {/* TODO default company language */}

      {!editing && (
        <Button
          intercomTarget="enable-edit-button"
          type={"button"}
          disabled={!writePermission || !selectedCompanyId}
          buttonColor={ColorVariant.primary}
          icon={<EditIcon />}
          onClick={() => {
            setEditing(true);
          }}
        >
          {t("Edit")}
        </Button>
      )}
      {editing && (
        <ButtonRow>
          <Button
            intercomTarget="company-save-edit-button"
            type={"button"}
            buttonColor={ColorVariant.ghost}
            disabled={loadingUpdateCompany}
            onClick={resetForm}
            icon={loadingUpdateCompany ? <Spinny /> : <CancelIcon />}
          >
            {t("Cancel")}
          </Button>
          <Button
            intercomTarget="company-save-edit-button"
            type={"submit"}
            buttonColor={ColorVariant.success}
            disabled={loadingUpdateCompany}
            buttonPosition={ButtonPosition.right}
            icon={loadingUpdateCompany ? <Spinny /> : <SaveIcon />}
          >
            {t("Save")}
          </Button>
        </ButtonRow>
      )}
      {updateCompanyErrors && (
        <Note noteType={NoteType.error} title={t("Error")}>
          {tv(errMessage)}
        </Note>
      )}
    </Form>
  );
}

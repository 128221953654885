import { useEffect } from "react";

const CHAT_BASE_URL = "https://chat.mion.group";

const merchantUIInstance = "merchant";
const checkoutInstance = "checkout";

const tokens = {
  [merchantUIInstance]: "XQ4VEkuytUWoJadPABMU9X3E",
  [checkoutInstance]: "Mp8RKZCbmiunXBn7sg11rG47",
};

export function LivechatMerchantUI() {
  return Livechat(merchantUIInstance);
}

export function LivechatCheckout() {
  return Livechat(checkoutInstance);
}

export function LivechatToggle() {
  if (!window.$chatwoot) {
    console.error("Chat not loaded on this page.");
    return;
  }

  window.$chatwoot.toggle();
}

export function LivechatOpen() {
  if (!window.$chatwoot) {
    console.error("Chat not loaded on this page.");
    return;
  }

  window.$chatwoot.toggle("open");
}

export function LivechatClose() {
  if (!window.$chatwoot) {
    console.error("Chat not loaded on this page.");
    return;
  }

  window.$chatwoot.toggle("close");
}

declare global {
  interface Window {
    chatwootSettings: object;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    chatwootSDK: any;
  }
}

function Livechat(instance: string) {
  useEffect(() => {
    window.chatwootSettings = {
      hideMessageBubble: true, // hide bubble because we have a custom button
      position: "right",
      locale: "en",
      type: "standard",
      // useBrowserLanguage: false, // Set widget language from user's browser
    };

    const g = document.createElement("script");
    g.async = true;
    g.defer = true;
    g.src = CHAT_BASE_URL + "/packs/js/sdk.js";

    document.body.appendChild(g);

    g.onload = function () {
      window.chatwootSDK.run({
        websiteToken: tokens[instance as keyof typeof tokens],
        baseUrl: CHAT_BASE_URL,
      });
    };

    return () => {
      document.body.removeChild(g);
    };
  }, [instance]); // recreate on instance change

  return <span></span>;
}

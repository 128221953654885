import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import React from "react";
import Table from "features/table/Table";
import DefaultCellRenderer from "features/table/DefaultCellRenderer";
import TablePageTemplate, { TableControlSection } from "features/templates/tablePageTemplate/TablePageTemplate";
import api, {
  EdenAdminGetLightningWithdrawalsResp,
  LightningWithdrawalListRecord,
  LightningWithdrawalStatus,
} from "api/api";
import { ColumnMetaData } from "features/table/types";
import ToastContext from "features/toast/context";
import { toastCategory } from "features/toast/Toasts";
import { getErrorResponse } from "components/errors/validationErrors";
import { ArrowSync20Regular as RefreshIcon, Options20Regular as OptionsIcon } from "@fluentui/react-icons";
import { ColorVariant } from "components/buttons/buttonVariants";
import { useTableColumns } from "features/table/tableColumnHooks";
import { usePagination } from "components/table/pagination/usePagination";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import Button from "components/buttons/Button";
import ViewsSidebar from "features/viewManagement/ViewsSidebar";
import { FilterInterface } from "features/sidebar/sections/filter/filter";
import { EnumAsOptionsLabelKey } from "api/apiUtils";
import { formatTranslateFunc } from "features/table/columnFormatFunctions";

const columns: ColumnMetaData<LightningWithdrawalListRecord>[] = [
  // {
  //   heading: "",
  //   type: "LinkCell",
  //   key: "lightningWithdrawalId",
  //   valueType: "multilink",
  //   multilinkData: [{
  //       basePath: "",
  //       text: "Succeed",
  //       icon: <CheckIcon />,
  //       internal: true,
  //       disabled: false,
  //     },
  //     {
  //       basePath: "",
  //       text: "Fail",
  //       icon: <DismissIcon />,
  //       color: ColorVariant.error,
  //       internal: true,
  //       disabled: false,
  //     },
  //   ],
  // },
  {
    heading: "Status",
    type: "TextCell",
    key: "status",
    valueType: "enum",
    formatFunction: formatTranslateFunc,
    formatLocalizePackage: "payment",
    headingLocalizePackage: "wallet",
    selectOptions: EnumAsOptionsLabelKey(LightningWithdrawalStatus),
    sortable: true,
    filterable: true,
  },
  {
    heading: "CustomerFirstName",
    type: "TextCell",
    key: "customerFirstName",
    valueType: "string",
    headingLocalizePackage: "customer",
    sortable: true,
    filterable: true,
  },
  {
    heading: "CustomerLastName",
    type: "TextCell",
    key: "customerLastName",
    valueType: "string",
    headingLocalizePackage: "customer",
    sortable: true,
    filterable: true,
  },
  {
    heading: "CustomerEmail",
    type: "TextCell",
    key: "customerEmail",
    valueType: "string",
    headingLocalizePackage: "customer",
    sortable: true,
    filterable: true,
  },
  {
    heading: "CompanyName",
    type: "TextCell",
    key: "companyName",
    valueType: "string",
    headingLocalizePackage: "company",
    sortable: true,
    filterable: true,
  },
  {
    heading: "PaymentRequest",
    type: "LongTextCell",
    key: "paymentRequest",
    valueType: "string",
    headingLocalizePackage: "wallet",
    filterable: true,
  },
  {
    heading: "CreatedAt",
    type: "DateCell",
    key: "createdAt",
    valueType: "datetime",
    headingLocalizePackage: "common",
    sortable: true,
  },
  {
    heading: "PaidAt",
    type: "DateCell",
    key: "paidAt",
    valueType: "datetime",
    headingLocalizePackage: "wallet",
    sortable: true,
  },
];

function LightningWithdrawalsPage() {
  const { t } = useTranslation("wallet");
  const toastRef = React.useContext(ToastContext);
  // const confirm = useConfirmation();
  const [sidebarExpanded, setSidebarExpanded] = useState(false);

  const [cols, sortBy, sortDirection, filters, filterJSON, handleFilterUpdate] =
    useTableColumns<LightningWithdrawalListRecord>(columns);

  const [getPaginator, limit, offset] = usePagination("lightningWithdrawalsPagination", 50);

  const adminWithdrawalsResponse = api.useEdenAdminGetLightningWithdrawalsQuery<{
    data: EdenAdminGetLightningWithdrawalsResp;
    isLoading: boolean;
    isFetching: boolean;
    isUninitialized: boolean;
    isSuccess: boolean;
    error: FetchBaseQueryError | SerializedError | undefined;
  }>(
    {
      limit: limit,
      offset: offset,
      sortby: sortBy,
      sortdirection: sortDirection,
      filter: filterJSON,
    },
    { refetchOnMountOrArgChange: true },
  );

  const data = adminWithdrawalsResponse?.data?.records || [];
  const totalCount = adminWithdrawalsResponse?.data?.totalCount || 0;

  useEffect(() => {
    const err = getErrorResponse(adminWithdrawalsResponse.error);
    if (err?.message) {
      toastRef?.current?.addToast(err.message, toastCategory.error);
    }
  }, [adminWithdrawalsResponse.error]);

  const closeSidebarHandler = () => {
    setSidebarExpanded(false);
  };

  // const [succeedMutation, { error: succeedError, isSuccess: succeedSuccess }] =
  //   api.useEdenAdminSucceedLightningWithdrawalMutation();
  //
  // const succeedTransfer = async (value: string) => {
  //   const result = await confirm({
  //     title: t("Confirm Action", { ns: "common" }),
  //     message: t("confirmWithdrawalSuccess"),
  //   });
  //   if (result) {
  //     succeedMutation(value);
  //   }
  // };
  //
  // const succeedDisabled = (row: object | undefined) => {
  //   if (row && "status" in row) {
  //     return row.status !== "initiated" && row.status !== "failed";
  //   }
  //
  //   return true;
  // };
  //
  // const [failMutation, { error: failError, isSuccess: failSuccess }] =
  //   api.useEdenAdminFailLightningWithdrawalMutation();
  //
  // const failTransfer = async (value: string) => {
  //   const result = await confirm({
  //     title: t("Confirm Action", { ns: "common" }),
  //     message: t("confirmWithdrawalFail"),
  //   });
  //   if (result) {
  //     failMutation(value);
  //   }
  // };
  //
  // const failDisabled = (row: object | undefined) => {
  //   if (row && "status" in row) {
  //     return row.status !== "initiated" && row.status !== "succeeded";
  //   }
  //
  //   return true;
  // };
  //
  // useEffect(() => {
  //   if (succeedSuccess) {
  //     toastRef?.current?.addToast(t("Succeeded successfully"), toastCategory.success);
  //   }
  // }, [succeedSuccess]);
  //
  // useEffect(() => {
  //   if (succeedError) {
  //     const err = getErrorResponse(succeedError);
  //     toastRef?.current?.addToast(err?.message || t("Failed to succeed"), toastCategory.error);
  //   }
  // }, [succeedError]);
  //
  // useEffect(() => {
  //   if (failSuccess) {
  //     toastRef?.current?.addToast(t("Failed successfully"), toastCategory.success);
  //   }
  // }, [failSuccess]);
  //
  // useEffect(() => {
  //   if (failError) {
  //     const err = getErrorResponse(failError);
  //     toastRef?.current?.addToast(err?.message || t("Failed to fail"), toastCategory.error);
  //   }
  // }, [failError]);
  //
  // // set multilink values
  // let finalCols = cols.map((col) => {
  //   if (col.valueType === "multilink") {
  //     return {
  //       ...col,
  //       multilinkData: col.multilinkData?.map((link) => {
  //         switch (link.text) {
  //           case "Succeed":
  //             return { ...link, action: succeedTransfer, disabledFunc: succeedDisabled };
  //           case "Fail":
  //             return { ...link, action: failTransfer, disabledFunc: failDisabled };
  //         }
  //
  //         return link;
  //       }),
  //     };
  //   }
  //   return col;
  // });

  const tableControls = (
    <TableControlSection intercomTarget={"table-page-controls"}>
      <Button
        intercomTarget="refresh-lightning-withdrawals-table"
        buttonColor={ColorVariant.primary}
        icon={<RefreshIcon />}
        onClick={() => {
          adminWithdrawalsResponse.refetch();
        }}
      />
      <Button
        intercomTarget="table-settings"
        onClick={() => {
          setSidebarExpanded(!sidebarExpanded);
        }}
        icon={<OptionsIcon />}
        hideMobileText={true}
        id={"tableControlsButton"}
      >
        {t("TableOptions", { ns: "common" })}
      </Button>
    </TableControlSection>
  );

  const sidebar = (
    <ViewsSidebar
      onExpandToggle={closeSidebarHandler}
      expanded={sidebarExpanded}
      allColumns={cols}
      filterTemplate={{ key: "", category: "string" } as FilterInterface}
      filters={filters}
      handleFilterUpdate={handleFilterUpdate}
    />
  );

  return (
    <TablePageTemplate
      title={t("Withdrawals")}
      titleContent={""}
      sidebarExpanded={sidebarExpanded}
      sidebar={sidebar}
      tableControls={tableControls}
      pagination={getPaginator(totalCount || 0)}
    >
      <Table
        intercomTarget={"lightning-withdrawals-table"}
        cellRenderer={DefaultCellRenderer}
        data={data}
        activeColumns={cols}
        isLoading={
          adminWithdrawalsResponse.isLoading ||
          adminWithdrawalsResponse.isFetching ||
          adminWithdrawalsResponse.isUninitialized
        }
      />
    </TablePageTemplate>
  );
}

export default LightningWithdrawalsPage;

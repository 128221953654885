import api from "api/api";
import { UserListInternal, UserListRecordInternal } from "api/api.generated";
import * as routes from "constants/routes";
import { useAppSelector } from "store/hooks";
import { selectSelectedCompanyId } from "features/navigation/navSlice";
import Table from "features/table/Table";
import DefaultCellRenderer from "features/table/DefaultCellRenderer";
import { useTranslation } from "react-i18next";
import { usePagination } from "components/table/pagination/usePagination";
import { ColumnMetaData } from "features/table/types";
import Button, { ButtonPosition, ColorVariant } from "components/buttons/Button";
import TablePageTemplate, { TableControlSection } from "features/templates/tablePageTemplate/TablePageTemplate";
import {
  ArrowSync20Regular as RefreshIcon,
  EditRegular as EditIcon,
  Options20Regular as OptionsIcon,
  PersonAdd20Regular as UserAddIcon,
  PersonSettings20Regular as UserAdminAddIcon,
} from "@fluentui/react-icons";
import { useHasPermission } from "features/authorization/ComponentPermissionChecker";
import { useLocation, useNavigate } from "react-router";
import NoMatch from "pages/noMatch/NoMatch";
import { useConfirmation } from "components/dialogue/ConfirmContext";
import { MultiLinkProps } from "components/table/cells/link/MultiLinkCell";
import PageTitle from "components/pageTitle/PageTitle";
import { joinAndOverwriteColumns } from "features/table/columnFormatFunctions";
import { useTableColumns } from "features/table/tableColumnHooks";
import { getErrorResponse } from "components/errors/validationErrors";
import { toastCategory } from "features/toast/Toasts";
import ToastContext from "features/toast/context";
import React, { useEffect, useState } from "react";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import ViewsSidebar from "features/viewManagement/ViewsSidebar";
import { FilterInterface } from "features/sidebar/sections/filter/filter";

const columns: ColumnMetaData<UserListRecordInternal>[] = [
  {
    heading: "Edit",
    type: "LinkCell",
    key: "userId",
    valueType: "multilink",
    headingLocalizePackage: "common",
    multilinkData: [
      {
        basePath: "/users/permissions/",
        text: "Edit",
        icon: <EditIcon />,
        internal: true,
        disabled: false,
        color: ColorVariant.primary,
        localizePackage: "user",
      },
    ],
  },
  {
    heading: "FirstName",
    type: "TextCell",
    key: "firstName",
    valueType: "string",
    headingLocalizePackage: "user",
    sortable: true,
    filterable: true,
  },
  {
    heading: "LastName",
    type: "TextCell",
    key: "lastName",
    valueType: "string",
    headingLocalizePackage: "user",
    sortable: true,
    filterable: true,
  },
  {
    heading: "Email",
    type: "TextCell",
    key: "email",
    valueType: "string",
    headingLocalizePackage: "user",
    sortable: true,
    filterable: true,
  },
  // {
  //   heading: "PhoneNumber",
  //   type: "TextCell",
  //   key: "phoneNumber",
  //   valueType: "string",
  //   headingLocalizePackage: "user",
  // },
];

const adminColumns: ColumnMetaData<UserListRecordInternal>[] = [
  // overwrite the edit column of the default columns
  {
    heading: "Edit",
    type: "LinkCell",
    key: "userId",
    valueType: "multilink",
    headingLocalizePackage: "common",
    multilinkData: [
      {
        basePath: "/users/admin-permissions/",
        text: "EditAdminPermissions",
        icon: <EditIcon />,
        internal: true,
        disabled: false,
        color: ColorVariant.primary,
        localizePackage: "user",
      },
      {
        basePath: "/users/usercompanies/",
        text: "EditUserCompanies",
        icon: <EditIcon />,
        internal: true,
        disabled: false,
        color: ColorVariant.primary,
        localizePackage: "user",
      },
    ],
  },
  {
    heading: "Companies",
    type: "TextCell",
    key: "companyNames",
    valueType: "string",
    headingLocalizePackage: "company",
    sortable: true,
    filterable: true,
  },
  {
    heading: "CreatedAt",
    type: "DateCell",
    key: "createdAt",
    valueType: "datetime",
    headingLocalizePackage: "common",
    sortable: true,
    filterable: true,
  },
  {
    heading: "CreatedByUserFullName",
    type: "TextCell",
    key: "createdByUserFullName",
    valueType: "string",
    headingLocalizePackage: "common",
  },
  {
    heading: "CreatedByApiKeyName",
    type: "TextCell",
    key: "createdByApiKeyName",
    valueType: "string",
    headingLocalizePackage: "common",
  },
  {
    heading: "UpdatedAt",
    type: "DateCell",
    key: "updatedAt",
    valueType: "datetime",
    headingLocalizePackage: "common",
    sortable: true,
  },
  {
    heading: "UpdatedByUserFullName",
    type: "TextCell",
    key: "updatedByUserFullName",
    valueType: "string",
    headingLocalizePackage: "common",
  },
  {
    heading: "UpdatedByApiKeyName",
    type: "TextCell",
    key: "updatedByApiKeyName",
    valueType: "string",
    headingLocalizePackage: "common",
  },
  {
    heading: "SuspendedAt",
    type: "DateCell",
    key: "suspendedAt",
    valueType: "datetime",
    headingLocalizePackage: "common",
    sortable: true,
    filterable: true,
  },
  {
    heading: "SuspendedByUserFullName",
    type: "TextCell",
    key: "suspendedByUserFullName",
    valueType: "string",
    headingLocalizePackage: "common",
  },
  {
    heading: "SuspendedByApiKeyName",
    type: "TextCell",
    key: "suspendedByApiKeyName",
    valueType: "string",
    headingLocalizePackage: "common",
  },
];

function UsersPage() {
  const { t } = useTranslation("user");
  const navigate = useNavigate();
  const confirm = useConfirmation();
  const location = useLocation();
  const isAdminPage: boolean = location.pathname.includes(routes.ADMIN_USERS);
  const selectedCompanyId = useAppSelector(selectSelectedCompanyId);
  const hasViewPermissionsPermission = useHasPermission(["companyUserRolesRead"]);
  const hasEditPermission = useHasPermission(["companyUsersWriteRead"]);
  const hasEdenAdminPermission = useHasPermission(["edenAdmin"]);
  const toastRef = React.useContext(ToastContext);
  const [sidebarExpanded, setSidebarExpanded] = useState(false);

  const [colsTemp, sortBy, sortDirection, filters, filterJSON, handleFilterUpdate] =
    useTableColumns<UserListRecordInternal>(isAdminPage ? joinAndOverwriteColumns(columns, adminColumns) : columns);

  // set multilink permission
  const cols = [...colsTemp].map((col) => {
    if (col.valueType === "multilink") {
      return {
        ...col,
        multilinkData: col.multilinkData?.map((link) => {
          if (link.text == "Edit") {
            return {
              ...link,
              disabled: !hasViewPermissionsPermission,
            } as MultiLinkProps;
          }

          return link;
        }),
      } as ColumnMetaData<UserListRecordInternal>;
    }

    return col as ColumnMetaData<UserListRecordInternal>;
  });

  const [getPaginator, limit, offset] = usePagination("usersPagination", 50);

  let isLoading: boolean = false;
  let isFetching: boolean = false;
  let isUninitialized: boolean = true;

  const allUsersResponse = api.useGetAllUsersQuery<{
    data: UserListInternal;
    isLoading: boolean;
    isFetching: boolean;
    isUninitialized: boolean;
    isSuccess: boolean;
    error: FetchBaseQueryError | SerializedError | undefined;
  }>(
    {
      limit: limit,
      offset: offset,
      sortby: sortBy,
      sortdirection: sortDirection,
      filter: filterJSON,
    },
    {
      skip: !isAdminPage || !hasEdenAdminPermission,
      refetchOnMountOrArgChange: true,
    },
  );

  const companyUsersResponse = api.useGetUsersByCompanyIdQuery<{
    data: UserListInternal;
    isLoading: boolean;
    isFetching: boolean;
    isUninitialized: boolean;
    isSuccess: boolean;
    error: FetchBaseQueryError | SerializedError | undefined;
  }>(
    {
      companyId: selectedCompanyId || "",
      limit: limit,
      offset: offset,
      sortby: sortBy,
      sortdirection: sortDirection,
      filter: filterJSON,
    },
    {
      skip: isAdminPage || !selectedCompanyId,
      refetchOnMountOrArgChange: true,
    },
  );

  useEffect(() => {
    const err = getErrorResponse(allUsersResponse.error || companyUsersResponse.error);
    if (err?.message) {
      toastRef?.current?.addToast(err.message, toastCategory.error);
    }
  }, [allUsersResponse.error, companyUsersResponse.error]);

  let data: UserListRecordInternal[] = [];
  let totalCount: number = 0;

  if (isAdminPage) {
    if (!hasEdenAdminPermission) {
      return <NoMatch />;
    }
    isLoading = allUsersResponse?.isLoading;
    isFetching = allUsersResponse?.isFetching;
    isUninitialized = allUsersResponse?.isUninitialized;
    data = allUsersResponse?.data?.records || [];
    totalCount = allUsersResponse?.data?.totalCount || 0;
  } else {
    isLoading = companyUsersResponse?.isLoading;
    isFetching = companyUsersResponse?.isFetching;
    isUninitialized = companyUsersResponse?.isUninitialized;
    data = companyUsersResponse?.data?.records || [];
    totalCount = companyUsersResponse?.data?.totalCount || 0;
  }

  const handleUserInvite = async () => {
    if (isAdminPage) {
      const result = await confirm({
        title: t("Confirm Action", { ns: "common" }),
        message: t("confirmGoToInviteAdminUser"),
      });

      if (result) {
        navigate(routes.INVITE_ADMIN_USER, { state: { background: location } });
      }

      return;
    }

    navigate(routes.INVITE_USER_COMPANY, { state: { background: location } });
  };

  const pageTitle = <PageTitle title={isAdminPage ? t("AllUsers") : t("Users")}></PageTitle>;

  const closeSidebarHandler = () => {
    setSidebarExpanded(false);
  };

  const tableControls = (
    <TableControlSection intercomTarget={"table-page-controls"}>
      <Button
        intercomTarget="refresh-users-table"
        buttonColor={ColorVariant.primary}
        icon={<RefreshIcon />}
        onClick={() => {
          if (isAdminPage) {
            allUsersResponse.refetch();
          } else {
            companyUsersResponse.refetch();
          }
        }}
      />
      <Button
        buttonColor={ColorVariant.overlay}
        buttonPosition={ButtonPosition.right}
        hideMobileText={true}
        icon={isAdminPage ? <UserAdminAddIcon /> : <UserAddIcon />}
        onClick={handleUserInvite}
        intercomTarget={"invite-user-button"}
        disabled={!hasEditPermission}
      >
        {isAdminPage ? t("InviteAdminUser") : t("InviteUser")}
      </Button>
      <Button
        intercomTarget="table-settings"
        onClick={() => {
          setSidebarExpanded(!sidebarExpanded);
        }}
        icon={<OptionsIcon />}
        hideMobileText={true}
        id={"tableControlsButton"}
      >
        {t("TableOptions", { ns: "common" })}
      </Button>
    </TableControlSection>
  );

  const sidebar = (
    <ViewsSidebar
      onExpandToggle={closeSidebarHandler}
      expanded={sidebarExpanded}
      allColumns={cols}
      filterTemplate={{ key: "", category: "string" } as FilterInterface}
      filters={filters}
      handleFilterUpdate={handleFilterUpdate}
    />
  );

  return (
    <TablePageTemplate
      titleComponent={pageTitle}
      titleContent={""}
      sidebarExpanded={sidebarExpanded}
      sidebar={sidebar}
      tableControls={tableControls}
      pagination={getPaginator(totalCount || 0)}
    >
      <Table
        intercomTarget={"users-table"}
        cellRenderer={DefaultCellRenderer}
        data={data}
        activeColumns={cols}
        isLoading={isLoading || isFetching || isUninitialized}
      />
    </TablePageTemplate>
  );
}

export default UsersPage;

import { useTranslation } from "react-i18next";
import styles from "pages/refundClaim/refundClaimPage.module.scss";
import { PersonAddRegular as CreateAccountIcon } from "@fluentui/react-icons";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import * as routes from "constants/routes";
import classNames from "classnames";
import { RefundClaimContext } from "pages/refundClaim/RefundClaimPage";

export default function CreateAccountButton() {
  const { t } = useTranslation("claimrefund");

  const navigate = useNavigate();

  const { refundSessionId, email } = useContext(RefundClaimContext);

  function navigateCreateAccount() {
    navigate(`${routes.CONSUMER_ACCOUNT_CREATE}?rs=${refundSessionId}`, {
      state: {
        customerEmail: email,
      },
    });
  }

  return (
    <div className={styles.accountOptionsWrapper}>
      <div className={classNames(styles.accountOption)} title={t("CreateAccount")} onClick={navigateCreateAccount}>
        <div className={styles.optionIcon}>
          <CreateAccountIcon />
        </div>
        <div className={styles.accountOptionTextWrapper}>
          <div className={styles.optionLabel}>{t("CreateAccount")}</div>
          <div className={styles.optionText}>{t("CreateAccountForRefundInfo")}</div>
        </div>
      </div>
    </div>
  );
}

import styles from "pages/consumerCompliance/modules/complianceForm/ComplianceForm.module.scss";
import { Form, Input, PhoneInput, RadioChips, Select } from "components/forms/forms";
import { ProgressTabContainer } from "features/progressTabs/ProgressTab";
import { useTranslation } from "react-i18next";
import { FormEvent, useContext } from "react";
import { FormToJson } from "api/apiUtils";
import { StepProps } from "pages/consumerCompliance/modules/types";
import {
  QuestionnaireField,
  questionnaireFields,
  SelectOption,
} from "pages/consumerCompliance/modules/complianceForm/constants";
import { NavigateButtons } from "pages/consumerCompliance/modules/ComplianceSteps";
import api from "api/api";
import { ComplianceCheckContext } from "pages/consumerCompliance/ComplianceCheckPage";

export default function ComplianceForm(props: StepProps) {
  const { paymentSessionId, paymentMethod, loggedInCustomer } = useContext(ComplianceCheckContext);

  const checkComplianceResp = api.useComplianceCheckQuery(
    {
      paymentSessionId: paymentSessionId,
      // TODO REFUND SESSION ID !
      paymentMethod: paymentMethod,
      customerId: loggedInCustomer?.customerId,
    },
    { skip: !loggedInCustomer?.customerId },
  );

  // TODO MAP THE COMPLIANCE RESPONSE TO THE FORM
  console.log(checkComplianceResp);

  // function for handling form submit.
  function submit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    // Get all the data from the form (add type to FormToJson<the type>(event))
    const formJson = FormToJson(event);

    // TODO Send it to the API

    console.log(formJson);
    // Correctly update the state based on previous state and proceed to the next step.
    props.setCompletedSteps((prevSteps) => [...prevSteps, "questionnaire"]);
    props.setStep(props.step + 1);
  }

  // handleBack handles the logic for moving backwards. Should only be available if the user is not on the first step.
  const handleBack = (event: FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (props.step > 0) {
      props.setStep(props.step - 1);
    }
  };

  return (
    <ProgressTabContainer>
      <Form className={styles.form} intercomTarget={"compliance-questionnaire"} onSubmit={submit}>
        {questionnaireFields.map((field) => (
          <InputComponent key={field.name} {...field} />
        ))}
        <NavigateButtons step={props.step} hideBack handleBack={handleBack} />
      </Form>
    </ProgressTabContainer>
  );
}

function InputComponent(field: QuestionnaireField) {
  // translate the label. We might consider adding this to a dynamic response from the backend
  const { t } = useTranslation("consumerCompliance");
  const translatedLabel = t(field.name);
  let translatedOptions: SelectOption[] = [];

  switch (field.type) {
    case "select":
      // Translate the options
      translatedOptions = field.options?.map((option) => ({ ...option, label: t(option.label) })) || [];

      return <Select name={field.name} required={field.required} options={translatedOptions} label={translatedLabel} />;
    case "radio":
      translatedOptions = field.options?.map((option) => ({ ...option, label: t(option.label) })) || [];

      return <RadioChips groupName={field.name} options={translatedOptions} label={translatedLabel} />;
    case "tel":
      return <PhoneInput name={field.name} required={field.required} label={translatedLabel} extensionIso3={"usa"} />;
    default:
      return <Input type={field.type} name={field.name} required={field.required} label={translatedLabel} />;
  }
}

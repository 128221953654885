import { Suspense, useEffect } from "react";
import { Cookies } from "react-cookie";
import { RouteObject, useRoutes } from "react-router";
import { useLocation, useNavigate } from "react-router-dom";
import { useLogoutMutation } from "api/manualApi";
import DefaultLayout from "layout/DefaultLayout";
import LoginLayout from "layout/LoginLayout";
import LoginPage from "features/auth/LoginPage";
import DashboardPage from "pages/dashboard/DashboardPage";
import NoMatch from "pages/noMatch/NoMatch";
import * as routes from "constants/routes";
import BootstrappingPage from "pages/bootstrapping/BootstrappingPage";
import ConnectionStatusModal from "features/connectionStatus/ConnectionStatusModal";
import CreateCompanyModal from "pages/company/modals/CreateCompanyModal";
import CompanyPage from "pages/company/CompanyPage";
import ShopLayout from "layout/ShopLayout";
import DemoShopPage from "pages/demo/DemoShopPage";
import DemoProductPage from "pages/demo/DemoProductPage";
import SignUpPage from "pages/register/SignUpPage";
import ApiKeysPage from "pages/apikeys/ApiKeysPage";
import CheckoutPage from "pages/checkout/CheckoutPage";
import EditApiKey from "pages/apikeys/EditApiKey";
import PaymentsPage from "pages/payments/PaymentsPage";
import UsersPage from "pages/users/UsersPage";
import EditUserPermissionsModal from "pages/users/permissions/EditUserPermissionsModal";
import UserDetailsModal from "pages/users/UserDetailsModal";
import InviteUserModal from "pages/users/InviteUserModal";
import TokenLoginPage from "features/auth/TokenLoginPage";
import ResetPasswordPage from "features/auth/ResetPasswordPage";
import ForgotPasswordPage, { ForgotPasswordPageType } from "features/auth/ForgotPasswordPage";
import EditUserAdminPermissionsModal from "pages/users/permissions/EditUserAdminPermissionsModal";
import EditUserCompaniesModal from "pages/users/permissions/EditUserCompaniesModal";
import BankAccountsPage from "pages/bankAccounts/BankAccountsPage";
import CreateBankAccountModal from "pages/bankAccounts/CreateBankAccountModal";
import LoadingApp from "components/loading/LoadingApp";
import BankTransfersPage, { BankTransfersPageType } from "pages/bankTransfers/BankTransfersPage";
import ComplianceCheckPage from "pages/consumerCompliance/ComplianceCheckPage";
import FailureEventsPage from "pages/failureEvents/FailureEventsPage";
import CustomersPage from "pages/customers/CustomersPage";
import ComplianceHitsPage from "pages/complianceHits/ComplianceHitsPage";
import UpdateComplianceHitStatusModal from "pages/complianceHits/UpdateComplianceHitStatusModal";
import ExemptComplianceHitModal from "pages/complianceHits/ExemptComplianceHitModal";
import SuperAdminPage from "pages/superAdmin/SuperAdminPage";
import ShopPage, { ShopView } from "pages/shop/ShopPage";
import ProductsPage from "pages/products/ProductsPage";
import { CompanyLogoModal } from "pages/company/modals/CompanyLogoModal";
import { CompanySymbolModal } from "pages/company/modals/CompanySymbolModal";
import ProductDetailsModal from "pages/products/ProductDetailsModal";
import RefundsPage from "pages/refunds/RefundsPage";
import RefundModal from "pages/refunds/RefundModal";
import CustomerLayout from "layout/CustomerLayout";
import RefundClaimPage from "pages/refundClaim/RefundClaimPage";
import LightningWithdrawalsPage from "pages/wallet/lightningWithdrawals/LightningWithdrawalsPage";
import ConsumerAccountPage from "pages/consumerAccount/ConsumerAccountPage";
import ConsumerBitcoinWithdrawModal from "pages/consumerAccount/ConsumerBitcoinWithdrawModal";
import { CompanyShopFaviconModal } from "pages/company/modals/CompanyShopFaviconModal";
import { CompanyCheckoutBgImageModal } from "pages/company/modals/CompanyCheckoutBgImageModal";

function Logout() {
  const [logout] = useLogoutMutation();
  const navigate = useNavigate();

  useEffect(() => {
    const c = new Cookies();
    c.remove("merchant_services_session");
    logout();
    navigate("/login", { replace: true });
  });

  return <div />;
}

const customerRoutes: RouteObject = {
  element: <CustomerLayout />,
  children: [
    { path: `${routes.CHECKOUT}/:session`, element: <CheckoutPage /> },
    { path: routes.CONSUMER_ACCOUNT, element: <ConsumerAccountPage /> },
    { path: routes.CONSUMER_ACCOUNT_EDIT, element: <ComplianceCheckPage /> },
    { path: routes.CONSUMER_ACCOUNT_CREATE, element: <ComplianceCheckPage /> },
    { path: `${routes.CLAIM_REFUND}/:refundSessionId`, element: <RefundClaimPage /> },
  ],
};

const shopRoutes: RouteObject = {
  element: <ShopLayout />,
  children: [
    // TODO refactor demoshop into generic shop
    { path: routes.DEMO_SHOP, element: <DemoShopPage /> },
    { path: routes.DEMO_PRODUCT, element: <DemoProductPage /> },
    { path: routes.SHOP, element: <ShopPage view={ShopView.PRODUCTS} /> },
    { path: routes.SHOPPING_CART, element: <ShopPage view={ShopView.CART} /> },
  ],
};

const publicRoutes: RouteObject = {
  element: <LoginLayout />,
  children: [
    { path: routes.SIGNUP, element: <SignUpPage /> },
    { path: routes.LOGIN, element: <LoginPage /> },
    { path: routes.BOOTSTRAPPING, element: <BootstrappingPage /> },
    { path: routes.LOGOUT, element: <Logout /> },
    { path: routes.TOKEN_LOGIN, element: <TokenLoginPage /> },
    { path: routes.TOKEN_RESET_PASSWORD, element: <ResetPasswordPage /> },
    { path: routes.FORGOT_PASSWORD_USER, element: <ForgotPasswordPage pageType={ForgotPasswordPageType.user} /> },
    {
      path: routes.FORGOT_PASSWORD_CUSTOMER,
      element: <ForgotPasswordPage pageType={ForgotPasswordPageType.customer} />,
    },
  ],
};

const modalRoutes: RouteObject = {
  children: [
    { path: routes.CONNECTION_STATUS, element: <ConnectionStatusModal /> },
    { path: routes.CREATE_COMPANY, element: <CreateCompanyModal /> },
    { path: routes.EDIT_USER_PERMISSIONS, element: <EditUserPermissionsModal /> },
    { path: routes.INVITE_USER_COMPANY, element: <InviteUserModal /> },
    { path: routes.API_KEY, element: <EditApiKey /> },
    { path: routes.USER_SELF_DETAILS, element: <UserDetailsModal /> },
    { path: routes.INVITE_ADMIN_USER, element: <InviteUserModal /> },
    { path: routes.EDIT_USER_ADMIN_PERMISSIONS, element: <EditUserAdminPermissionsModal /> },
    { path: routes.EDIT_USER_COMPANIES, element: <EditUserCompaniesModal /> },
    { path: routes.CREATE_COMPANY_BANK_ACCOUNT, element: <CreateBankAccountModal /> },
    { path: routes.ADMIN_UPDATE_COMPLIANCE_HIT_STATUS, element: <UpdateComplianceHitStatusModal /> },
    { path: routes.ADMIN_EXEMPT_COMPLIANCE_HIT, element: <ExemptComplianceHitModal /> },
    { path: routes.COMPANY_LOGO, element: <CompanyLogoModal /> },
    { path: routes.COMPANY_SYMBOL, element: <CompanySymbolModal /> },
    { path: routes.COMPANY_SHOP_FAVICON, element: <CompanyShopFaviconModal /> },
    { path: routes.COMPANY_CHECKOUT_BG_IMAGE, element: <CompanyCheckoutBgImageModal /> },
    { path: routes.COMPANY_PRODUCT_EDIT, element: <ProductDetailsModal /> },
    { path: routes.COMPANY_PRODUCT_ADD, element: <ProductDetailsModal /> },
    { path: routes.PAYMENT_REFUND, element: <RefundModal /> },
    { path: routes.CONSUMER_ACCOUNT_BITCOIN_WITHDRAW, element: <ConsumerBitcoinWithdrawModal /> },
  ],
};

const authenticatedRoutes: RouteObject = {
  element: <DefaultLayout />,
  children: [
    {
      // Allow to go to app without services being up
      //element: <RequireMerchantServices />,
      children: [
        {
          path: routes.ROOT,
          element: <DashboardPage />,
          children: modalRoutes.children,
        },
        { path: routes.COMPANY, element: <CompanyPage /> },
        { path: routes.USERS, element: <UsersPage /> },
        { path: routes.PAYMENTS, element: <PaymentsPage /> },
        { path: routes.REFUNDS, element: <RefundsPage /> },
        { path: routes.CUSTOMERS, element: <CustomersPage /> },
        { path: routes.COMPANY_BANK_ACCOUNTS, element: <BankAccountsPage /> },
        {
          path: routes.COMPANY_BANK_WITHDRAWALS,
          element: <BankTransfersPage pageType={BankTransfersPageType.CompanyWithdrawals} />,
        },
        { path: routes.API_KEYS, element: <ApiKeysPage /> },
        { path: routes.COMPANY_PRODUCTS, element: <ProductsPage /> },
        { path: routes.ADMIN_USERS, element: <UsersPage /> },
        { path: routes.ADMIN_CUSTOMERS, element: <CustomersPage /> },
        {
          path: routes.ADMIN_BANK_WITHDRAWALS,
          element: <BankTransfersPage pageType={BankTransfersPageType.AdminWithdrawals} />,
        },
        { path: routes.ADMIN_LIGHTNING_WITHDRAWALS, element: <LightningWithdrawalsPage /> },
        { path: routes.ADMIN_FAILURE_EVENTS, element: <FailureEventsPage /> },
        { path: routes.ADMIN_COMPLIANCE_HITS, element: <ComplianceHitsPage /> },
        { path: routes.ADMIN_SUPER_ADMIN_SETTINGS, element: <SuperAdminPage /> },
        { path: "*", element: <NoMatch /> },
      ],
    },
  ],
};

const Router = () => {
  const location = useLocation();
  let background = location.state && location.state.background;

  // If we do not have a background, and we are on a modal route, we should default the background to the root
  (modalRoutes?.children || []).forEach((route) => {
    const path = route.path || "";
    const regexPattern = path.replace(/:[^/]+/g, "[^/]+");
    const regex = new RegExp(`^${regexPattern}$`);

    if (regex.test(location.pathname) && !background) {
      background = "/";
    }
  });

  const currentLocation = background || location;

  const routes = [publicRoutes, authenticatedRoutes, shopRoutes, customerRoutes];

  const router = useRoutes(routes, currentLocation);
  const modalRouter = useRoutes([modalRoutes, publicRoutes, authenticatedRoutes]);

  return (
    <Suspense fallback={<LoadingApp />}>
      {router}
      {background && modalRouter}
    </Suspense>
  );
};

export default Router;

import api, { CheckCustomerResponse } from "api/api";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import {
  Mail20Regular as EmailIcon,
  Password20Regular as PinIcon,
  Password20Regular as PasswordIcon,
} from "@fluentui/react-icons";
import styles from "features/customerEmailLoginFields/customerEmailLoginFields.module.scss";
import Button, { ButtonPosition, ButtonRow } from "components/buttons/Button";
import { Input } from "components/forms/forms";
import { FormEvent, useEffect, useRef, useState } from "react";
import { getErrorResponse } from "components/errors/validationErrors";
import Spinny from "features/spinny/Spinny";
import { useDispatch } from "react-redux";
import * as routes from "constants/routes";
import { useNavigate } from "react-router-dom";

type Props = {
  header?: string;
  paymentSessionId?: string;
  refundSessionId?: string;
  setCustomerResp: (customer: CheckCustomerResponse) => void | null;
  email: string | undefined;
  setEmail: (email: string | undefined) => void;
  isGuest?: boolean;
  setIsGuest?: (edit: boolean | undefined) => void;
  className?: string;
};

// First checks if customer exists, and if does show pin/password fields to login

export default function CustomerEmailLoginFields(props: Props) {
  const { t } = useTranslation("common");
  const { t: tc, i18n } = useTranslation("checkout");
  const { t: tCompl } = useTranslation("compliance");
  const { t: tv } = useTranslation("validation");
  const navigate = useNavigate();

  // email input ref
  const emailInput = useRef<HTMLInputElement>(null);

  const [validEmail, setValidEmail] = useState<boolean>(false);
  const [requiresPin, setRequiresPin] = useState<boolean>(false);
  const [currentPin, setCurrentPin] = useState<string>();
  const [checkPinErrors, setCheckPinErrors] = useState<string[] | undefined>(undefined);
  const [requiresPassword, setRequiresPassword] = useState<boolean>(false);
  const [currentPassword, setCurrentPassword] = useState<string>();
  const [checkPasswordErrors, setCheckPasswordErrors] = useState<string[] | undefined>(undefined);
  const [checkCustomerErrors, setCheckCustomerErrors] = useState<string[] | undefined>(undefined);
  const [delayRetry, setDelayRetry] = useState<boolean>(false);

  const dispatch = useDispatch();

  const [getCheckCustomer, customerResp] = api.useRequestCustomerCheckoutLoginMutation();
  const [loginCustomer, loginResp] = api.useLoginCustomerMutation();

  // login
  useEffect(() => {
    if (loginResp?.data?.token) {
      // put customer token in session storage, to clear after closing the browser
      window.sessionStorage.setItem("token", loginResp?.data?.token);
      window.localStorage.removeItem("token");
    }

    // dispatch manually after setting the token to trigger logged in customer refetch
    dispatch(api.util.invalidateTags(["customer"]));
  }, [loginResp]);

  useEffect(() => {
    if (!customerResp?.error && !loginResp?.error) {
      setCheckCustomerErrors(undefined);
      setCheckPinErrors(undefined);
      setCheckPasswordErrors(undefined);
      return;
    }

    const err = getErrorResponse(customerResp?.error || loginResp?.error);

    if (loginResp?.error && err?.message) {
      let errMsg = err.message;

      if (i18n.exists(err.message, { ns: "validation" })) {
        errMsg = tv(err.message);
      } else if (i18n.exists(err.message, { ns: "compliance" })) {
        errMsg = tCompl(err.message);
      } else {
        errMsg = tc(err.message);
      }

      if (customerResp?.data?.pin && requiresPin) {
        setCheckPinErrors([...(checkPinErrors || []), errMsg]);
        setCheckPasswordErrors(undefined);
      }

      if (customerResp?.data?.password && requiresPassword) {
        setCheckPasswordErrors([...(checkPasswordErrors || []), errMsg]);
        setCheckPinErrors(undefined);
      }
    }

    if (customerResp?.error && err?.message) {
      // use either compliance of validation package for error localization
      if (i18n.exists(err.message, { ns: "validation" })) {
        setCheckCustomerErrors([tv(err.message)]);
      } else {
        setCheckCustomerErrors([tCompl(err.message)]);
      }
      clear(false, false);
    }
  }, [customerResp, loginResp]);

  useEffect(() => {
    props.setCustomerResp(customerResp);

    if (customerResp?.data?.pin) {
      setRequiresPin(true);
      if (props.setIsGuest) props.setIsGuest(false); // cannot be guest as already has an account
    } else if (customerResp?.data?.password) {
      setRequiresPassword(true);
      if (props.setIsGuest) props.setIsGuest(false); // cannot be guest as already has an account
    }
  }, [customerResp]);

  function checkEmailValidity() {
    // regex for email should be any character seperated by an @ symbol followed by any character seperated by a .
    const emailRegex = /\S+@\S+\.\S+/;

    setValidEmail(emailRegex.test(emailInput?.current?.value || ""));
  }

  function validatePin(pin: string): boolean {
    const onlyNumbers = /^\d+$/.test(pin) || pin === "";
    const maxLength = pin.length <= 4;

    return onlyNumbers && maxLength;
  }

  function handleEnterKey(event: React.KeyboardEvent<HTMLInputElement>) {
    // we don't want to submit the form when pressing enter in email or pin input
    if (event.key === "Enter") {
      event.preventDefault();
      submit();
    }
  }

  function validateAndSetCheckPin(event: FormEvent<HTMLInputElement>) {
    if (validatePin(event.currentTarget.value)) {
      setCheckPinErrors(undefined);
      setCurrentPin(event.currentTarget.value);
    } else {
      // cannot set pin of wrong format
      setCheckPinErrors([tc("PinCodeFormatErr")]);
    }
  }

  function validateAndSetCheckPassword(event: FormEvent<HTMLInputElement>) {
    // no need to validate password in login
    setCurrentPassword(event.currentTarget.value);
  }

  function checkCustomer() {
    const email = emailInput.current?.value || undefined;
    getCheckCustomer({
      "X-Checkout-Payment-Session-ID": props.paymentSessionId,
      "X-Refund-Session-ID": props.refundSessionId,
      customerEmail: { email: email || "" },
    });
    props.setEmail(email);
    setDelayRetry(true);
    setTimeout(() => setDelayRetry(false), 3000); // 3 sec delay to avoid spamming
  }

  function login() {
    let pin: string | undefined = undefined;
    let password: string | undefined = undefined;

    if (requiresPin) {
      if (!currentPin) {
        setCheckPinErrors([tc("PinCodeRequiredErr")]);
        return;
      }
      pin = currentPin;
    }

    if (requiresPassword) {
      if (!currentPassword) {
        setCheckPasswordErrors([tc("PasswordRequiredErr")]);
        return;
      }
      password = currentPassword;
    }

    loginCustomer({
      email: props.email || "",
      pin: pin,
      password: password,
    });
  }

  // either check the customer or login
  function submit() {
    if (!validEmail) {
      return;
    }

    if (!props.email || !customerResp?.data) {
      checkCustomer();
      return;
    }

    login();
  }

  function clear(clearEmailInput: boolean, clearCheckCustomerResp: boolean) {
    if (emailInput?.current && clearEmailInput) {
      emailInput.current.value = "";
    }

    props.setEmail(undefined);
    setRequiresPin(false);
    setCurrentPin("");
    setRequiresPassword(false);
    setCurrentPassword("");
    if (props.setIsGuest) props.setIsGuest(undefined);

    if (clearCheckCustomerResp) {
      customerResp.reset();
    }
  }

  function toggleUsePasswordInsteadOfPin(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    if (loginResp.isLoading) {
      return;
    }

    if (!customerResp.data?.pin || !customerResp.data?.password) {
      console.error("Should not be able to toggle between pin and password, if can use only one");
      return;
    }

    setCurrentPin("");
    setCurrentPassword("");

    if (requiresPin) {
      setRequiresPin(false);
      setRequiresPassword(true);
    } else {
      setRequiresPin(true);
      setRequiresPassword(false);
    }
  }

  function navigateForgotPassword(event: React.MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();

    if (props.paymentSessionId) {
      navigate(routes.FORGOT_PASSWORD_CUSTOMER + "?checkout=" + props.paymentSessionId, {
        state: { email: emailInput.current?.value },
      });
      return;
    }

    if (props.refundSessionId) {
      navigate(routes.FORGOT_PASSWORD_CUSTOMER + "?refund=" + props.refundSessionId, {
        state: { email: emailInput.current?.value },
      });
      return;
    }

    navigate(routes.FORGOT_PASSWORD_CUSTOMER, {
      state: { email: emailInput.current?.value },
    });
  }

  return (
    <div className={classNames(styles.formPart, styles.emailInputWrapper, props.className)}>
      <div className={styles.header}>{props.header}</div>
      <Input
        leftIcon={<EmailIcon />}
        ref={emailInput}
        label={tc("EmailInput")}
        name={"email"}
        required={true}
        type={"email"}
        onChange={checkEmailValidity}
        onKeyDown={handleEnterKey}
        autoFocus={true}
        disabled={!!props.email}
        errors={checkCustomerErrors}
      />
      {/* Only show pin or password input if customer with email exist */}
      {requiresPin && (
        <Input
          autoFocus={true}
          pinLength={4}
          maxLength={4}
          minLength={4}
          required={true}
          leftIcon={<PinIcon />}
          label={tc("PinCodeInput")}
          name={"checkPin"}
          value={currentPin || ""}
          type={"password"}
          onChange={validateAndSetCheckPin}
          onKeyDown={handleEnterKey}
          errors={checkPinErrors}
        />
      )}
      {requiresPassword && (
        <Input
          autoFocus={true}
          minLength={8}
          required={true}
          leftIcon={<PasswordIcon />}
          label={tc("PasswordInput")}
          name={"checkPassword"}
          value={currentPassword || ""}
          type={"password"}
          onChange={validateAndSetCheckPassword}
          onKeyDown={handleEnterKey}
          errors={checkPasswordErrors}
        />
      )}
      {!props.isGuest && (requiresPassword || requiresPin) && (
        <div className={styles.usePinOrPassword}>
          <a href="#" onClick={toggleUsePasswordInsteadOfPin}>
            {requiresPin ? t("Use password instead") : t("Use pin instead")}
          </a>
        </div>
      )}
      <ButtonRow>
        {props.email && !props.isGuest && customerResp.isSuccess && (
          <Button
            intercomTarget={"back-email-set"}
            type={"button"}
            onClick={() => clear(true, true)}
            buttonPosition={ButtonPosition.left}
          >
            {t("Back")}
          </Button>
        )}
        {(!props.email || requiresPassword || requiresPin) && (
          <Button
            intercomTarget={"next-email-set"}
            type={"button"}
            onClick={() => submit()}
            disabled={
              !validEmail ||
              // must change email before trying again after error
              (!customerResp.isSuccess && delayRetry) ||
              loginResp.isLoading
            }
            buttonPosition={ButtonPosition.right}
            icon={loginResp.isLoading || customerResp.isLoading ? <Spinny /> : undefined}
          >
            {t("Next")}
          </Button>
        )}
      </ButtonRow>
      {!props.isGuest && (requiresPassword || requiresPin) && (
        <div className={styles.forgotPassword}>
          <a href={routes.FORGOT_PASSWORD_CUSTOMER} onClick={navigateForgotPassword}>
            {t("Forgot your pin or password?")}
          </a>
        </div>
      )}
    </div>
  );
}
